export const userConstants = {
    AUTHENTICATED: 'AUTHENTICATED',
    LOGOUT: 'LOGOUT',
    USER_SAVE: 'USER_SAVE',
    USER_LIST: 'USER_LIST',
    USER_SAVE_PREFERENCES: 'USER_SAVE_PREFERENCES',
    FORGOTTEN: 'FORGOTTEN',
    RESET: 'RESET',
    KEY_CHECK: 'KEY_CHECK',
    WELCOME: 'WELCOME',
    REINVITE: 'REINVITE',
    DELEGATE_AUTHENTICATED: 'DELEGATE_AUTHENTICATED',
    DELEGATE_LOGOUT: 'DELEGATE_LOGOUT',
    PREDICT_COUNTRY: 'PREDICT_COUNTRY'
};