import { overlayConstants } from '../constants/overlay';

export default function(state = {}, action) {
		
	switch(action.type){
		
		case overlayConstants.SHOW:
			let ret = action.payload
			ret.show = true;
			return ret;
			
		case overlayConstants.HIDE:
			return { 
				...state, 
				show: false 
			}
			
		default:
			return state;
	}
	
	
}