import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/chrome/loading';
import CollectionsToolbar from './toolbar';
import OverlaySettings from '../../components/chrome/overlay_settings';
import CountUp from 'react-countup';

import AssetGridItem from '../../components/assets/grid_item';
import EmptySection from '../../components/chrome/empty';
import { generateBack } from '../../helpers/back';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import i18n from '../../helpers/i18n'; 
import DropzoneArea from '../../components/forms/dropzone';
import { checkPermission } from '../../helpers/permissions';

import { fetchCollectionsList, fetchCollectionAssets, saveAsset } from '../../actions/assets';

class Collection extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'COLLECTIONS')){
			this.props.history.push('/');
		}
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/assets');
		}
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		let keyword = false;
		
		if(queryString.keyword){
			//keyword = decodeURI(queryString.keyword);
		}
				
		this.props.fetchCollectionsList(id);
		this.props.fetchCollectionAssets(id);
		
		this.state = {
			id: id,
			files: [],
			uploadStatus: false,
			uploadIcon: false,
			type: 'all',
			selected: [],
			search: keyword ? true : false,
			keyword: keyword
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
				
		if(this.props.assets.collections){

			if(!this.props.assets.collections[this.state.id]){
				this.props.history.push('/assets');
			}			
		}
	}
	
	onChangeType(type){
		this.setState({
			type: type
		});
	}
	
	onSelectItem(id){
		
		let selected = this.state.selected;
		
		if(!selected.includes(id)){
			selected.push(id);
		}else{
			
			let index = selected.indexOf(id);
 
		    if(index > -1) {
		    	selected.splice(index, 1);
		    }
		}
		
		this.setState({
			selected: selected
		})
	}
	
	filterData(type){
		
		let filetype;
		let assets = [];
		let counter = {
			image: 0,
			video: 0,
			document: 0	
		};
			
		_.forEach(_.at(_.keyBy(this.props.assets.assets, 'id'), this.props.assets.collections[this.state.id].assets), (asset, key) => {
			
			if(!asset){
				return null;
			}	
										
			filetype = asset.type;
			
			if(filetype === 'pdf'){
				filetype = 'document';
			}else if(filetype === 'spreadsheet'){
				filetype = 'document';
			}else if(filetype === 'presentation'){
				filetype = 'document';
			}else if(filetype === 'youtube'){
				filetype = 'video';
			}else if(filetype === 'vimeo'){
				filetype = 'video';
			}
							
			if(this.state.search && this.state.keyword){
				
				if(asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
					
					if(this.state.type === 'all' || this.state.type === filetype){
						assets.push(asset);
					}
					
					++counter[filetype];
				}
				
			}else{
				if(this.state.type === 'all' || this.state.type === filetype){
					assets.push(asset);
				}
				
				++counter[filetype];
			}

		}, this);
		
		if(type === 'counter'){
			return counter;
		}else{
			return assets;
		}
	}
	
	renderFiles(){
		
		let assets = this.filterData();
		let collection = this.props.assets.collections[this.state.id];
		let collections = _.filter(this.props.assets.collections, { parent_id: collection.id });
		
		if(_.isEmpty(collections) && !collection.parent_id && _.isEmpty(assets)){
			
			/*let search = '';
			
			if(this.state.keyword){
				search = '_search';
			}*/
			
			//if(this.state.type === 'all'){
				
				return (
					<DropzoneArea
						type="multiple"
						processFiles={(posting, percentIndicator, callback, callbackError) => {
							
							posting.append('collection_id', this.state.id);
							posting.append('source', 'local');
				
							this.props.saveAsset(false, false, posting, (percent) => {
								percentIndicator(percent);
							}, callback, callbackError);
						}}
						disabled={collection.readonly === 1 ? true : false}
					>	
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t(`assets:collections_assets_empty_title`)}
							description={i18n.t(`assets:collections_assets_empty_description`)}
							cta={{
								label: i18n.t('assets:action_add_submit'),
								url: `${this.props.parentPath}/asset?collection=${this.state.id}&b=${generateBack(window.location.pathname)}`
							}}
						/>						
					</DropzoneArea>				
				);
				
			/*}else{
				
				return (
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t(`collection_empty_${this.state.type}_title${search}`)}
						description={i18n.t(`collection_empty_${this.state.type}_description${search}`)}
						cta={{
							label: i18n.t('collections_action_upload_assets'),
							url: `${this.props.location.pathname}/asset?collection=${this.state.id}`
						}}
					/>
				);
			}*/		
		}
		
		assets = _.orderBy(assets, ['created.date'],['desc']); 
		collections = _.orderBy(collections, ['created.date'],['desc']); 
		
		return (
         	<div className="c-card u-mb-zero"> 
					
				<DropzoneArea
					type="multiple"
					processFiles={(posting, percentIndicator, callback, callbackError) => {
				
						posting.append('collection_id', this.state.id);
						posting.append('source', 'local');
							
						this.props.saveAsset(false, false, posting, (percent) => {
							percentIndicator(percent);
						}, callback, callbackError);
					}}
					disabled={collection.readonly === 1 ? true : false}
				>
					<div className="row assets u-pb-zero">
						{collection.parent_id && 
							<div className="col-sm-6 col-lg-4 col-xl-2">
								
								<Link to={`/assets/collection/${collection.parent_id}`} className="c-card c-card-collection is-animated u-text-center equalize">
									<div className="u-mb-small directory">
										<i className="fal fa-folder-open"></i>
									</div>
									<div className="info">
										<h4>Up A Level</h4>
										<p>{this.props.assets.collections[collection.parent_id].name}</p>
									</div>
								</Link>					
				            </div>						
						}	
						
						{_.map(collections, (child, key) => {
						    
						    let locked = '';
		
							if(child.readonly === 1){
								locked = (
									<i className="far fa-lock locked"></i>
								);
							}
							
							child.sub = [];
							child.counter = 0;
			
							const organiseSub = (id) => {
								
								let tmp = _.filter(this.props.assets.collections, { parent_id: id });
								
								_.forEach(tmp, (child2, key) => {
									child.sub.push(child2.name);
									tmp[key].children = organiseSub(child2.id);
								});
								
								return tmp;
							}
							
							organiseSub(child.id);
							
							_.forEach(this.props.assets.assets, (asset, key) => {					
										
								if(child.assets.includes(asset.id)){
									++child.counter;
								}
							});
							
							return(
								<div className="col-sm-6 col-lg-4 col-xl-2" key={key}>
									<DropzoneArea
										type="multiple"
										processFiles={(posting, percentIndicator, callback, callbackError) => {
											
											posting.append('collection_id', child.id);
											posting.append('source', 'local');
							
											this.props.saveAsset(false, false, posting, (percent) => {
												percentIndicator(percent);
											}, callback, callbackError);
										}}
										border={true}
										disabled={child.readonly === 1 ? true : false}
									>
										<Link to={`/assets/collection/${child.id}`} className="c-card c-card-collection is-animated u-text-center equalize">
											<div className="u-mb-small directory">
												<i className="fal fa-folder"></i>
												{locked}
											</div>
											<div className="info">
												<h4>{child.name}</h4>
												<p>
													<CountUp 
														delay={0} 
														duration={1} 
														end={child.counter}
														separator="," 
														preserveValue={true}
													/> {child.counter === 1 ? 'Asset' : 'Assets'}
													
													{child.sub.length > 0 && 
														<React.Fragment>
															{` / `}
															<CountUp 
																delay={0} 
																duration={1} 
																end={child.sub.length}
																separator="," 
																preserveValue={true}
															/> {child.sub.length === 1 ? 'Collection' : 'Collections'}
														</React.Fragment>
													}
												</p>
											</div>
										</Link>					
									</DropzoneArea>					
					            </div>
					        );
				        })}
						
						{_.map(assets, (asset, key) => {	
							
							let selected = this.state.selected.includes(asset.id) ? true : false;
							let onSelect = 'disabled';
										
							if(asset.type !== 'youtube' && asset.type !== 'vimeo'){
								onSelect = this.onSelectItem.bind(this);
							}
											
							return (
								<AssetGridItem 
									key={key}
									asset={asset}
									size="small"
									onSelectItem={onSelect}
									selected={selected}
									back={generateBack(window.location.pathname)}
									icon={selected ? 'fa-minus' : 'fa-plus'}
									{...this.props}
								/>						
							);
						})}
					</div>
				</DropzoneArea>
			</div>
		);
		
		
		
				
		/*return (
         	<div className="c-card u-mb-zero">
	         	<DropzoneArea
					type="multiple"
					processFiles={(files, percentIndicator, callback, callbackError) => {
						
						let values = {
							collection_id: this.state.id,
							files: files,
							source: 'local'
						}
						
						this.props.saveAsset(false, false, values, (percent) => {
							percentIndicator(percent);
						}, callback, callbackError);
					}}
					disabled={collection.readonly === 1 ? true : false}
				>	
					<div className="row assets u-pb-zero">
						{_.map(assets, (asset, key) => {	
							
							let selected = this.state.selected.includes(asset.id) ? true : false;
							let onSelect = 'disabled';
										
							if(asset.type !== 'youtube' && asset.type !== 'vimeo'){
								onSelect = this.onSelectItem.bind(this);
							}
											
							return (
								<AssetGridItem 
									key={key}
									asset={asset}
									size="small"
									onSelectItem={onSelect}
									selected={selected}
									back={generateBack(window.location.pathname)}
									icon={selected ? 'fa-minus' : 'fa-plus'}
									{...this.props}
								/>						
							);
						})}
					</div>						
				</DropzoneArea>
			</div>
		);	*/	
	}

	render() {

		if(!this.props.assets.assets || !this.props.assets.collections){
			return (
				<Loading />
			);
		}else if(this.state.id && !this.props.assets.collections[this.state.id]){
			return (
				<Loading />
			);
		}
		
		const collection = this.props.assets.collections[this.state.id];
		let parent = false;
		
		if(collection.parent_id){
			parent = _.find(this.props.assets.collections, { id: collection.parent_id });
			
			if(!parent){
				return (
					<Loading />
				);
			}
		}
		
		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{parent && 
							<React.Fragment>
								<Link to={`/assets/collection/${parent.id}`}>
									{parent.name}
								</Link>
								<i className="fal fa-chevron-right u-ml-xsmall u-mr-xsmall"></i>
							</React.Fragment>
						}
						
						{collection.name}
					</h2>
				</header>

				<CollectionsToolbar
					key={`toolbar_${this.state.selected}`}
					section="files"
					id={this.state.id}
					selected={this.state.selected}
					readonly={collection.readonly === 1 ? true : false}
					{...this.props}
				/>
				
				{this.renderFiles()}
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets, account }, ownProps){
	return {
		assets,
		account
	};
}

export default connect(mapStateToProps, { 
	fetchCollectionsList, 
	fetchCollectionAssets,
	saveAsset
})(Collection);