import moment from 'moment';
import 'moment/min/locales';
import i18n from './i18n'; 

export function dateString(format, start, end) {

	moment.locale(i18n.language);
	let value = moment(start).format('LL');
	
	if(end){
				
		if(moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD')){
			value = moment(start).format('LL');
		}else{
			
			if(moment(start).format('YYYY') === moment(end).format('YYYY')){
				
				if(moment(start).format('MM') === moment(end).format('MM')){
					value = `${moment(start).format('MMMM D')} - ${moment(end).format('D, YYYY')}`;
				}else{
					value = `${moment(start).format('MMMM D')} - ${moment(end).format('MMMM D, YYYY')}`;
				}
											
			}else{
				value = `${moment(start).format('LL')} - ${moment(end).format('LL')}`;
			}						
		}
		
	}else{
		
		switch(format){
			
			case 'datetime':
				value = `${value} - ${moment(start).format('HH:mm')}`;
				break;
				
			default:
			
				break;
		}
	}
					
	return value;
}
