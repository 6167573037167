import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import GAPage from '../components/tracking/ga_page';

export const RouteProtected = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        (localStorage.getItem('user_id') && localStorage.getItem('user_token'))
            ? 
            (
            	<React.Fragment>
            		<Component {...props} />
					<GAPage {...props} />
            	</React.Fragment>
            )
            : 
            (
            	<Redirect 
            		to={`/login`} 
            	/>
            )
    )} />
);

export const RouteUnprotected = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        (localStorage.getItem('user_id') && localStorage.getItem('user_token'))
            ? 
            (
            	<Redirect 
            		to={{ pathname: '/', state: { from: props.location } }} 
            	/>
            )
            :
            (
            	<React.Fragment>
            		<Component {...props} />
					<GAPage {...props} />
            	</React.Fragment>
            )
    )} />
);