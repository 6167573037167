import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) //https://github.com/i18next/i18next-xhr-backend
  .use(LanguageDetector) //https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)
  .init({ 
	  	
	  	// https://www.i18next.com/overview/configuration-options
    	
    	ns: 'translation',
		defaultNS: 'translation',
  		debug: process.env.NODE_ENV !== 'production',
    	detection: {
			order: ['localStorage'],
			lookupLocalStorage: 'locale'
    	},
    	backend: {
	      	loadPath: "/locales/{{lng}}/{{ns}}.json",
	      	addPath: '/locales/{{lng}}/{{ns}}.missing.json',
	      	crossDomain: true
	    },
	    fallbackLng: "en-GB",
	    interpolation: {
	      	escapeValue: false, // react already safes from xss
	      	format: function(value, format, lng) {
	            if (format === 'uppercase') return value.toUpperCase();
	            if (format === 'ucfirst') return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
	            //if(value instanceof Date) return moment(value).format(format);
	            return value;
	        }
	    },
	    react: {
			useSuspense: false,
		},
		saveMissing: false
  }, () => { 
	  
  }) 

// Keep the language on moment.js in sync with i18next
// by listening to the change language event.

i18n.on('loaded', function(loaded) {
	//i18n.changeLanguage(Object.keys(loaded)[0]);
	//console.log('Langauge set as ' + Object.keys(loaded)[0])
})

i18n.on('languageChanged', newlocale => {
  	//moment.locale('languageChanged', newlocale)
  	//console.log('languageChanged', newlocale)
});

export default i18n;