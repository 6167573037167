import { calendarConstants } from '../constants/calendar';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function fetchSchedule(startDate, endDate, clear) {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get(`/calendar/schedule?from=${startDate}&to=${endDate}`);
										
			dispatch({ 
				type: calendarConstants.SCHEDULE,
				payload: request,
				clear: clear ? true : false
			});
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}