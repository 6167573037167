import { accountConstants } from '../constants/account';

const defaultState = {
	group: false,
	role: false,
	permissions: false
};

export default function(state = defaultState, action) {

	switch (action.type) {
			
		case accountConstants.ACCOUNT:
				
			return { 
				...state, 
				group: action.payload.data.group,
				role: action.payload.data.role,
				permissions: action.payload.data.permissions,
				profile: action.payload.data.profile,
				docs: action.payload.data.docs
			};
							
		default:
			return state;
	}
}