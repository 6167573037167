import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import Timezone from '../../components/users/timezone';
import { imageUrl } from '../../helpers/s3';
import Toolbar from '../../components/chrome/toolbar';

import { checkPermission } from '../../helpers/permissions';
import { usersFetch, userSave } from '../../actions/users';
import { fetchRetailersList } from '../../actions/retailers';
import { fetchRegionalList } from '../../actions/regional';
import { fetchTimezones } from '../../actions/options';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';

import * as Doka from '../../packages/react-doka/lib/doka.esm.min';
import '../../packages/react-doka/lib/doka.min.css';

registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit);

var formName = 'formEditUser';

class EditUser extends Component {

	constructor(props){
		super(props);
		
		this.props.stopSubmission(formName);	
		
		const { id } = this.props.match.params;
		let { type } = this.props.match.params;
		let group = false;
		
		if(!type){
			this.props.history.push('/');
		}else{
			
			switch(type){
				
				case 'retailer':
					type = 'retailers';
					break;
				
				case 'regional':
					type = 'regional';
					break;
					
				default:
					this.props.history.push('/');
					break;
			}
		}

		if(id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, `${type.toUpperCase()}_USERS_EDIT`)){
				this.props.history.push('/');
			}
						
			this.props.usersFetch(id, false, () => {
				this.updateDomElements();
			});
			
		}else{

			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, `${type.toUpperCase()}_USERS_ADD`)){
				this.props.history.push('/');
			}
			
			this.props.fetchTimezones();
			
			const query = require('query-string');
			const queryString = query.parse(this.props.location.search);
			
			if(queryString.id){
				group = parseFloat(queryString.id);
			}
			
			// DO THIS SO THE VALIDATION IS RE-RENDERED AS SOMETIMES FAILS IN OVERLAY!
	        this.props.change('force_validation', 'rerender now!');	
		}
		
		let permissions = {};
		
		switch(type){
				
			case 'retailers':						
				if(checkPermission(this.props.account.permissions, id ? 'RETAILERS_USERS_EDIT' : 'RETAILERS_USERS_ADD', 'retailer')){
					
					this.props.fetchRetailersList(false, () => {
						this.updateDomElements();
					});
					
					permissions.group = true;
				}				
				break;
				
			case 'regional':
				this.props.fetchRegionalList(false, () => {
					this.updateDomElements();
				});
				
				permissions.group = true;
				break;
				
			default:
			
				break;
		}
		
		let language = false;
		
		if(!id && this.props.brand.details.languages.length === 1){
			language = this.props.brand.details.languages[0];
		}
		
		this.state = {
			id: id,
			files: [],
			files_changed: false,
			permissions: permissions,
			group: group,
			language: language,
			timezone: false,
			type: type
		}		
				
		this.updateDomElements();
	}
	
	updateDomElements(){
		
		if(this.props.users.users && this.state.id && this.props.users.users[this.state.id] && ((this.state.type === 'retailers' && this.props.retailers.retailers) || (this.state.type === 'regional' && this.props.regional.groups))){
		
			let user = this.props.users.users[this.state.id];
			let files = [];
									
			if(user.image){
				
				files = [
					{
						source: imageUrl(user.image),
						options: {
			              	type: 'local'
			            }
					}
				];
			}
			
			this.setState({
				files: files,
				files_changed: false
			});	
			
			let group_id = false;
			
			switch(this.state.type){
				
				case 'retailers':			
					_.forEach(this.props.retailers.retailers, (group, key) => {
								
						if(group.users && group.users.includes(user.id)){
							group_id = group.id;
							return;
						}
					}, this);
					break;
					
				case 'regional':
					_.forEach(this.props.regional.groups, (group, key) => {

						if(group.users && group.users.includes(user.id)){
							group_id = group.id;
							return;
						}
					}, this);
					break;
					
				default:
				
					break;
			}
																
			this.props.initialize({
				forename: user.forename,
				surname: user.surname,
				email: user.email,
				language: user.language
			});	
			
			this.setState({
				group: group_id,
				language: user.language,
				timezone: user.timezone
			});
			
		}else if(this.props.retailers.retailers){
			this.userLanguage();
		}
	}
	
	componentDidMount(){
		
		if(this.state.language){
			this.props.initialize({
				language: this.state.language
			});
		}
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
			
		let posting = _.clone(values);
		
		posting.type = this.state.type;
		
		if(this.state.files_changed){
			
			let files = _.map(this.avatar.getFiles(), (file, key) => {
			  	return {
				  	filename: file.filename,
				  	encoded: file.getFileEncodeBase64String()
				}
			});
						
			if(files.length > 0){
				posting.image = files[0].encoded;
			}else {
				posting.image = 'none';
			}
		}
		
		if(this.state.permissions.group){
			posting.group = this.state.group;
		}
		
		posting.language = this.state.language;
		
		if(this.state.id && this.props.users.users[this.state.id].active === '1'){
			posting.timezone = this.state.timezone;
		}
			  	
	  	this.props.userSave(formName, this.state.id, posting, () => {
		  	
		  	this.setState({
				files_changed: false
			});			
			
			let group_id = false;
			
			switch(this.state.type){
				
				case 'retailers':			
					if(this.state.permissions.retailer){
						this.props.fetchRetailersList(this.state.group);
						group_id = this.state.group;
					}else{
						this.props.fetchRetailersList(this.props.account.group.id);
						group_id = this.props.account.group.id;
					}
					break;
					
				case 'regional':
					this.props.fetchRegionalList(this.state.group);
					group_id = this.state.group;
					break;
					
				default:
				
					break;
			}
			
			this.props.usersFetch(false, group_id, () => {
				if(this.props.overlay.back){
					this.props.history.push(this.props.overlay.back.url);
				}else{
					this.props.history.push(this.props.parentPath);
				}
			});			
	  	});
	}
	
	onInputGroupChange(selected){
				
		this.setState({
			group: selected.value
		}, () => {
			this.userLanguage();
		});		
	}
	
	onInputLanguageChange(selected){
				
		this.setState({
			language: selected.value
		});
	}
	
	onInputTimezoneChange(selected){
						
		this.setState({
			timezone: selected.value
		});
	}
	
	userLanguage(){
		
		if(!this.state.language){
			
			let data = false;
						
			switch(this.state.type){
				
				case 'retailers':			
					if(this.props.retailers.retailers[this.state.group]){
						data = this.props.retailers.retailers[this.state.group];
					}
					break;
					
				case 'regional':
					if(this.props.regional.groups[this.state.group]){
						data = this.props.regional.groups[this.state.group];
					}
					break;
					
				default:
				
					break;
			}
						
			if(data){
				
				let timezone = _.find(this.props.options.timezones, {country_code:data.details.country});
				
				if(timezone){
					
					if(this.props.brand.details.languages.includes(timezone.languages[0])){
						this.setState({
							language: timezone.languages[0]
						})
					}
				}
			}
		}
	}
	
	renderPage(){
		
		if((this.state.id && (!this.props.users.users || !this.props.users.users[this.state.id]))){
			return (
				<Loading />
			);
		}
				
		let group_options = false;
		let group_title = 'Group';
		
		if(this.state.permissions.group){
			
			switch(this.state.type){
				
				case 'retailers':			
					group_options = _.map(this.props.retailers.retailers, (retailer, key) => {
																								
						return ({
							label: retailer.name,
							value: retailer.id							
						});
					})	
					
					group_title = i18n.t('retailers:user_label_group');				
					break;
					
				case 'regional':
					group_options = _.map(this.props.regional.groups, (regional, key) => {
																								
						return ({
							label: regional.name,
							value: regional.id							
						});
					})	
					
					group_title = i18n.t('regional:user_label_group');
					break;
					
				default:
				
					break;
			}
		}
				
		return (
			<div className="row">
				<div className="col-xl-8 col-md-12">
					<div className="c-card">
						<h4 className="u-mb-medium">{i18n.t('retailers:user_title_general')} </h4>
						
						<div className="row">
							<div className="col-xl-6 col-md-12">
								<Field
									label={i18n.t('retailers:user_label_forename')} 
									name="forename"
									type="text"
									component={InputField}
								/>
							</div>
							<div className="col-xl-6 col-md-12">
								<Field
									label={i18n.t('retailers:user_label_surname')} 
									name="surname"
									type="text"
									component={InputField}
								/>
							</div>
						</div>
														
						<div className="row">
							<div className="col-xl-6 col-md-12">
								<Field
									label={i18n.t('retailers:user_label_email')} 
									name="email"
									type="email"
									component={InputField}
								/>
							</div>
							<div className="col-xl-6 col-md-12">
								<Field
									label={i18n.t('users:account_label_language')} 
									name="language"
									type="suggest"
									options={_.map(this.props.brand.details.languages, (language, key) => {
									
										return {
											label: i18n.t(`translation:language_${language}`),
											value: language,
											//imageDropdown: `/images/flags/${language}.svg`
										}
									})}
									sort="asc"
									selected={this.state.language}
									onChangeFunc={this.onInputLanguageChange.bind(this)}
									component={InputField}
								/>
							</div>									
						</div>	
						<div className="row">
							{this.state.id && this.props.users.users[this.state.id].active === '1' && 
								<div className="col-xl-6 col-md-12">
									<Timezone
										label={i18n.t('users:account_label_timezone')}
										selected={this.state.timezone}
										onChangeFunc={this.onInputTimezoneChange.bind(this)}
										component={Field}
									/>
								</div>
							}
							
							{this.state.permissions.group && 
								<div className="col-xl-6 col-md-12">
									<Field
										label={group_title} 
										name="group"
										type="suggest"
										options={group_options}
										selected={this.state.group}
										onChangeFunc={this.onInputGroupChange.bind(this)}
										component={InputField}
									/>
								</div>
							}
						</div>													
					</div>
				</div>
				<div className="col-xl-4 col-md-12">
					<div className="c-card">
						<h4 className="u-mb-medium">{i18n.t('retailers:user_title_avatar')} </h4>
						<div className="filepond-container minimal">
							<FilePond 
								maxFiles="1"
								ref={ref => this.avatar = ref}
		                      	allowFileEncode={true}
								acceptedFileTypes="image/*"
		                      	labelIdle={i18n.t('filepond_idle')}
		                      	labelFileLoading={i18n.t('filepond_fileLoading')}
		                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
		                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
		                      	files={this.state.files}
							    imageCropAspectRatio="1:1"
							    stylePanelLayout="circle"
							    stylePanelAspectRatio="1:1"
							    styleButtonRemoveItemPosition="center bottom"
							    styleButtonProcessItemPosition="center bottom"
							    styleLoadIndicatorPosition="center bottom"
							    styleProgressIndicatorPosition="center bottom"
							    className="circle"
							    server={{
									process: null,
									load: (source, load, error, progress, abort, headers) => {
																					
										fetch(source)
											.then((res) => {
									            return res.blob();
									        }).then((res) => {
										      	load(res);
									        });
									}
								}}
								onupdatefiles={(files) => {

									if(files.length > 0){

										let fileItems = files.map(fileItem => fileItem.file);

										if(files[0].origin !== 3){ // NOT LOADED ON INIT
											this.setState({
												files: fileItems,
												files_changed: true
											});
										}
										
									}else{
										this.setState({
											files: [],
											files_changed: true
										});
									}
								}}
								imageEditEditor={Doka.create({
									onconfirm: (output) => {
										this.setState({
											files_changed: true
										});
									}
								})}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
	
	render() {
				
		const { handleSubmit } = this.props;
		
		let title = i18n.t('retailers:user_title_add');
		let action = i18n.t('retailers:user_add_submit');
		
		if(this.state.id){
			title = i18n.t('retailers:user_title_edit');
			action = i18n.t('retailers:user_update_submit');
		}
				
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('retailers:user_toolbar_settings'),
						url: `${this.props.parentPath}/user/${this.state.type}${this.state.id ? '/' + this.state.id : ''}`,
						selected: true
					}]}
					buttons={[{
						label: action,
						submit: formName
					}]}
				/>
				
				{this.renderPage()}			
			</form>	
		);
	}
}

const validate = (values) => {	
		
	const errors = {};

	if (!values.forename) {
		errors.forename = 'Enter the users forename!';
	}
	
	if (!values.surname) {
		errors.surname = 'Enter the users surname!';
	}
	
	if (!values.email) {
		errors.email = 'Enter the users email!';
	}
	
	if (!values.language) {
		errors.language = 'Enter the users language!';
	}
	
	if (!values.timezone) {
		errors.timezone = 'Enter the users timezone!';
	}
	
	if (!values.type) {
		errors.type = 'Enter the users type!';
	}
	
	if (!values.status) {
		errors.status = 'Enter the users status!';
	}
			
	return errors;
}

function mapStateToProps({ users, account, retailers, regional, overlay, options, brand }, ownProps){
	return {
		users,
		account,
		retailers,
		regional,
		overlay,
		options,
		brand
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		usersFetch,
		userSave,
		fetchRetailersList,
		fetchRegionalList,
		fetchTimezones
	} )(EditUser)
);