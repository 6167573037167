import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import VirtualGrid from '../../components/chrome/virtual_grid';
import InputField from '../../components/forms/field.js';
import CountUp from 'react-countup';

import AssetGridItem from '../../components/assets/grid_item';
import DropzoneArea from '../../components/forms/dropzone';
import { fetchTags } from '../../actions/options';
import { imageResize } from '../../helpers/s3';
import { forceDownload } from '../../helpers/download'; 

import _ from 'lodash';
import i18n from '../../helpers/i18n'; 

import { fetchCollectionsList, searchAssetsList, saveAsset, downloadAsset } from '../../actions/assets';
import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';

class Assets extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'ASSETS')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions('assets', i18n.t('assets:title'));
		this.props.fetchCollectionsList();		
		this.props.fetchTags();
		
		this.state = {
			keyword: '',
			expanded: {
				collection: false, 
				image: false,
				video: false,
				document: false
			},
			selected: [],
			downloading: false,
			width: false,
			perPage: {
				image: 10,
				video: 10,
				document: 10
			},

			image: {
				total: false,
				results: [],
				osset: 0
			},
			video: {
				total: false,
				results: [],
				osset: 0
			},
			document: {
				total: false,
				results: [],
				osset: 0
			},
			types: {
				image: ['image'],
				video: ['video','youtube','vimeo'],
				document: ['pdf','document','presentation','spreadsheet']
			}
		}
		
		this.getKeywordBouncer = _.debounce((keyword) => {
			
			this.setState({
				keyword: keyword
			});
				
		}, 500);
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	toggleScroller(type){
				 			 		
 		return (
	 		<div className="options">
				<i className={`fal fa-arrows-h ${this.state.expanded[type] ? '' : 'selected'}`} onClick={() => { 
					
					let expanded = this.state.expanded;
			 		expanded[type] = false;
			 		
			 		let perPage = this.state.perPage;
			 		perPage[type] = (this.state.columns * 2)+2;
			 
			 		this.setState({
				 		expanded,
						perPage					
			 		});
			 		
				}}></i>
				<i className={`fal fa-th ${this.state.expanded[type] ? 'selected' : ''}`} onClick={() => { 
					
					let expanded = this.state.expanded;
			 		expanded[type] = true;
	
			 		let perPage = this.state.perPage;
			 		perPage[type] = this.state.columns * (Math.ceil(window.innerHeight/(this.state.width*0.7))+2);
			 
			 		this.setState({
				 		expanded,
				 		perPage
			 		});
			 		
				}}></i>
			</div>
 		);
	}
		
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 4;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		const expandedRows = this.state.width ? Math.ceil(window.innerHeight/(this.state.width*0.7))+2 : 5;
		
		this.setState({
			columns: columns,
			perPage: {
				image: this.state.expanded.image ? columns * expandedRows : (columns*2)+2,
				video: this.state.expanded.video ? columns * expandedRows : (columns*2)+2,
				document: this.state.expanded.document ? columns * expandedRows : (columns*2)+2
			}
		}, () =>{
			this.colSizer();
		});		
	}
	
	colSizer(){
			
		if(document.getElementById('col-sizer')){
			this.setState({
				width: document.getElementById('col-sizer').getElementsByClassName('scroller-col')[0].offsetWidth
			});
		}else{
			setTimeout(() => {
				this.colSizer();
			}, 100)
		}
	}
	
	cleanTags(type, tags){
		
		let ret = [];
																	
		_.forEach(tags, (tag, key) => {	
			
			if(this.props.options.tags[type] && this.props.options.tags[type][key]){
				tag = this.props.options.tags[type][key];
				ret.push(i18n.t(`brand_${this.props.brand.details.directory}:tag_${tag.tag}`));
			}else{
				ret.push(tag);
			}
		});
			
		return ret;
	}
	
	renderCollections(){
		
		let collections = [];
		let assets = [];
		let url = '';
		
		const collections_root = _.filter(this.props.assets.collections, { parent_id: null });
		
		_.forEach(collections_root, (collection, key) => {					
									
			assets = [];
			collection.sub = [];
			
			const organiseSub = (id) => {
				
				let collections = _.filter(this.props.assets.collections, { parent_id: id });
				
				_.forEach(collections, (child, key) => {
					collection.sub.push(child.name);
					collections[key].children = organiseSub(child.id);
				});
				
				return collections;
			}
			
			organiseSub(collection.id);
			
			_.forEach(this.props.assets.assets, (asset, key) => {					
										
				if(collection.assets.includes(asset.id)){
					
					if(this.state.keyword){
						
						if(asset.name !== '' && asset.name !== null && asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
							assets.push(asset);
						}else if(asset.tags){
							
							if(_.filter(this.cleanTags('asset', asset.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
								assets.push(asset);
								return;
							}
						}
						
					}else{
						assets.push(asset);
					}
				}
			});
			
			collection.counter = assets.length;
			collection.sub_counter = collection.sub.length;
			
			if(this.state.keyword){
								
				collection.sub_counter = _.filter(collection.sub, title => (title.toLowerCase().includes(this.state.keyword.toLowerCase()))).length;
				
				if(collection.counter > 0 || collection.sub_counter > 0){
					collections.push(collection);
				}else{
					
					if(collection.name !== '' && collection.name !== null && collection.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
						collections.push(collection);
					}else if(collection.tags){
						
						if(_.filter(this.cleanTags('collection', collection.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
							collections.push(collection);
							return;
						}
					}
				}
				
							
			}else{
				collections.push(collection);
			}

		}, this);
					
		collections = _.orderBy(collections, ['created.date'],['desc']); 
				
		if(this.state.keyword){
			//url = `?keyword=${encodeURI(this.state.keyword)}`;
		}

		return (
			<div className="c-card grid-scroller">
				<h4>
					{i18n.t('assets:title_collections')}
					<span className="c-badge c-badge--small badge-light u-ml-xsmall">
						<CountUp 
							delay={0} 
							duration={1} 
							end={_.isEmpty(collections) ? 0 : collections.length}
							separator="," 
							preserveValue={true}
						/>
					</span>

					{collections.length > this.state.columns && 
	         			this.toggleScroller('collection')
	         		}
				</h4>
				
				<VirtualGrid
	         		columnCount={this.state.expanded.collection ? this.state.columns : collections.length}
	         		columnWidth={this.state.width}
	         		rowCount={this.state.expanded.collection ? Math.ceil(collections.length/this.state.columns) : 1}
	         		overscanRowCount={10}
					overscanColumnCount={10}
					className={`collections grid ${this.state.expanded.collection ? 'expanded' : ''}`}
					data={collections}
					renderEmpty={() => {
						return (
							<div className="u-pb-medium">
				         		{i18n.t('assets:collections_empty_description')}
				         	</div>
						);
					}}
					renderItem={(collection, key) => {
	
						let locked = '';
		
						if(collection.readonly === 1){
							locked = (
								<i className="far fa-lock locked"></i>
							);
						}
								
						return (
							<DropzoneArea
								key={`collection_${key}`}
								type="multiple"
								processFiles={(posting, percentIndicator, callback, callbackError) => {
									
									posting.append('collection_id', collection.id);
									posting.append('source', 'local');
									
									this.props.saveAsset(false, false, posting, (percent) => {
										percentIndicator(percent);
									}, (assets) => {
										
										callback();
										
										let image = 0;
										let video = 0;
										let document = 0;
										
										_.forEach(assets, (asset, key) => {
											
											if(this.state.types.image.includes(asset.type)){
												++image;
											}else if(this.state.types.video.includes(asset.type)){
												++video;
											}else if(this.state.types.document.includes(asset.type)){
												++document;
											}
										});
										
										if(image > 0){
											this.getData('image', 0, this.state.keyword, true);
										}
										
										if(video > 0){
											this.getData('image', 0, this.state.keyword, true);
										}
										
										if(document > 0){
											this.getData('image', 0, this.state.keyword, true);
										}
										
									}, callbackError);
								}}
								border={true}
								disabled={collection.readonly === 1 ? true : false}
							>
								<Link to={`/assets/collection/${collection.id}${url}`} className="c-card c-card-collection is-animated u-text-center equalize">
									<div className="u-mb-small directory">
										<i className="fal fa-folder"></i>
										{locked}
									</div>
									<div className="info">
										<h4>{collection.name}</h4>
										<p>
											{(!this.state.keyword || collection.counter > 0) && 
												<React.Fragment>
													<CountUp 
														delay={0} 
														duration={1} 
														end={collection.counter}
														separator="," 
														preserveValue={true}
													/> {collection.counter === 1 ? 'Asset' : 'Assets'}
												</React.Fragment>
											}
											
											{(!this.state.keyword || collection.counter > 0) && collection.sub_counter > 0 && 
												` / `
											}
											
											{collection.sub_counter > 0 && 
												<React.Fragment>
													<CountUp 
														delay={0} 
														duration={1} 
														end={collection.sub_counter}
														separator="," 
														preserveValue={true}
													/> {collection.sub_counter === 1 ? 'Collection' : 'Collections'}
												</React.Fragment>
											}
										</p>
									</div>
								</Link>					
							</DropzoneArea>
						);
					}}
	         	/>
			</div>
		);	
	}

	renderFiles(type){
		
		if(!this.state.width){
			return null;
		}
							
		return (
         	<div className={`c-card grid-scroller ${this.state.expanded[type] ? 'expanded' : ''}`} key={type}>
	         	<h4>
	         		{i18n.t(`assets:type_title`, { type: type })}
	         		{this.state[type].total !== false && 
		         		<span className="c-badge c-badge--small badge-light u-ml-xsmall">
		         			<CountUp 
								delay={0} 
								duration={1} 
								end={this.state[type].total}
								separator="," 
								preserveValue={true}
							/>
						</span>
	         		}
	         		
	         		{this.state[type].total > this.state.columns && 
	         			this.toggleScroller(type)
	         		}
	         	</h4>
	         	
	         	<VirtualGrid
	         		columnCount={this.state.expanded[type] ? this.state.columns : this.state[type].total}
	         		columnWidth={this.state.width}
	         		rowCount={this.state.expanded[type] ? Math.ceil(this.state[type].total/this.state.columns) : 1}
	         		overscanRowCount={10}
					overscanColumnCount={10}
					className={`assets grid ${this.state.expanded[type] ? 'expanded' : ''}`}
					keyword={this.state.keyword}
					getData={(offset, callback) => {
						
						this.props.searchAssetsList(this.state.types[type], offset, this.state.perPage[type], this.state.keyword, (total, results) => { 
							callback(total, results)
						});
					}}
					setTotal={(total) => {
						let current = this.state[type];
						
						current.total = total;
						
						this.setState({
							[type]: current
						})
					}}
					renderEmpty={() => {
						return (
							<div className="u-pb-medium">
				         		{i18n.t(`assets:type_empty_description`, { type: type })}
				         	</div>
						);
					}}
					renderItem={(id, key) => {
						
						if(!id || !this.props.assets.assets || !this.props.assets.assets[id]){ 
							return false;
						}
						
						const asset = this.props.assets.assets[id];
						const selected = this.state.selected.includes(asset.id) ? true : false;
						
						let onSelect = 'disabled';
								
						if(asset.type !== 'youtube' && asset.type !== 'vimeo'){
							onSelect = (id) => {
	
								let selected = this.state.selected;
								
								if(!selected.includes(id)){
									selected.push(id);
								}else{
									
									let index = selected.indexOf(id);
						 
								    if(index > -1) {
								    	selected.splice(index, 1);
								    }
								}
								
								this.setState({
									selected: selected
								})
							}
						}
								
						return (
							<AssetGridItem 
								key={`${type}_${key}`}
								asset={asset}
								onSelectItem={onSelect}
								selected={selected}
								icon={selected ? 'fa-minus' : 'fa-plus'}
								cols={false}
							/>
						);
					}}
	         	/>
			</div>
		);	
	}

	render() {
	
		let { assets } = this.props;

		if(!assets.collections){
			return (
				<Loading />
			);
		}
		
		let buttons = [
			{
				icon: 'fa-download',
				className: this.state.downloading ? 'processing' : false,
				onClick: () => {
					
					this.setState({ downloading: true });
					
					this.props.downloadAsset(this.state.selected.join(','), (url, filename) => {
						forceDownload(url, filename);
						this.setState({ downloading: false });
					});				
				},
				outline: true,
				tooltip: i18n.t('assets:download_tooltip')
			},
			{
				label: i18n.t('assets:action_add_collection'),
				url: `${this.props.location.pathname}/collection/add`
			}
		];
		
		if(this.state.selected.length === 0){
			buttons.shift();
		}
		
		const background = imageResize(`brands/${this.props.brand.details.directory}/heroes/assets.jpg`, 1500);

		let fieldOptions = {};
		
		if(this.state.keyword){
			fieldOptions.onClearFunc= () => {
				this.setState({
					keyword: ''
				}, () => {
					document.getElementsByClassName('clear-field')[0].value = '';
					document.getElementsByClassName('clear-field')[0].focus();
				});
			};
		}
								
		return (
			<React.Fragment>

				<div className="jumbotron" style={{ backgroundImage: `url(${background})`}}>
					<div className="container">
						<div className="contents">
							<h2>{i18n.t('assets:hero_title')}</h2>
							<p>{i18n.t('assets:hero_description')}</p>
							
							<InputField
								name="keyword"
								type="text"
								className="clear-field"
								placeholder={i18n.t('assets:hero_keyword')}
								onChangeFunc={(event) => {
									this.getKeywordBouncer(event.target.value === '' ? false : event.target.value);									
								}}
								{...fieldOptions}
							/>
						</div>
					</div>
				</div>

				<Toolbar
					buttons={buttons}
				/>
										
				<div className="container">
					<div className="c-card" id="col-sizer">
						<div className="row">
							<div className="scroller-col col-sm-6 col-lg-4 col-xl-3">
								{/*SIZER COL FOR VIRTUAL GRID. */}
							</div>
						</div>
					</div>
					
					{this.renderCollections()}
					{this.renderFiles('image')}	
					{this.renderFiles('video')}	
					{this.renderFiles('document')}	
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets, account, brand, options }, ownProps){
	return {
		assets,
		account,
		brand,
		options
	};
}

export default connect(mapStateToProps, { 
	fetchCollectionsList, 
	searchAssetsList,
	setSectionOptions,
	saveAsset,
	downloadAsset,
	fetchTags
})(Assets);