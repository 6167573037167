import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSectionOptions } from '../../actions/chrome';
import i18n from '../../helpers/i18n'; 
import { fetchAnalytics, fetchStatsType, clearAnalytics } from '../../actions/stats';
import { checkPermission } from '../../helpers/permissions';
import Loading from '../../components/chrome/loading';
import moment from 'moment';
import 'moment/min/locales';
import _ from 'lodash';
import InputField from '../../components/forms/field.js';
import { Line } from 'react-chartjs-2';
import CountUp from 'react-countup';
import ReactTooltip from 'react-tooltip';
import Table from '../../components/chrome/table';
import EmptySection from '../../components/chrome/empty';
import { imageResize } from '../../helpers/s3';
import { fetchAssetsList } from '../../actions/assets';
import { fetchRetailersList, fetchRetailersGroups } from '../../actions/retailers';

class Analytics extends Component {

	constructor(props){
		
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'ANALYTICS')){
			this.props.history.push('/');
		}
		
		moment.locale(i18n.language);
		
		let startDate = moment().subtract(7,'days').toDate();
		let endDate = moment().toDate();
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		if(queryString.from){
			startDate = moment(queryString.from).toDate();
		}
		
		if(queryString.to){
			endDate = moment(queryString.to).toDate();
		}
		
		this.state = {
			date: {
	            startDate: startDate,
	            endDate: endDate
	        },
	        view: 'facebook',
	        retailers: [],
	        fetched_assets: []
		}
		
		this.props.setSectionOptions('analytics', i18n.t('analytics:title'));
		this.props.clearAnalytics();
	}
	
	componentDidMount(){
		
		window.scrollTo(0,0);
				       	
		if(checkPermission(this.props.account.permissions, 'RETAILERS')){
			this.props.fetchRetailersList();
		}
		
		if(checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS')){
			this.props.fetchRetailersGroups();
		}
		
		this.onInputDateChange();
	
		// 12/05/2021 - Disabled due to FB limits usage
		/*this.statsRefreshTimer = setInterval(() => {
			this.onInputDateChange();
		}, 60000); // EVERY MIN*/
	}
	
	componentWillUnmount(){
		clearInterval(this.statsRefreshTimer);
	}
	
	onInputDateChange(date, general){
		
		if(date){
			this.setState({
				date: date
			});
		}else{
			date = this.state.date;
		}
		
		let retailers = [];
		
		if(this.state.retailers && this.state.retailers.length > 0){
			
			_.forEach(this.state.retailers, (item, key) => {

				let value = item.value.split('|');
				
				switch(value[0]){
					
					case 'group':
						let group = this.props.retailers.groups[value[1]];

						if(group.retailers && group.retailers.length > 0){
							retailers = [...retailers, ...group.retailers]
						}
						break;
						
					case 'retailer':
						retailers.push(parseFloat(value[1]));
						break;
						
					default:
					
						break;
				}
			});
			
			retailers = _.uniq(retailers);
		}
			
		if(general !== false){
			this.props.fetchAnalytics(
				moment(date.startDate).format('YYYY-MM-DD'),
				moment(date.endDate).format('YYYY-MM-DD'),
				false, 
				false, 
				retailers
			);
		}
		
		this.props.fetchStatsType(
			moment(date.startDate).format('YYYY-MM-DD'),
			moment(date.endDate).format('YYYY-MM-DD'),
			this.state.view,
			retailers,
			() => {
				
				// LOAD IN THE FIRST ASSET
				if(this.props.stats.types[this.state.view] && this.props.stats.types[this.state.view].length > 0){
					
					let assets = [];
				
					if(this.props.stats.types[this.state.view]){
						
						_.forEach(this.props.stats.types[this.state.view], (campaign) => {
							
							if(campaign.items && campaign.items.assets && !_.isEmpty(campaign.items.assets)){
								assets.push(campaign.items.assets[0]);
							}
						});
					}
					
					assets = _.difference(_.uniq(assets), this.state.fetched_assets);
			
					if(assets.length > 0){
						this.props.fetchAssetsList(assets);
					}
					
					this.setState({
						fetched_assets: this.state.fetched_assets ? this.state.fetched_assets.concat(assets) : assets
					});
				}
			}
		);
	}
	
	onInputRetailerChange(selected){
				
		this.setState({
			retailers: selected
		}, () => {
			this.onInputDateChange();
		});		
	}
	
	renderOverview(){
				
		let labels = [];
		let datasets = [];
		
		let data = {
			impressions: {},
			clicks: {},
			engagement: {},
			conversion: {},
			value: {}
		}
			
		let types = {}
				
		_.forEach(this.props.stats.analytics, (value, key) => {
						
			let dates = [];
					
			_.forEach(value, (day, key2) => {
	
				if(labels.length === 0){
					dates.push(moment(key2).format('D/M'));
				}
											
				if(day.impression){
					
					if(data.impressions[key2]){
						data.impressions[key2] = data.impressions[key2] + day.impression;
					}else{
						data.impressions[key2] = day.impression;
					}
					
				}else if(!data.impressions[key2]){
					data.impressions[key2] = 0
				}
				
				if(day.click){
					
					if(data.clicks[key2]){
						data.clicks[key2] = data.clicks[key2] + day.click;
					}else{
						data.clicks[key2] = day.click;
					}
					
				}else if(!data.clicks[key2]){
					data.clicks[key2] = 0
				}
				
				if(day.engagement){
					
					if(data.engagement[key2]){
						data.engagement[key2] = data.engagement[key2] + day.engagement;
					}else{
						data.engagement[key2] = day.engagement;
					}
					
				}else if(!data.engagement[key2]){
					data.engagement[key2] = 0
				}
				
				if(day.conversion){
					
					if(data.conversion[key2]){
						data.conversion[key2] = data.conversion[key2] + day.conversion;
					}else{
						data.conversion[key2] = day.conversion;
					}
					
				}else if(!data.conversion[key2]){
					data.conversion[key2] = 0
				}
				
				if(day.value){
					
					if(data.value[key2]){
						data.value[key2] = data.value[key2] + day.value;
					}else{
						data.value[key2] = day.value;
					}
					
				}else if(!data.value[key2]){
					data.value[key2] = 0
				}
			});
			
			if(labels.length === 0){
				labels = dates;
			}
		});
				
		types = {
			impressions: 'Impressions',
			clicks: 'Clicks',
			engagement: 'Engagement',
			conversion: 'Conversions',
			value: 'Revenue'
		}
		
		if(types.impressions){
			datasets.push({
				label: types.impressions,
				backgroundColor: '#2083fe',
				borderColor: '#2083fe',
				borderWidth: 2,
				data: _.values(data.impressions),
				fill: false,
				pointRadius: 0
			});
		}
		
		if(types.clicks){
			datasets.push({
				label: types.clicks,
				fill: false,
				backgroundColor: '#28c76f',
				borderColor: '#28c76f',
				borderWidth: 2,
				data: _.values(data.clicks),
				pointRadius: 0
			});
		}
		
		if(types.engagement){
			datasets.push({
				label: types.engagement,
				fill: false,
				backgroundColor: '#ffc343',
				borderColor: '#ffc343',
				borderWidth: 2,
				data: _.values(data.engagement),
				pointRadius: 0
			});
		}
		
		if(types.conversion){
			datasets.push({
				label: types.conversion,
				fill: false,
				backgroundColor: '#768093',
				borderColor: '#768093',
				borderWidth: 2,
				data: _.values(data.conversion),
				pointRadius: 0
			});
		}
		
		if(types.value){
			datasets.push({
				label: types.value,
				fill: false,
				backgroundColor: '#ee4343',
				borderColor: '#ee4343',
				borderWidth: 2,
				data: _.values(data.value),
				pointRadius: 0
			});
		}	
			
		return (
			<div className="c-card">				
				<div style={{ height: '350px', width: 'calc(100% + 20px)', marginLeft: '-10px'  }}>
					<Line
						data={{
							labels: labels,
							datasets: datasets
						}}
						options={{
							responsive: true,
							maintainAspectRatio: false,
							title: {
								display: false,
							},
							legend: {
								display: false,
							},
							layout: {
					            padding: {
					                left: 10,
					                right: 10,
					                top: 30,
					                bottom: 30
					            }
					        },
							tooltips: {
								mode: 'index',
								intersect: false,
								callbacks: {
					                title: (tooltipItem, data) => {
						                return moment(tooltipItem[0].xLabel, 'D/M').format('LL');
						            }
							    }
							},
							hover: {
								mode: 'nearest',
								intersect: true
							},
							scales: {
								xAxes: [{
									display: true,
									scaleLabel: {
										display: false,
										scaleLabel: {
											display: false
										}
									}
								}],
								yAxes: [{
									display: false,
									scaleLabel: {
										display: false
									},
									ticks: {
										min: 0
									}
								}]
							}
						}}
					/>
				</div>														
			</div>
		);
	}
	
	renderType(type){
				
		let labels = [];
		let datasets = [];
		let icon = '';
		
		let data = {
			impressions: [],
			clicks: [],
			engagement: [],
			conversion: [],
			value: []
		}
		
		let types = {}
				
		_.forEach(this.props.stats.analytics[type], (day, date) => {
					
			labels.push(moment(date).format('D/M'));
			
			if(day.impression){
				data.impressions.push(day.impression);
			}else{
				data.impressions.push(0);
			}
			
			if(day.click){
				data.clicks.push(day.click);
			}else{
				data.clicks.push(0);
			}
			
			if(day.engagement){
				data.engagement.push(day.engagement);
			}else{
				data.engagement.push(0);
			}
			
			if(day.conversion){
				data.conversion.push(day.conversion);
			}else{
				data.conversion.push(0);
			}
			
			if(day.value){
				data.value.push(day.value);
			}else{
				data.value.push(0);
			}
		});
		
		switch(type){
			
			case 'facebook':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fab fa-facebook-f';
				break;
				
			case 'instagram':
				types = {
					impressions: 'Impressions',
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fab fa-instagram';
				break;
				
			case 'twitter':
				types = {
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fab fa-twitter';
				break;
				
			case 'email':
				types = {
					impressions: 'Opens',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-envelope';
				break;
				
			case 'landing':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-laptop';
				break;
				
			case 'slider':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-arrows-h';
				break;
				
			case 'advert':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				icon = 'fas fa-ad';
				break;
				
			default:
			
				break;
		}
		
		if(types.impressions){
			datasets.push({
				label: types.impressions,
				backgroundColor: '#2083fe',
				borderColor: '#2083fe',
				borderWidth: 2,
				data: data.impressions,
				fill: false,
				pointRadius: 0
			})
		}
		
		if(types.clicks){
			datasets.push({
				label: types.clicks,
				fill: false,
				backgroundColor: '#28c76f',
				borderColor: '#28c76f',
				borderWidth: 2,
				data: data.clicks,
				pointRadius: 0
			})
		}
		
		if(types.engagement){
			datasets.push({
				label: types.engagement,
				fill: false,
				backgroundColor: '#ffc343',
				borderColor: '#ffc343',
				borderWidth: 2,
				data: data.engagement,
				pointRadius: 0
			})
		}
				
		if(types.conversion){
			datasets.push({
				label: types.conversion,
				fill: false,
				backgroundColor: '#768093',
				borderColor: '#768093',
				borderWidth: 2,
				data: _.values(data.conversion),
				pointRadius: 0
			})
		}
		
		if(types.value){
			datasets.push({
				label: types.value,
				fill: false,
				backgroundColor: '#ee4343',
				borderColor: '#ee4343',
				borderWidth: 2,
				data: _.values(data.value),
				pointRadius: 0
			})
		}	
		
		return (
			<div className="col-md-4">
				<div 
					className={`c-card equalize clickable ${this.state.view === type ? 'selected' : ''}`}
					onClick={() => {
						
						this.setState({
							view: type
						}, () => {
							this.onInputDateChange(false, false);
						});						
					}}
				>
					<h4>
						<i className={icon}></i>
						{i18n.t(`campaigns:event_type_${type}`)}
					</h4>
					
					<div style={{ height: '150px', width: 'calc(100% + 20px)', marginLeft: '-10px' }}>
						<Line
							data={{
								labels: labels,
								datasets: datasets
							}}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								title: {
									display: false,
								},
								legend: {
									display: false,
								},
								layout: {
						            padding: {
						                left: 10,
						                right: 10,
						                top: 30,
						                bottom: 30
						            }
						        },
								tooltips: {
									mode: 'index',
									intersect: false,
									callbacks: {
						                title: (tooltipItem, data) => {
							                
							                return moment(tooltipItem[0].xLabel, 'D/M').format('LL');
							               
							            }
								    }
								},
								hover: {
									mode: 'nearest',
									intersect: true
								},
								scales: {
									xAxes: [{
										display: false,
										scaleLabel: {
											display: true,
											scaleLabel: {
												display: false
											}
										}
									}],
									yAxes: [{
										display: false,
										scaleLabel: {
											display: false
										},
										ticks: {
											min: 0
										}
									}]
								}
							}}
						/>
					</div>
					
					<ul className="stat-totals">
					
						{types.impressions && 
							<li>
								<span className="c-icon c-icon--info" data-tip={types.impressions}>
									<i className="fal fa-eye"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.impressions)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
						
						{types.clicks && 
							<li>
								<span className="c-icon c-icon--success" data-tip={types.clicks}>
									<i className="fal fa-mouse-pointer"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.clicks)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
						
						{types.engagement && 
							<li>
								<span className="c-icon c-icon--warning" data-tip={types.engagement}>
									<i className="fal fa-thumbs-up"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.engagement)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
												
						{types.conversion && 
							<li>
								<span className="c-icon c-icon--secondary" data-tip={types.conversion}>
									<i className="fal fa-receipt"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.conversion)} 
										separator="," 
										preserveValue={true}
									/>
								</h4>
							</li>
						}
												
						{types.value && 
							<li>
								<span className="c-icon c-icon--danger" data-tip={types.value}>
									<i className="fal fa-money-bill"></i>
								</span>
										
								<h4>
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.sum(data.value)} 
										separator="," 
										preserveValue={true}
										decimals={2}
									/>
								</h4>
							</li>
						}
					</ul>										
				</div>
			</div>
		);
	}
	
	renderCampaigns(){
		
		if(!this.props.stats.types || !this.props.stats.types[this.state.view]){
			return (
				<Loading 
					className="inline"
				/>
			);
		}
		
		let data = this.props.stats.types[this.state.view];
		let cols = [
			{
				key: "image",
				label: "",
				format: 'image_circle',
				className: 'tight'
			},
			{
				key: "name",
				label: "Name",
				sortable: true
			}
		];
		
		let types = {};
		let sort = 'impression';
		
		switch(this.state.view){
		
			case 'facebook':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				sort = 'engagement';
				break;
				
			case 'instagram':
				types = {
					impressions: 'Impressions',
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				sort = 'engagement';
				break;
				
			case 'twitter':
				types = {
					engagement: 'Engagement',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				
				sort = 'engagement';
				break;
				
			case 'email':
				types = {
					impressions: 'Opens',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				break;
				
			case 'landing':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				break;
				
			case 'slider':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				break;
				
			case 'advert':
				types = {
					impressions: 'Impressions',
					clicks: 'Clicks',
					conversion: 'Conversions',
					value: 'Revenue'
				}
				break;
				
			default:
			
				break;
		}
		
		if(types.impressions){
			cols.push({
				key: "impression",
				label: {
					icon: 'far fa-eye',
					tooltip: types.impressions,
				},
				sortable: true,
				className: "tight icon"
			});
		}
		
		if(types.clicks){
			cols.push({
				key: "click",
				label: {
					icon: 'far fa-mouse-pointer',
					tooltip: types.clicks,
				},
				sortable: true,
				className: "tight icon"
			});
		}
		
		if(types.engagement){
			cols.push({
				key: "engagement",
				label: {
					icon: 'far fa-thumbs-up',
					tooltip: types.engagement,
				},
				sortable: true,
				className: "tight icon"
			});
		}	
		
		if(types.conversion){
			cols.push({
				key: "conversion",
				label: {
					icon: 'far fa-receipt',
					tooltip: types.conversion,
				},
				sortable: true,
				className: "tight icon"
			});
		}
		
		if(types.value){
			cols.push({
				key: "value",
				label: {
					icon: 'far fa-money-bill',
					tooltip: types.value,
				},
				sortable: true,
				className: "tight icon"
			});
		}
		
		let dataCleaned = [];
				
		_.forEach(data, (campaign, key) => {
			
			let valid = false;
			
			if(campaign.stats !== null){
			
				if(types.impressions){
					
					if(campaign.stats.impression && campaign.stats.impression > 0){
						valid = true;
					}
				}
				
				if(types.clicks){
					
					if(campaign.stats.click && campaign.stats.click > 0){
						valid = true;
					}
				}
				
				if(types.engagement){
					
					if(campaign.stats.engagement && campaign.stats.engagement > 0){
						valid = true;
					}
				}
				
				if(types.conversion){
					
					if(campaign.stats.conversion && campaign.stats.conversion > 0){
						valid = true;
					}
				}
				
				if(types.value){
					
					if(campaign.stats.value && campaign.stats.value > 0){
						valid = true;
					}
				}
			}
			
			if(valid){
				dataCleaned.push(campaign);
			}
			
		});
			
				
		if(_.isEmpty(dataCleaned)){
					
			return(
				<EmptySection
					icon="fa-exclamation-circle"
					title="No Campaigns"
					description="No Campaigns are within the analytic data."
				/>
			);
			
		}else{
				
			return (
				<React.Fragment>
					<p className="u-mb-small">Campaigns which are within the {i18n.t(`campaigns:event_type_${this.state.view}`)} totals for the selected period.</p>
				
					<Table 
						columns={cols}
						data={_.map(dataCleaned, (campaign, key) => {
																							
							let image = false;	
							let url = false;
													
							if(campaign.items){
											
								if(campaign.items.assets && !_.isEmpty(campaign.items.assets) && this.props.assets.assets && this.props.assets.assets[campaign.items.assets[0]]){
									
									let asset = this.props.assets.assets[campaign.items.assets[0]];
									image = imageResize(asset.thumbnail, 100, 100);	
									url = `/asset/${asset.id}`;				
								}
							}
													
							return ({
								id: campaign.id,
								image: {
									src: image,
									icon: 'fal fa-hashtag',
									alt: campaign.internal_name,
									url: url
								},
								name: campaign.internal_name,
								click: campaign.stats.click ? campaign.stats.click : 0,
								engagement: campaign.stats.engagement ? campaign.stats.engagement : 0,
								impression: campaign.stats.impression ? campaign.stats.impression : 0,
								conversion: campaign.stats.conversion ? campaign.stats.conversion : 0,
								value: campaign.stats.value ? campaign.stats.value : 0,								
								actions: ['analytics']
							});
						})}
						actions={{
							/*analytics: {
								tooltip: i18n.t('dashboard:campaigns_action_analytics'),
								icon: 'fal fa-analytics',
								url: '/campaigns/[ID]/analytics'
							},*/
							edit: {
								tooltip: i18n.t('dashboard:campaigns_action_edit'),
								icon: 'fal fa-edit',
								url: '/campaigns/[ID]'
							}
						}}
						sort={{
							column: sort,
							order: 'desc'
						}}
						rowClick={(row) => {
							if(row.actions.includes('analytics')){
								this.props.history.push(`/campaigns/${row.id}/analytics?from=${moment(this.state.date.startDate).format('YYYY-MM-DD')}&to=${moment(this.state.date.endDate).format('YYYY-MM-DD')}`);
							}else{
								this.props.history.push(`/campaigns/${row.id}`);
							}
						}}
					/>	
				</React.Fragment>
			);
		}
	}

	render() {

		if(!this.props.stats.analytics){
			return (
				<Loading />
			);
		}
		
		let filters = [];
		let filters_label = [];
		
		if(this.props.retailers.groups && this.props.retailers.groups.length > 0){
			filters_label.push('retailer groups');
			filters.push({
				label: 'Retailer Groups',
				options: _.map(_.orderBy(this.props.retailers.groups, ['name'],['asc']), (item, key2) => {
					return {
						label: item.name,
						labelDropdown: `${item.name} (${item.retailers ? item.retailers.length : 0} retailer${item.retailers.length === 1 ? '' : 's'})`,
						value: `group|${item.id}`,
						icon: 'fal fa-link'
					}
				})
			});
		}
		
		if(this.props.retailers.retailers && this.props.retailers.retailers.length > 0){
			filters_label.push('individual retailers');
			filters.push({
				label: 'Retailers',
				options: _.map(_.orderBy(this.props.retailers.retailers, ['name'],['asc']), (item, key2) => {
					return {
						label: item.name,
						value: `retailer|${item.id}`,
						icon: 'fal fa-store-alt'
					}
				})
			});
		}
		
		if(filters.length > 0){
			if(filters_label.length > 1){
				filters_label = `${filters_label.slice(0, -1).join(', ')} or ${filters_label.slice(-1)}`;
			}else{
				filters_label = filters_label[0]
			}
		}
					
		return (
			<div className="container">
				<div className="row">
					<div className="col-md-7">
						<div className="c-card equalize">
							<h4 className="u-mb-medium">
								Daily Overview
								<div className="options">
					         		
					         		<InputField
										name="date_range"
										type="datepicker"
										range={{
											months: 2
										}}
										maxDate={moment().toDate()}
										selected={this.state.date}
										alignRight={true}
										onChangeFunc={this.onInputDateChange.bind(this)}
									/>
				         		</div>
							</h4>
							
							{filters.length > 0 && 
								<div className="">
						         		
					         		<InputField
										placeholder={`Filter by ${filters_label}`} 
										name="retailers"
										type="suggest"
										options={filters}
										multiple={true}
										selected={this.state.retailers}
										onChangeFunc={this.onInputRetailerChange.bind(this)}
									/>
				         		</div>
			         		}
			         									
							{this.renderOverview()}
							
							<div className="row">
								{this.renderType('facebook')}
								{this.renderType('instagram')}
								{this.renderType('twitter')}
								{this.renderType('email')}
								{this.renderType('landing')}
								{this.renderType('slider')}
								{this.renderType('advert')}
							</div>
						</div>	
					</div>
					<div className="col-md-5">
						<div className="c-card equalize">
							<h4 className="u-mb-medium">Campaigns Breakdown</h4>
							{this.renderCampaigns()}
						</div>
					</div>
				</div>
				<ReactTooltip 
					effect="solid"
				/>			
			</div>
		);

		/*
			
			<div className="c-card u-pb-zero">
					<div className="row">

						<div className="col-md-4">
							<div className="c-card stats-box">
								<span className="c-icon c-icon--info u-mb-small">
									<i className="fal fa-eye"></i>
								</span>
										
								<h3 className="c-text--subtitle">Impressions</h3>
								<h1>
									<CountUp 
										delay={0} 
										duration={1} 
										end={impressions} 
										separator="," 
										preserveValue={true}
									/>
								</h1>
							</div>
						</div>
				
						<div className="col-md-4">
							<div className="c-card stats-box">
								<span className="c-icon c-icon--success u-mb-small">
									<i className="fal fa-mouse-pointer"></i>
								</span>
							
								<h3 className="c-text--subtitle">Clicks</h3>
								<h1>
									<CountUp 
										delay={0} 
										duration={1} 
										end={clicks} 
										separator=","
										preserveValue={true} 
									/>
								</h1>
							</div>
						</div>
						
						<div className="col-md-4">
							<div className="c-card stats-box">
								<span className="c-icon c-icon--warning u-mb-small">
									<i className="fal fa-thumbs-up"></i>
								</span>
							
								<h3 className="c-text--subtitle">Engagements</h3>
								<h1>
									<CountUp 
										delay={0} 
										duration={1} 
										end={engagement} 
										separator=","
										preserveValue={true} 
									/>
								</h1>
							</div>
						</div>
					</div>
				</div>
			*/
	}
}

function mapStateToProps({ users, stats, account, assets, retailers }, ownProps){
	return {
		users,
		stats, 
		account,
		assets,
		retailers
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchAnalytics, 
	fetchStatsType,
	clearAnalytics,
	fetchAssetsList,
	fetchRetailersList, 
	fetchRetailersGroups
})(Analytics);