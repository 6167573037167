import React, { Component } from 'react';
import { Grid, AutoSizer, CellMeasurer, CellMeasurerCache, WindowScroller } from "react-virtualized";
import Loading from '../../components/chrome/loading';
import _ from 'lodash';

class VirtualGrid extends Component {
	
	constructor(props){
		
		super(props);
		
		this.state = {
			offset: 0,
			data: {
				total: false,
				results: [],
				offset: 0
			}
		}
		
		this.scrollBouncer = _.debounce((startIndex, endIndex) => {

			this.setState({
				offset: startIndex
			});
					
			this.getData(startIndex);
			
		}, 750);
				
		this.cache = new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 100
		});
		
		this.measureCallbacks = [];
		this.items = [];
		
		this.getData(0);
	}
		
	componentDidUpdate(prevProps){
		
		if(this.props.data){
			
			if(this.state.data.total === false || this.state.data.total !== this.props.data.length){
			
				this.setState({
					data: {
						results: this.props.data,
						total: this.props.data.length
					}
				}, () => {
					this.cache.clearAll();
				});
			}
			
		}else{
		
			let keywordPrev = false;
			
			if(prevProps.keyword){
				keywordPrev = prevProps.keyword;
			}
							
			if((this.props.keyword && this.props.keyword !== keywordPrev) || (keywordPrev && !this.props.keyword)){
				this.getData(0, true);
			}else if(prevProps.columnWidth !== this.props.columnWidth || prevProps.rowCount !== this.props.rowCount){
				this.cache.clearAll();
			}else if((prevProps.rowCount * prevProps.columnCount) !== (this.props.rowCount * this.props.columnCount)){
				this.cache.clearAll();
			}
		}
	}
	
	getData(offset, reset){
		
		if(this.props.getData){
				
			if(typeof offset === undefined || isNaN(offset)){
				offset = this.state.offset;
			}
								
			if(this.props.rowCount > 1 && this.props.columnCount){
				offset = offset - this.props.columnCount;
			}else{
				offset = offset-1;
			}
								
			if(offset < 0){
				offset = 0;
			}
		
			this.props.getData(offset, (total, list) => {
							
				let results = this.state.data;
				
				results.total = total;
	
				if(total > 0){
					
					if(reset || offset == 0){
						results.results = list;
					}else{
						
						let missing = offset+1 - results.results.length;
						
						if(missing > 0){
							missing = new Array(missing);
							list.unshift(...missing);
						}			
						
						results.results.splice(offset, list.length, ...list);
					}
				}else{
					results.results = [];
				}
													
				this.setState({
					data: results
				});	
				
				this.props.setTotal(total);		
			});	
		}	
	}
	
	render(){
				
		if(this.state.data.total === false || this.props.columnCount === false){
			return (
				<Loading 
					className="inline"
				/>
			);
		}
			
		if(this.state.data.total == 0){
			
			if(this.props.renderEmpty){
				return this.props.renderEmpty();
			}else{
				return null;
			}
		}
			
		const renderedSection = ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }) => {
				
			let startIndex = rowStartIndex * this.props.columnCount + columnStartIndex
			const stopIndex = rowStopIndex * this.props.columnCount + columnStopIndex
			
			let current = startIndex;
			let missing = false;
			
			while(current <= (stopIndex+2)){
				
				if(!this.state.data.results[current]){
					missing = true;
					startIndex = current;
					break;
				}
				
				++current;
			}
			
			if(missing){
				this.scrollBouncer(startIndex, stopIndex);
			}
		}
		
		const renderCell = ({ columnIndex, key, rowIndex, style, parent }) => {
							
			let i = columnIndex;
						
			if(rowIndex > 0){
				i = i + (this.props.columnCount * rowIndex);
			}
									
			if(i+1 > this.state.data.total){
				return null;
			}
			
			const getContent = measure => {
						
				this.measureCallbacks[columnIndex] = measure;
				const id = this.state.data.results[i];
				
				const content = this.props.renderItem(id, key);	
				
				if(!content){
					return (
						<div className="c-card pending">
							<Loading />
						</div>
					);
				}else{
					return content;
				}		
			};
			
			if(this.props.columnWidth){
				style = {...style, ...{ width: `${this.props.columnWidth}px` }};
			}
						
			return (
				<CellMeasurer 
			        key={key}
			        cache={this.cache}
			        parent={parent}
			        columnIndex={columnIndex}
			        rowIndex={rowIndex}
				>
					{({ measure }) => (
						<div 
							className="scroller-col"
							style={style} 
							ref={ref => this.items[columnIndex] = ref} //todo
							id={`item_${columnIndex}`}
						>
							{getContent(measure)}						
						</div>
					)}
				</CellMeasurer>
			);
		}
		
		return (
			<WindowScroller 
	 			//scrollElement={scrollElement}
	 		>
	            {({height, isScrolling, registerChild, scrollTop}) => (
	                <AutoSizer 
	                	disableHeight
						className="virtualized"
	                >
	                    {({width}) => (
							<div ref={el => registerChild(el)}>
								<Grid
									ref={registerChild}
									autoHeight
	                                isScrolling={isScrolling}
	                                scrollTop={scrollTop}
	                                width={window.innerWidth}
	                                height={height}
									columnCount={this.props.columnCount}
									columnWidth={this.props.columnWidth}
									rowCount={this.props.rowCount}
									overscanRowCount={this.props.overscanRowCount ? this.props.overscanRowCount : 5}
									overscanColumnCount={this.props.overscanColumnCount ? this.props.overscanColumnCount : 5}
									rowHeight={this.cache.rowHeight}
									cellRenderer={renderCell}
									deferredMeasurementCache={this.cache}
									className={this.props.className}
									onSectionRendered={renderedSection}
								/>
							</div>
						)}
					</AutoSizer>
				)}
			</WindowScroller>
		);
	}
}	

export default VirtualGrid;