import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectionSettings } from '../../../actions/connections';
import CopyCode from '../../chrome/copy_code';
import { showNotification } from '../../../actions/notifications';
import i18n from '../../../helpers/i18n'; 

class IntegrationsDomain extends Component {
	
	constructor(props){
		super(props);
       	this.props.connectionSettings(['dns']);
    }
    
    render() {		
		
		let domain = false;
		
		if(this.props.domain){
			domain = this.props.domain;
		}else if(this.props.retailer.domains && this.props.retailer.domains.custom){
			domain = this.props.retailer.domains.custom.domain;
		} 
		
		if(!domain){
			
			return (
				<React.Fragment>	
					No custom domain name set!
				</React.Fragment>	
			);
		
		}else{
		
			let ip = '';
			
			if(this.props.connections.settings && this.props.connections.settings.dns && this.props.connections.settings.dns.ip){
				ip = this.props.connections.settings.dns.ip;
			}
						
			return (
				<React.Fragment>						
					<ol className="numbererd">
						<li>Log in to the account of the domain provider and find the DNS settings for {domain}. Look for your domain management area, DNS configuration, or similar.</li>
						<li>
							Add an A record with the value: <CopyCode
								className="inline"
								value={ip}
								onCopy={() => {
									this.props.showNotification(
										'success',
										i18n.t('retailers:notification_integration_dns_value_copied_title'),
										i18n.t('retailers:notification_integration_dns_value_copied_description', { type: 'A' })
									);	
								}}
							/>
						</li>
						<li>Save the changes.</li>
					</ol>
				</React.Fragment>
			);
		}
	}
}

function mapStateToProps({ connections }, ownProps){
	return {
		connections
	};
}

export default connect(mapStateToProps, { 
	connectionSettings,
	showNotification
})(IntegrationsDomain);