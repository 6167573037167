import { retailersConstants } from '../constants/retailers';
import _ from 'lodash';

export default function(state = { }, action) {
	
	let retailers = {};

	switch (action.type) {
			
		case retailersConstants.RETAILERS_LIST:
		
			retailers = _.map(action.payload.data, (retailer, key) => {
				
				let details = {};
				
				if(retailer.details){
										
					_.forEach(retailer.details, (detail, key) => {	
						details[detail.type] = detail.value;
					});
				}
				
				retailer.details = details;
								
				return retailer;

			});
						
			return { 
				...state, 
				retailers: { ...state.retailers, ..._.mapKeys(retailers, 'id') }
			}
			
		case retailersConstants.RETAILERS_SAVE:
		case retailersConstants.RETAILERS_INTEGRATION_SAVE:
		
			retailers = _.map(action.payload.data.retailers, (retailer, key) => {
				
				let details = {};
				
				if(retailer.details){
										
					_.forEach(retailer.details, (detail, key) => {	
						details[detail.type] = detail.value;
					});
				}
				
				retailer.details = details;
								
				return retailer;

			});
					
			return { 
				...state, 
				retailers: { ...state.retailers, ..._.mapKeys(retailers, 'id') }
			}
			
		case retailersConstants.RETAILERS_GROUPS_LIST:
					
			return { 
				...state, 
				groups: { ...state.groups, ..._.mapKeys(action.payload.data, 'id') }
			}
			
		case retailersConstants.RETAILERS_GROUPS_SAVE:
		case retailersConstants.RETAILERS_GROUPS_ASSIGN:
					
			return { 
				...state, 
				groups: { ...state.groups, ..._.mapKeys(action.payload.data.groups, 'id') }
			}
		
		case retailersConstants.RETAILERS_DEVELOPER:
			
			let details = {};
			let retailer = action.payload;
													
			_.forEach(retailer.details, (detail, key) => {	
				details[detail.type] = detail.value;
			});
			
			retailer.details = details;
			
			return { 
				...state, 
				developer: action.payload
			}	
			
		default:
			return state;
	}
}