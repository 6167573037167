import React, { Component } from 'react';
import { overlayShow, overlayHide } from '../../actions/overlay';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { parseBack } from '../../helpers/back';

class OverlaySettings extends Component {
	
	constructor(props){
		super(props);
		this.escFunction = this.escFunction.bind(this);
		
		this.state = {
			back: false
		}
	}

	componentDidMount() {
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		let back = false;
		
		if(queryString.b){
			back = parseBack(queryString.b);
			this.setState({ back: back });
		}
		
		this.props.overlayShow(this.props.closeURL, this.props.className, back);
		document.addEventListener("keydown", this.escFunction, false);
	}

	componentWillUnmount() {

		document.removeEventListener("keydown", this.escFunction, false);
		
		this.props.overlayHide(() => { 
			
			if(this.props.closeAction){
				this.props.closeAction();
			}
		});
	}
	
	escFunction(event){
		if(event.keyCode === 27) {
			
			if(this.state.back){
				this.props.history.push(this.state.back.cancel ? this.state.back.cancel : this.state.back.url);
			}else{
				this.props.history.push(this.props.closeURL);
			}
		}
	}
	
	render() {
		return (
			<React.Fragment></React.Fragment>
		)
	}
}

function mapStateToProps({ users }, ownProps){
	return { users };
}

export default withRouter(connect(mapStateToProps, { 
	overlayShow, 
	overlayHide 
})(OverlaySettings));