import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import Loading from '../../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../../actions/forms';
import Toolbar from '../../../components/chrome/toolbar';

import IntegrationsSlider from '../../../components/retailers/integrations/slider';

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { saveRetailerIntegration, fetchRetailersList } from '../../../actions/retailers';
import { showNotification } from '../../../actions/notifications';
import SendDeveloper from '../../../components/retailers/send_developer';

var formName = 'formRetailersIntegrationsSlider';


class RetailersIntegrationsSlider extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}else{
				this.updateDomElements();
			}
       	});
       	
       	this.state = {
	       	id: id,
	       	empty: false
       	}
	}
	
	updateDomElements(){
		
		if(this.props.retailers.retailers && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];			
			
			this.setState({
				empty: (retailer.details.slider_empty_disable && retailer.details.slider_empty_disable === '1') ? true : false
			});
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
		  	
		this.props.saveRetailerIntegration(formName, 'slider', { empty_disable: this.state.empty }, () => {
			
			this.props.showNotification(
				'success',
				i18n.t('retailers:notification_integration_slider_updated_title'),
				i18n.t('retailers:notification_integration_slider_updated_description')
			);
				
			this.props.history.push(this.props.parentPath);
	  	});
	}
	
	onInputEmptyChange(event){
		this.setState({
			empty: event.target.checked
		});
	}

	render() {
			
		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}
				
		const { handleSubmit } = this.props;

		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_slider_title')}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('retailers:integration_slider_toolbar_settings'),
						url: `${this.props.parentPath}/integrations/slider`,
						selected: true
					}]}
					buttons={[{
						label: i18n.t('retailers:integration_slider_action_save'),
						submit: formName
					}]}
				/>
				
				<div className="row">
					
					<div className="col-xl-6">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_slider_settings_title')}</h4>
							
							<Field
								labelSwitch={i18n.t('retailers:integration_slider_label_empty')} 
								name="empty"
								type="switch"
								selected={this.state.empty}
								onChangeFunc={this.onInputEmptyChange.bind(this)}
								component={InputField}
							/>
						</div>
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_slider_instructions_title')}</h4>
						
							<IntegrationsSlider
								retailer={retailers.retailers[this.state.id]}
							/>
							
							<br/>
							<SendDeveloper />
						</div>
					</div>					
				</div>
			</form>
		);
		
	}
}

const validate = (values) => {	
	
	const errors = {};

	
	return errors;
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		showNotification,
		fetchRetailersList,
		saveRetailerIntegration
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersIntegrationsSlider)
);
