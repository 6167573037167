export const retailersConstants = {
    RETAILERS_LIST: 'RETAILERS_LIST',
    RETAILERS_SAVE: 'RETAILERS_SAVE',
    RETAILERS_GROUPS_LIST: 'RETAILERS_GROUPS_LIST',
    RETAILERS_GROUPS_SAVE: 'RETAILERS_GROUPS_SAVE',
    RETAILERS_INTEGRATION_SAVE: 'RETAILERS_INTEGRATION_SAVE',
    RETAILERS_GROUPS_ASSIGN: 'RETAILERS_GROUPS_ASSIGN',
    RETAILERS_DEVELOPER: 'RETAILERS_DEVELOPER'
};

