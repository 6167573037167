import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import CopyCode from '../../chrome/copy_code';
import { showNotification } from '../../../actions/notifications';
import { Link } from 'react-router-dom';

class IntegrationsConversion extends Component {

	render() {		

		return (		
			<ol className="numbererd">
				<li>
					Install the <Link to={this.props.code_url}>website code</Link>
				</li>
				<li>
					Copy the following code
					
					<CopyCode
						className="u-mt-small u-mb-small"
						value={`(function () {
						    cch.logTransaction(AMOUNT);
						})();`}
						onCopy={() => {
							this.props.showNotification(
								'success',
								i18n.t('retailers:notification_integration_conversion_copied_title'),
								i18n.t('retailers:notification_integration_conversion_copied_description')
							);	
						}}
					/>
					
				</li>
				<li>Paste this on the website page which is shown when a sale has been made, replacing AMOUNT with the value of the transaction.</li>
			</ol>
		);
	}
}

function mapStateToProps({ }, ownProps){
	return {

	};
}

export default connect(mapStateToProps, { 
	showNotification,
})(IntegrationsConversion);