import { articlesConstants } from '../constants/articles';
import _ from 'lodash';

export default function(state = { }, action) {

	switch (action.type) {
			
		case articlesConstants.ARTICLE_LIST:

			return { 
				...state, 
				articles: { ...state.articles, ..._.mapKeys(action.payload.data, 'id') }
			}
			
		case articlesConstants.ARTICLE_SAVE:
			
			let articles = { ...state.articles, ..._.mapKeys(action.payload.data.articles, 'id') }
			
			if(action.payload.data.duplicated){
				
				if(articles[action.payload.data.duplicated]){
					delete(articles[action.payload.data.duplicated]);
				}
			}
			
			return { 
				...state, 
				articles: articles
			}
			
		default:
			return state;
	}
}