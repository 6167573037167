import { optionsConstants } from '../constants/options';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {
		      
		case optionsConstants.TAGS_LIST:
			
			let tags = _.mapValues(_.groupBy(action.payload.data, 'type'), tlist => tlist.map(tag => _.omit(tag, 'type')));
			
			_.forEach(tags, (items, key) => {	
				tags[key] = _.mapKeys(items, 'id');
			});
			
			return { 
				...state, 
				tags: tags
			}
			
		case optionsConstants.FONTS_LIST:

			return { 
				...state, 
				fonts: _.mapKeys(action.payload.data, 'id')
			}
			
		case optionsConstants.TIMEZONES_LIST:

			return { 
				...state, 
				timezones: action.payload.data
			}
									
		default:
			return state;
	}
}