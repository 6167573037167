import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from '../forms/field.js';
import i18n from '../../helpers/i18n'; 
import Clock from 'react-live-clock';
import { fetchTimezones } from '../../actions/options';
import moment from 'moment-timezone';
import 'moment/min/locales';
import _ from 'lodash';

class Timezone extends Component {
	
	constructor(props){
		super(props);
		this.props.fetchTimezones();
		this.clockKey = 1;
				
		moment.locale(i18n.language);
	}
	
	render() {

		let timezoneTime = '';
		
		if(this.props.selected){
			
			++this.clockKey;
					
			timezoneTime = (
				<Clock 
					key={this.clockKey}
					//date={moment().tz('UTC')} 
					format={'LLL'} 
					ticking={true} 
					timezone={this.props.selected} 
				/>
			);
		}
		
		let Component = InputField;
		
		
		if(this.props.component){
			Component = this.props.component;
		}
		
		return (

			<Component
				label={this.props.label}
				labelRight={timezoneTime} 
				name="timezone"
				type="suggest"
				options={_.map(this.props.options.timezones, (timezone, key) => {
				
					return {
						label: i18n.t(`users:timezone_${timezone.label}`),
						value: timezone.value
					}

				})}
				selected={this.props.selected}
				onChangeFunc={this.props.onChangeFunc}
				component={InputField}
			/>
		);
	}
}

function mapStateToProps({ options }, ownProps){
	return {
		options
	};
}

export default connect(mapStateToProps, { 
	fetchTimezones
})(Timezone);