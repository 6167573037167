import { regionalConstants } from '../constants/regional';
import _ from 'lodash';

export default function(state = { }, action) {
	
	let regional_groups = {};

	switch (action.type) {
			
		case regionalConstants.REGIONAL_LIST:
		
			regional_groups = _.map(action.payload.data, (regional, key) => {
				
				let details = {};
				
				if(regional.details){
										
					_.forEach(regional.details, (detail, key) => {	
						details[detail.type] = detail.value;
					});
				}
				
				regional.details = details;
								
				return regional;

			});
						
			return { 
				...state, 
				groups: { ...state.groups, ..._.mapKeys(regional_groups, 'id') }
			}
			
		case regionalConstants.REGIONAL_SAVE:
		
			regional_groups = _.map(action.payload.data.regional, (regional, key) => {
				
				let details = {};
				
				if(regional.details){
										
					_.forEach(regional.details, (detail, key) => {	
						details[detail.type] = detail.value;
					});
				}
				
				regional.details = details;
								
				return regional;

			});
					
			return { 
				...state, 
				groups: { ...state.groups, ..._.mapKeys(regional_groups, 'id') }
			}
			
		default:
			return state;
	}
}