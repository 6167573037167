import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import Loading from '../../../components/chrome/loading';
import CopyCode from '../../../components/chrome/copy_code';
import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { fetchRetailersList } from '../../../actions/retailers';
import { showNotification } from '../../../actions/notifications';

class RetailersIntegrationsDeveloper extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}
       	});
       	
       	this.state = {
	       	id: id
       	}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	render() {
					
		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let retailer = retailers.retailers[this.state.id];
				
		return (

			<React.Fragment>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_developer_title')}
					</h2>
				</header>
				
				<div className="row">
					
					<div className="col-xl-6">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_developer_settings_title')}</h4>
							<p>{i18n.t('retailers:integration_developer_settings_intro')}</p>
							
							<a href={`https://${window.location.host}/developer/${retailer.details.key_developer}`} target="_blank" rel="noopener noreferrer"  className="u-mt-medium c-btn c-btn--info">
								{i18n.t('retailers:integration_developer_action_view')}
							</a>
						</div>
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_developer_instructions_title')}</h4>
							
							<ol className="numbererd">
								<li>
									Copy your unique developer URL below:
									
									<CopyCode
										className="u-mt-small u-mb-small"
										value={`https://${window.location.host}/developer/${retailer.details.key_developer}`}
										onCopy={() => {
											this.props.showNotification(
												'success',
												i18n.t('retailers:notification_integration_developer_copied_title'),
												i18n.t('retailers:notification_integration_developer_copied_description')
											);	
										}}
									/>
									
								</li>
								<li>Send the code to your developer</li>
							</ol>
						</div>
					</div>					
				</div>
			</React.Fragment>
		);
		
	}
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default connect(mapStateToProps, { 
	showNotification,
	fetchRetailersList
})(RetailersIntegrationsDeveloper);