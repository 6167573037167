import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n';
import InputField from '../../components/forms/field';
import moment from 'moment-timezone';
import { fetchCampaignsList, saveCampaignEvent } from '../../actions/campaigns';
import { fetchSubscribersGroups } from '../../actions/subscribers';
import { fetchTemplates, fetchBranding } from '../../actions/templates';
import Loading from '../../components/chrome/loading';
import TemplatePreview from '../../components/templates/preview';
import { startSubmission, stopSubmission } from '../../actions/forms';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import { showNotification } from '../../actions/notifications';
import { saveCampaignGeneral, deleteCampaignEvent } from '../../actions/campaigns';
import { Base64 } from 'js-base64';
import Toolbar from '../../components/chrome/toolbar';
import { Link } from 'react-router-dom';

import { connectionSettings, connectionGetUrl } from '../../actions/connections';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { imageUrl } from '../../helpers/s3';

import { saveRetailerIntegration } from '../../actions/retailers';

import { fetchAssetsList } from '../../actions/assets';
import { fetchArticlesList } from '../../actions/articles';
import { fetchProductList } from '../../actions/products';

import _ from 'lodash';
import { dateString } from '../../helpers/dates';
import { checkPermission } from '../../helpers/permissions';


var formName = 'formScheduleEvent';

class CampaignsScheduleEvent extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT')){
			this.props.history.push('/');
		}
		
		const { id, campaign } = this.props.match.params;

		if(!campaign){
			this.props.history.push('/campaigns');
		}
	
		let start = moment().toDate();
		let end = moment().toDate();
		let mode = 'view';
		let type = false;
		
		if(!id){
			
			const query = require('query-string');
			const queryString = query.parse(this.props.location.search);
						
			if(queryString.type){
				type = queryString.type;
			}
			
			if(queryString.start){
				start = moment(queryString.start).toDate();
			}
			
			if(queryString.end){
				end = moment(queryString.end).toDate();
			}
			
			//mode = 'edit';
		}
									
		this.state = {
			campaign: campaign,
			id: id ? id : false,
			type: type,
			mode: mode,
			errors: [],
			date_start: start,
			date_end: end,
			data: {},
			required: [],
			template: false,
			link: false,
			items: [],
			settings: [],
			view: false,
			viewMax: 3,
			status: 'Draft',
			url: false,
			sub_list: [],
			sub_list_error: false,
			issues: {}
		}
		
		this.props.fetchTemplates(false, () => {
			this.updateDomElements();
		});
		
		this.props.fetchBranding(() => {
			this.updateDomElements();
		});

		this.props.fetchCampaignsList(campaign, () => {
		    
	       	if(this.props.campaigns.campaigns && !this.props.campaigns.campaigns[campaign]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements();
			}
       	});
       	
       	this.connectionRefreshTimer = false;
	}
	
	componentDidMount(){
		this.updateDomElements();
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
		clearInterval(this.connectionRefreshTimer);
	}
	
	onInputSubscribersChange(selected){

		this.setState({
			sub_list: selected
		});
	}
	
	updateDomElements(){
		
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.campaign] && this.props.templates.templates){
			
			const campaign = this.props.campaigns.campaigns[this.state.campaign];
			
			// LOAD IN THE ASSIGNED ITEMS
			if(campaign.items.assets && !_.isEmpty(campaign.items.assets)){
				this.props.fetchAssetsList(campaign.items.assets, () => {
					this.setItems();
				});
			}
			
			if(campaign.items.articles && !_.isEmpty(campaign.items.articles)){
				this.props.fetchArticlesList(campaign.items.articles, () => {
					this.setItems();
				});
			}
			
			if(campaign.items.products && !_.isEmpty(campaign.items.products)){
				this.props.fetchProductList(campaign.items.products, () => {
					this.setItems();
				});
			}
			
			if(this.state.id){
						
				let event = _.find(campaign.events, { id: parseFloat(this.state.id) });
	
				if(event){
					
					if(this.props.templates.templates){
						
						let init = {
							...event,
							...event.data,
						}
						
						let link = false;
						
						if(event.data.link){
							
							link = {
								type: event.data.link.type,
								data: event.data.link.data
							}
							
							if(event.data.link.type === 'custom'){
								init.link_custom = event.data.link.data;
							}
						}
					
						this.setState({
							status: event.status, 
							type: event.type,
							data: event.data,
							url: event.url ? event.url : false,
							template: event.template_id,
							link: link,
							date_start: moment.utc(event.start.date).tz(this.props.account.profile.timezone).toDate(),
							date_end: event.end ? moment.utc(event.end.date).tz(this.props.account.profile.timezone).toDate() : false,
							issues: event.issues ? event.issues : {}
						}, () => {
							this.setRequired();
						});
						
						if(event.status === 'failed' || event.status === 'complete'){
												
							this.setState({
								view: 3
							});
							
							this.handleResize();
						}						
						
						this.props.initialize(init);
					}
					
					if(event.issues){
						
						switch(event.type){
							
							case 'email':
								this.props.fetchSubscribersGroups();
								break;
								
							case 'facebook':
								this.props.connectionSettings(['facebook']);
								break;
								
							case 'instagram':
								this.props.connectionSettings(['instagram']);
								break;
								
							default:
							
								break;
						}
					}
				}
				
			}else{
					
				let date_start = moment.utc(campaign.date_start.date).tz(this.props.account.profile.timezone).toDate();
				let date_end = moment.utc(campaign.date_end.date).tz(this.props.account.profile.timezone).toDate();
							
				this.setState({
					date_start: date_start,
					date_end: date_end
				});
				
				let init = {
					date_start: date_start,
					date_end: date_end
				};
				
				if(this.props.templates.templates && (!this.state.type || !this.state.template)){
				
					let type = this.state.type;
					
					if(!_.find(campaign.distribution, {type:type})){
						type = false;
					}
					
					if(!type){
					
						if(_.find(campaign.distribution, {type:'email'})){
							type = 'email';
						}else if(_.find(campaign.distribution, {type:'social'})){
							type = 'facebook'
						}else if(_.find(campaign.distribution, {type:'website'})){
							type = 'slider';
						}else if(_.find(campaign.distribution, {type:'landing'})){
							type = 'landing';
						}
					}
										
					if(type){
												
						let templates = _.filter(this.props.templates.templates, { 'type': type });
						let template = templates[Object.keys(templates)[0]];
						
						init.type = type;
						init.template = template.id;
			
						this.setState({
							template: template.id,
							type: type
						}, () => {
							this.setRequired();
						});
					}
				}
				
				this.props.initialize(init);
			}
		}
	}
	
	setItems(){
		
		const campaign = this.props.campaigns.campaigns[this.state.campaign];
		
		let items = {
			'images_headline': [],
			'images_secondary': [],
			'videos': [],
			'articles': [],
			'products': []
		};
					
		_.forEach(campaign.items, (value, key) => {

			switch(key){
				
				case 'assets':
					
					_.forEach(value, (asset, key2) => {
						
						if(this.props.assets.assets && this.props.assets.assets[asset]){
							
							let item = this.props.assets.assets[asset];
							
							let tmp = {
								id: item.id
							};
							
							if(item.type === 'youtube'){
								tmp.url = 'https://www.youtube.com/' + item.filename;
							}else if(item.type === 'vimeo'){
								tmp.url = 'https://www.vimeo.com/' + item.filename;	
							}else if(item.type === 'video'){
								tmp.url = imageUrl(item.filename);	
							}else{
								tmp.image = item.filename;
								
								if(item.crops){
									tmp.crops = item.crops;
								}
							}
							
							if(item.type === 'youtube' || item.type === 'vimeo' || item.type === 'video'){
								tmp.thumbnail = item.thumbnail;
								tmp.page = '/player/' + Base64.encode(JSON.stringify({ 'event': this.state.id, 'video': item.id }));
								
								items.videos.push(tmp);
							}else if(campaign.items.headline.includes(item.id)){
								items.images_headline.push(tmp);
							}else{
								items.images_secondary.push(tmp);
							}
						}
					});
					
					break;
					
				case 'articles':
				
					_.forEach(value, (article, key2) => {
												
						if(this.props.articles.articles && this.props.articles.articles[article]){
							
							let item = this.props.articles.articles[article];
							
							items.articles.push({
								id: item.id,
								title: item.title,
								image: item.image,
								summary: item.summary,
								url: item.url
							});
						}
					});
					
					break;
					
				case 'products':
				
					_.forEach(value, (product, key2) => {
												
						if(this.props.products.products && this.props.products.products[product]){
							
							let item = this.props.products.products[product];
							
							items.products.push({
								id: item.id,
								title: item.title,
								image: item.image,
								description: item.description,
								url: item.url,
								price: item.price_sale ? item.price_sale : item.price
							});
						}
					});
					
					break;
					
				default:
				
					break;
			}
		});	
		
		this.setState({
			items: items
		});
	}
	
	componentDidUpdate(){
		
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.campaign] && this.state.id){

			if(!_.find(this.props.campaigns.campaigns[this.state.campaign].events, { id: parseFloat(this.state.id) })){
				this.props.history.push(this.props.parentPath);
			}
		}		
	}
	
	refreshIssues(){
		this.props.fetchCampaignsList(this.state.campaign);
	}
	
	handleResize(){
		
		let current = this.state.view;
		let width = window.innerWidth;
		let max = 1
		
		if(width >= 1200){
			max = 3
		}else if(width >= 600){
			max = 2;
			
			if(current !== false && current === 3){
				current = 2;
			}
		}else if(current !== false){
			current = 1;
		}
				
		this.setState({
			view: current, 
			viewMax: max
		});
	}
	
	onSubmit(values){
				
		if(!this.checkErrors()){

			this.props.startSubmission(formName);
			
			let posting = _.clone(values);
			
			posting.campaign_id = this.state.campaign;
			posting.type = this.state.type;
			posting.data = this.state.data;
			
			switch(this.state.type){
			
				case 'twitter':
				case 'facebook':
				case 'instagram':
					posting.date_start = moment(this.state.date_start).format('YYYY-MM-DD HH:mm');
					posting.data.link = this.state.link;
					break;
					
				case 'email':
					posting.date_start = moment(this.state.date_start).format('YYYY-MM-DD HH:mm');
					posting.template = this.state.template;
					break;	
					
				case 'landing':
				case 'advert':
				case 'slider':
					posting.date_start = moment(this.state.date_start).format('YYYY-MM-DD HH:mm');
					posting.date_end = moment(this.state.date_end).format('YYYY-MM-DD HH:mm');
					posting.template = this.state.template;
					
					break;	
					
				default:

					break;		
			}
						
			this.props.saveCampaignEvent(formName, this.state.id, posting, (id) => {
				this.props.history.push(this.props.parentPath);
			});		
		}
	}
	
	startConnectionTime(){
		
		this.connectionRefreshTimer = setInterval(() => {			
			if(localStorage.getItem('connection_refresh')){
				
				switch(localStorage.getItem('connection_refresh')){
					
					case 'twitter':
						this.props.showNotification(
							'success',
							i18n.t('retailers:notification_integration_twitter_connected_title'),
							i18n.t('retailers:notification_integration_twitter_connected_description')
						);
						break;
						
					default:
					
						break;
				}
				
				localStorage.removeItem('connection_refresh');
				clearInterval(this.connectionRefreshTimer);
				
				this.refreshIssues();
			}
			
		}, 2500);
	}
		
	onInputTypeChange(selected){
		
		let type = selected.value;
		
		let templates = _.filter(this.props.templates.templates, { 'type': type });
		let template = templates[Object.keys(templates)[0]];		
		
		this.setState({
			type: type, 
			template: template.id
		
		}, () => {
			this.setRequired();
		});
	}
	
	onInputTemplateChange(selected){
				
		this.setState({
			template: selected.value
		
		}, () => {
			this.setRequired();
		});
	}
	
	onInputLinkChange(selected){
		
		let issues = this.state.issues;
		
		if(issues.link){
			delete issues.link;
		}
		
		let type = selected.value;
		let type_sub = false;
		
		if(type){
			
			selected = type.split(':');
			
			if(selected.length === 2){
				type = selected[0];
				type_sub = selected[1];
			}
			
			if(this.props.account.role.toLowerCase() === 'retailer'){
			
				switch(type){
					
					case 'product':				
						let product = this.props.products.products[type_sub];
						
						if(!product.url){
							issues.link = true;
						}					
						break;
						
					case 'website':					
						if(!this.props.templates.branding.url_website){
							issues.link = true;
						}
						break;
					
					default:
	
						break;
				}
			}
		}	
			
		this.setState({
			link: {
				type: type,
				data: type_sub
			},
			issues: issues
		}, () => {
			this.setRequired();
		});
	}
	
	onInputLinkChangeCustom(e){
		
		let link = this.state.link;
		
		link.data = e.target.value;
		
		this.setState({
			link: link
		});
	}
	
	setRequired(){
		
		let required = [];
		let template = this.props.templates.templates[this.state.template];
		let settings = JSON.parse(template.settings);

		_.forEach(settings.data, (value, key) => {
			
			if(value.required){
				required.push(key);
			}			
		});
		
		this.setState({
			required: required
		
		});		
	}
	
	onInputDateChange(type, date){
		
		this.setState({
			[type]: date
		});
	}
	
	checkErrors(){
				
		let errors = [];
		
		_.forEach(this.state.required, (type, key) => {

			if(!this.state.data[type] || this.state.data[type] === ''){
				errors.push(type);
			}
		});	
								
		this.setState({
			errors: errors
		});	
				
		if(errors.length > 0){
			return true;
		}else{
			return false;
		}
	}
		
	onClickSetView(type){
		
		if(type > this.state.viewMax){
			Swal.fire({
				title: i18n.t('campaigns:event_preview_device_dialog_title'),
				text: i18n.t('campaigns:event_preview_device_dialog_description'),
				icon: 'warning',
				confirmButtonText: i18n.t('dialog_confirm'),
				focusConfirm: false
			});
		}else{
			this.setState({
				view: type
			});
		}		
	}
	
	onChangeContent(type, value){
				
		if(value.value){
			value = value.value;
		}else if(value.target){
			value = value.target.value
		}
				
		let data = this.state.data;
				
		if(this.state.errors.includes(type) && value !== ''){
			
			this.setState({
				errors: this.state.errors.filter(item => item !== type)
			});
 		}
 		
 		if(!data[type]){
	 		data[type] = [];
 		}

		data[type] = value;
											
		this.setState({
			data: data
		});
	}
	
	renderSettings(){
		
		let fields = [];
		
		switch(this.state.type){
			
			case 'twitter':
			case 'facebook':
			case 'instagram':
				fields = ['date_start','link'];
				break;
				
			case 'email':
				fields = ['date', 'subject'];
				break;	
				
			case 'landing':
				fields = ['date_start', 'date_end', 'url'];
				break;	
			
			case 'advert':
			case 'slider':
				fields = ['date_start', 'date_end'];
				break;	
				
			default:

				break;		
		}
		
		return (
			<React.Fragment>
				{this.renderOptions('type')}
				{this.renderOptions('status')}
				
				{_.map(fields, (field, key) => {
					return (
						<div key={key}>
							{this.renderOptions(field, key)}
						</div>
					);
				})}
			</React.Fragment>
		);
	}
	
	renderOptions(type, key){
		
		let campaign = this.props.campaigns.campaigns[this.state.campaign];
		let editable = false;		
		let title = '';
		let field = '';
		let content = '';
						
		switch(type){
			
			case 'status':
				
				title = i18n.t('campaigns:event_label_status');

				switch(this.state.status){
					
					case 'draft':
						content = 'Draft';
						break;
					
					case 'pending':									
						content = 'Pending';
						
						if(this.state.id && this.state.issues){
							content = 'Pending - with issues';
						}						
						break;
						
					case 'active':
						content = 'Active';
						break;
						
					case 'failed':
						content = 'Failed';
						break;
						
					case 'complete':
						content = 'Completed';
						break;	
						
					default:
					
						break;			
				}			
				break;
		
			case 'type':
				if(!this.state.id){	
					editable = true;
				}
				
				title = i18n.t('campaigns:event_label_type');
				content = i18n.t('campaigns:event_type_' + this.state.type); //this.state.type.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
				
				if(editable){	
					
					let options = [];
					
					if(_.find(campaign.distribution, {type:'website'})){
						options.push({
							label: 'Website',
							options: [
								{
									label: 'Slider',
									value: 'slider'
								},
								{
									label: 'Advert',
									value: 'advert'
								}
							]
						});
					}
					
					if(_.find(campaign.distribution, {type:'email'})){
						options.push({
							label: 'Email',
							options: [
								{
									label: 'Email Shot',
									value: 'email'
								}
							]
						});
					}
					
					if(_.find(campaign.distribution, {type:'landing'})){
						options.push({
							label: 'Landing',
							options: [
								{
									label: 'Landing Page',
									value: 'landing'
								}
							]
						});
					}
					
					if(_.find(campaign.distribution, {type:'social'})){
						options.push({
							label: 'Social',
							options: [
								{
									label: 'Facebook Post',
									value: 'facebook'
								},
								{
									label: 'Instagram Post',
									value: 'instagram'
								},
								{
									label: 'Twitter Post',
									value: 'twitter'
								}
							]
						});
					}
				
					field = (
						<Field
							key="type"
							label={title} 
							name="type"
							type="suggest"
							options={options}
							selected={this.state.type}
							onChangeFunc={this.onInputTypeChange.bind(this)}
							component={InputField}
						/>
					);
				}				
				break;
			
			case 'date':
			case 'date_start':
			case 'date_end':
			
				let min_date = campaign.date_start.date;
				let max_date = campaign.date_end.date;
								
				switch(type){
					
					case 'date':
					case 'date_start':
					
						if(this.state.status === 'draft' || this.state.status === 'pending'){
							editable = true;
						}
						break;
						
					case 'date_end':

						if((this.state.status === 'draft' || this.state.status === 'pending' || this.state.status === 'active')){
							editable = true;
							//editable = true; // We could enable this in the future?
							min_date = this.state.date_start;
						}
						break
						
					default: 
					
						break;
				}
				
				if(moment(min_date).endOf('day') < moment()){
					min_date = moment().toDate();
				}
						
				let date = '';
			
				if(type === 'date_end'){
					title = i18n.t('campaigns:event_label_date_end');	
					date = this.state.date_end;
				}else if(type === 'date_start'){
					title = i18n.t('campaigns:event_label_date_start');;
					date = this.state.date_start;
				}else{
					title = i18n.t('campaigns:event_label_date');;
					date = this.state.date_start;
					type = 'date_start';
				}
				
				content = dateString('datetime', date);
				
				if(editable){
					field = (
						<Field
							key={type}
							label={title}
							name={type}
							type="datepicker"
							selected={date}
							onChangeFunc={(date) => { this.onInputDateChange(type, date) }}
							timepicker={true}
							minDate={moment(min_date).toDate()}
							maxDate={moment(max_date).toDate()}
							component={InputField}
						/>
					);
				}
				break;
				
			case 'subject':
				
				if(this.state.status === 'draft' || this.state.status === 'pending'){
					editable = true;
				}
					
				title = i18n.t('campaigns:event_label_subject');
				content = this.state.data.subject;

				if(editable){
					field = (
						<Field
							key="subject"
							label={title}
							name="subject"
							type="text"
							value={this.state.data.subject ? this.state.data.subject : ''}
							onChangeFunc={this.onChangeContent.bind(this, 'subject')}
							component={InputField}
						/>
					);
				}
				break;
				
			case 'link':
				
				if(this.state.status === 'draft' || this.state.status === 'pending'){
					editable = true;
				}
			
				title = i18n.t('campaigns:event_label_link');
				//content = this.state.data.link.type;
				
				let options = [
					{
						label: 'None',
						value: false
					},
					{
						label: 'Custom',
						value: 'custom'
					},
					{
						label: 'Hub',
						value: 'hub'
					},
					{
						label: 'Website',
						value: 'website'
					}
				];
				
									
				if(_.find(campaign.distribution, {type:'landing'})){					
					options.push({
						label: 'Campaign landing page',
						value: 'landing'
					});
				}
				
				if(editable){
					
					let selected = false;
					let url = false;
					
					if(this.state.link && this.state.link.type){
						
						switch(this.state.link.type){
							
							case 'product':
								selected = `product:${this.state.link.data}`;
								//let product = this.props.products.products[this.state.link.data];
								break;
							
							default:
								selected = this.state.link.type;
								break;
						}
					}
					
					if(campaign.items.products.length > 0){
						
						let products = _.filter(this.props.products.products, function(product){
				            return _.includes(campaign.items.products, product.id);
				        });
				        
						options.push({
							label: 'Products',
							options: _.map(_.orderBy(products, ['title'],['asc']), (product, key2) => {
								return {
									label: 'Product: ' + product.title,
									labelDropdown: product.title,
									value: `product:${product.id}`
								}
							})
						});
					}
					
					field = (
						<React.Fragment>
							<Field
								label={title} 
								name="link"
								type="suggest"
								options={options}
								selected={selected}
								onChangeFunc={this.onInputLinkChange.bind(this)}
								component={InputField}
							/>
							
							{url && 
								<small>
									{url}
								</small>
							}
							
							{this.state.link && this.state.link.type === 'custom' &&
								<Field
									label="Custom URL"
									name="link_custom"
									type="url"
									component={InputField}
									onChangeFunc={this.onInputLinkChangeCustom.bind(this)}
								/>
							}
						</React.Fragment>
					);
				}else{
					
					let selected = _.find(options, { value: this.state.data.link && this.state.data.link.type ? this.state.data.link.type : false });
					
					switch(this.state.link.type){
						
						case 'custom':
							content = `Custom: ${this.state.link.data}`;
							break;
							
						case 'product':
							let product = this.props.products.products[this.state.link.data];
							content = `Product: ${product.title}`;
							break;
							
						default:
							content = selected.label;
							break;
					}		
				}
				break;
				
			case 'url':
				
				if(this.state.status === 'draft' || this.state.status === 'pending'){
					editable = true;
				}

				title = i18n.t('campaigns:event_label_url');
				
				if(editable){
					field = (
						<Field
							key="url"
							label={title}
							name="url"
							type="text"
							onChangeFunc={this.onChangeContent.bind(this, 'url')}
							component={InputField}
						/>
					);
				}else{
					content = this.state.url;
				}
				break;
				
			case 'template':
			
				let templates = _.mapKeys(_.filter(this.props.templates.templates, { 'type': this.state.type }), 'id');
								
				if(_.size(templates) > 1){
					
					if(this.state.status === 'draft' || this.state.status === 'pending'){
						editable = true;
					}
											
					title = i18n.t('campaigns:event_label_template');
					content = this.state.template ? templates[this.state.template].name : 'Unknown template';
	
					if(editable){
						field = (
							<Field
								label={title} 
								name="template"
								type="suggest"
								options={_.map(templates, (template, key) => {
									return {
										label: template.name,
										value: template.id
									}
								})}
								selected={this.state.template}
								onChangeFunc={this.onInputTemplateChange.bind(this)}
								component={InputField}
							/>
						);
					}
				}
				
				break;
				
			case 'products': 
			
				field = (
					<div>
						Dean needs to add this functionality!
					</div>
				) 
			
				break;
				
			default: 

				break;
				
		}
		
		if(editable){
			return field;
		}else{
			
			return (
				<InputField
					label={title}
					type="echo"
					value={content}
				/>
			)
		}		
	}

	renderIssues(){
				
		if(this.state.id && this.state.status === 'pending' && this.state.issues && Object.keys(this.state.issues).length > 0){
			
			let campaign = this.props.campaigns.campaigns[this.state.campaign];
			let event = _.find(this.props.campaigns.campaigns[this.state.campaign].events, { id: parseFloat(this.state.id) });
			let button = false;
			
			if(event){			
			
				return(
					<div className="c-card">
						<h4>
							{i18n.t(`campaigns:event_issues_title`, { count: Object.keys(this.state.issues).length })}
						</h4>
						<p className="u-mb-medium">
							{i18n.t(`campaigns:event_issues_description`, { count: Object.keys(this.state.issues).length })}
						</p>
						
						{_.map(this.state.issues, (issue, key) => {
							
							switch(key){
										
								case 'link':
									let reason = 'The selected link is invalid';
									let action = '/';
									let cta = 'Fix';						
									let fallback = 'your hub';
									
									if(_.find(campaign.distribution, {type:'landing'})){
										fallback = 'the landing page for this campaign';
									}else if(!this.props.templates.branding.url_website){
										fallback = 'your hub';
									}
								
									switch(this.state.link.type){
							
										case 'product':
											let product = this.props.products.products[this.state.link.data];
											reason = `There is currently no URL provided for the product '${product.title}'`;
											action = `/products/product/${product.id}`;
											cta = 'Update Product';
											break;
											
										case 'website':
											reason = `You currently don't have a website added to your profile`;
											action = '/settings/branding';
											cta = 'Update Profile';
											break;
										
										default:
	
											break;
										
									}
								
									return (
										<Link 
											key={key} 
											className="c-card clickable"
											to={action}
										>
											<h5>Invalid Link</h5>
										<p className="u-mb-medium">{reason}, so the link will automatically fall back and to {fallback}.</p>
											<button type="button" className="c-btn c-btn--info">
												{cta}
											</button>
										</Link>
									);
									break;
									
								case 'image':
									return (
										<Link 
											key={key} 
											className="c-card clickable"
											to={`/campaigns/${this.state.campaign}/assets`}
										>
											<h5>No Image</h5>
											<p className="u-mb-medium">An image must be selected for this event.</p>
											<button type="button" className="c-btn c-btn--info">
												Campaign Assets
											</button>
										</Link>
									)
									break;
									
								default:
									
									switch(event.type){
										
										case 'facebook':
											if(key === 'disconnected' && this.props.connections.settings && this.props.connections.settings.facebook){
												
												return (
													<FacebookLogin
													    key={key}
													    appId={this.props.connections.settings.facebook.app_id}
													    scope={this.props.connections.settings.facebook.permissions.join(',')}
													    fields="name,email,picture"
													    callback={(response) => { 
														  	
														  	this.props.saveRetailerIntegration(formName, 'facebook', response, () => {
																								
																this.props.showNotification(
																	'success',
																	i18n.t('retailers:notification_integration_facebook_connected_title'),
																	i18n.t('retailers:notification_integration_facebook_connected_description')
																);
																
																this.refreshIssues();
														  	});
													    }}
													    render={renderProps => (
														    <div onClick={renderProps.onClick} className="c-card clickable">
																
																<h5>Disconnected</h5>
																<p className="u-mb-medium">Your Facebook account is not connected, so the message cannot be posted.</p>
																
																<button type="button" className="c-btn c-btn--info">
																	{i18n.t('retailers:integration_social_facebook_disconnected_cta')}
																</button>
															</div>
														)}
													/>
												);
											}
											break;
											
										case 'instagram':
											if(key === 'disconnected' && this.props.connections.settings && this.props.connections.settings.instagram){
												
												return (
													<FacebookLogin
													    key={key}
													    appId={this.props.connections.settings.instagram.app_id}
													    scope={this.props.connections.settings.instagram.permissions.join(',')}
													    fields="name,email,picture"
													    callback={(response) => { 
														  	
														  	this.props.saveRetailerIntegration(formName, 'instagram', response, () => {
																								
																this.props.showNotification(
																	'success',
																	i18n.t('retailers:notification_integration_instagram_connected_title'),
																	i18n.t('retailers:notification_integration_instagram_connected_description')
																);
																
																this.refreshIssues();
														  	});
													    }}
													    render={renderProps => (
														    <div onClick={renderProps.onClick} className="c-card clickable">
																
																<h5>Disconnected</h5>
																<p className="u-mb-medium">Your Instagram account is not connected, so the message cannot be posted.</p>
																
																<button type="button" className="c-btn c-btn--info">
																	{i18n.t('retailers:integration_social_instagram_disconnected_cta')}
																</button>
															</div>
														)}
													/>
												);
											}
											break;
											
										case 'twitter':
											if(key === 'disconnected'){
												
												return (
													<div
														key={key}
														className="c-card clickable"
														onClick={() => { 
															
															this.startConnectionTime();
																
															this.props.connectionGetUrl('twitter', {}, (response) => {
																window.open(response,'twitter','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600,height=400');
															});
														}}
													>
														<h5>Disconnected</h5>
														<p className="u-mb-medium">Your Twitter account is not connected, so the tweet cannot be posted.</p>
														
														<button type="button" className="c-btn c-btn--info ">
															{i18n.t('retailers:integration_social_twitter_disconnected_cta')}
														</button>
													</div>
												);
											}
											break;
											
										case 'email':
																			
											let title = '';
											let message = '';
											
											if(key === 'group_missing'){
												title = 'No subscriber group';
												message = 'There are currently no subscriber groups selected for this campaign, so the email cannot be sent.';
											}else if(key === 'group_invalid'){
												title = 'Invalid subscriber group';
												message = 'The previously selected subscriber group is no longer available, so the email cannot be sent.';
											}
											
											let groups = {};
							
											_.map(this.props.subscribers.groups, (group, key) => {
												return _.assign(groups, _.mapKeys(_.map(group, o => _.extend({source: key}, o)), 'id'));
											});
						
											let subGroups = _.map(groups, (group, key) => {
										
												let icon = 'fal fa-at';	
												let count = 0;
												let prefix = 'local';
						
												if(group.source){
													
													switch(group.source){
														
														case 'mailchimp':
															prefix = group.source;
															icon = 'fab fa-mailchimp';
															break;
															
														default: 
														
															break;
													}
												}
												
												if(group.subscribers){
																								
													if(Array.isArray(group.subscribers)){
														count = group.subscribers.length;
													}else{
														count = group.subscribers;
													}
												}
																													
												return ({
													name: group.name,
													label: `${group.name} (${count})`,
													value: `${prefix}|${group.id}`,
													icon: icon,
													count: count
												});
											});
										
											let total = 0;
										
											{_.forEach(this.state.sub_list, (group, key) => {
									
												if(group.count){
													total = total+group.count;
												}else{
													let find = _.find(subGroups, {value:group});
													
													if(find){
														total = total+find.count;
													}
												}
											})}
												
											return (
												<div
													key={key}
													className="c-card"
												>
													<h5>{title}</h5>
													<p className="u-mb-medium">{message}</p>
													
													<Field
														label={i18n.t(`campaigns:settings_distribution_email_lists_label`)}
														labelRight={i18n.t(`campaigns:settings_distribution_email_lists_total`, { count: total })}
														name="subscribers"
														type="suggest"
														className={this.state.sub_list_error ? 'c-input--danger' : ''}
														options={subGroups}
														selected={this.state.sub_list}
														multiple={true}
														onChangeFunc={this.onInputSubscribersChange.bind(this)}
														component={InputField}
													/>
													
													<button 
														type="button" 
														className="c-btn c-btn--info" 
														onClick={() => { 
															
															if(this.state.sub_list.length > 0){
																
																this.setState({
																	sub_list_error: false
																});
																
																let groups = {};
				  			  	
															  	_.forEach(this.state.sub_list, (item, key) => {
																			
																	let tmp = item;
																	
																	if(item.value){
																		tmp = item.value;
																	}
																	
																	tmp = tmp.split('|');;
																	
																	if(!groups[tmp[0]]){
																		groups[tmp[0]] = [];
																	}
																	
																	groups[tmp[0]].push(tmp[1]);				
																});
															  	
														  		this.props.saveCampaignGeneral(formName, this.state.campaign, { distribution_email: 1, subscribers: groups, ignore_warnings: true });
																
															}else{
																this.setState({
																	sub_list_error: true
																});
															}
														}}
													>
														Save
													</button>
												</div>
											);
											break;
											
										default:
										
											break;	       		
									
									}
								}
							}
						)}
					</div>
				);
			}
		}
								
		return;						
	}
	
	renderPage(){
		
		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.campaign] || (!this.state.type && this.state.id)){

			return (
				<Loading />
			);
		}
	
		// FURTHER CHECK TEMPLATE IS HERE
		if(!this.props.templates.templates || !this.props.templates.branding || !this.state.template || !this.props.templates.templates[this.state.template]){
			return (
				<Loading />
			);			
		}
		
		let template = this.props.templates.templates[this.state.template];

		return (
			<React.Fragment>
				<div className="row">
					<div className="col-md-3 col-sm-12">						
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('campaigns:event_title_details')}</h4>
							{this.renderSettings()}
						</div>
						
						{this.renderIssues()}
					</div>
					<div className="col-md-9 col-sm-12">
				
						<div className="c-card preview" style={{ overflowX: 'visible' }}>
							
							<h4 className="u-mb-medium">								
								{this.state.mode === 'edit' && 
									i18n.t(`campaigns:event_title_edit_${this.state.type}`)
								}
								
								{this.state.mode === 'view' && 
									i18n.t(`campaigns:event_title_preview_${this.state.type}`)
								}
								
								<div className="options">
									
									{this.state.status !== 'failed' && this.state.status !== 'complete' && 
										<i className={`preview_device fal fa-edit ${!this.state.view ? 'selected' : ''}`} onClick={() => { this.onClickSetView(false); }} data-tip={i18n.t('campaigns:event_preview_device_tooltip_none')}></i>
									}
									
									<i className={`preview_device fal fa-laptop ${this.state.view === 3 ? 'selected' : ''} ${this.state.viewMax < 3 ? 'inactive' : ''}`} onClick={() => { this.onClickSetView(3); }} data-tip={i18n.t('campaigns:event_preview_device_tooltip_desktop')}></i>
									<i className={`preview_device fal fa-tablet ${this.state.view === 2 ? 'selected' : ''} ${this.state.viewMax < 2 ? 'inactive' : ''}`} onClick={() => { this.onClickSetView(2); }} data-tip={i18n.t('campaigns:event_preview_device_tooltip_tablet')}></i>
									<i className={`preview_device fal fa-mobile ${this.state.view === 1 ? 'selected' : ''}`} onClick={() => { this.onClickSetView(1); }} data-tip={i18n.t('campaigns:event_preview_device_tooltip_mobile')}></i>
								</div>
							</h4>
							
							<TemplatePreview 
								template={template}
								data={this.state.data}
								items={this.state.items}
								branding={this.props.templates.branding}
								mode={this.state.mode}
								onEdit={this.onChangeContent.bind(this)}
								errors={this.state.errors}
								view={this.state.view}
							/>
						</div>	
					</div>
				</div>
							
				<ReactTooltip 
					effect="solid"
				/>
			</React.Fragment>
		)
	}
	
	render() {
		
		let title = i18n.t('campaigns:event_title_add');
		
		if(this.state.id){
			title = i18n.t(`campaigns:event_title_edit`);
		}
				
		let buttons = [];
		const { handleSubmit } = this.props;
				
		if(this.state.status === 'pending'){
			
			buttons.push({
				onClick: () => {
						
					Swal.fire({
						title: i18n.t('campaigns:event_delete_dialog_title'),
						text: i18n.t('campaigns:event_delete_dialog_description'),
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: i18n.t('dialog_confirm'),
						cancelButtonText: i18n.t('dialog_cancel'),
						focusConfirm: false
					}).then((result) => {
	
						if(result.value) {
							this.props.deleteCampaignEvent(this.state.id, () => {
								this.props.fetchCampaignsList(this.state.campaign, () => {
									this.props.history.push(this.props.parentPath);
								});
							});
						}
					});
				},
				outline: true,
				className: "c-btn--danger",
				icon: 'fal fa-trash',
				tooltip: i18n.t('campaigns:event_delete_tooltip')
			});
		}
		
		if(this.state.status !== 'failed' && this.state.status !== 'complete'){
			
			buttons.push({
				label: i18n.t(`campaigns:event_submit`),
				submit: formName,
				onClick: () => {
					this.checkErrors();
				}
			});
			
		}
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
			
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<Toolbar
					tabs={[{
						label: i18n.t('campaigns:event_toolbar_settings'),
						url: `${this.props.parentPath}/event/${this.state.campaign}/${this.state.id}`,
						selected: true
					}]}
					tabsSteps={true}
					buttons={buttons}
				/>	

				{this.renderPage()}
			</form>	
		);
	}
}

const validate = (values) => {	
	
	const errors = {};
	
	if (!values.type) {
		errors.type = 'Choose type of event!';
	}
	
	if (!values.template) {
		errors.template = 'Choose a template for this event!';
	}
	
	/*if (!values.date_start) {
		errors.date_start = 'Choose a start date!';
	}
	
	if (!values.date_end) {
		errors.date_end = 'Choose an end date!';
	}*/
	
	if (!values.subject) {
		errors.subject = 'Enter a subject!';
	}
	
	if (!values.url) {
		errors.url = 'Enter a url!';
	}
	
	if (!values.link) {
		errors.link = 'Select a link option!';
	}
	
	if (!values.link_custom) {
		errors.link_custom = 'Enter a url!';
	}
	
	return errors;
}

function mapStateToProps({ campaigns, assets, templates, account, brand, articles, products, connections, subscribers }, ownProps){
	return {
		campaigns,
		assets,
		templates,
		account,
		brand, 
		articles, 
		products,
		connections,
		subscribers
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		fetchCampaignsList,
		fetchAssetsList,
		fetchArticlesList,
		fetchProductList,
		fetchTemplates,
		fetchBranding,
		startSubmission, 
		stopSubmission,
		saveCampaignEvent,
		connectionSettings,
		saveRetailerIntegration,
		connectionGetUrl,
		showNotification,
		fetchSubscribersGroups,
		saveCampaignGeneral,
		deleteCampaignEvent
	} )(CampaignsScheduleEvent)
);