import { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import { showNotification } from '../../actions/notifications';
import { errorsResetErrors } from '../../actions/errors';

class ErrorAlert extends Component {
	
	componentWillMount() {
		this.props.errorsResetErrors();
	}
	
	componentWillUnmount() {
		this.props.errorsResetErrors();
	}
	
	render(){
		
		if(!this.props.errors.message){
			return false;
		}
		
		this.props.showNotification(
			'error',
			i18n.t(`errors:title`),
			i18n.t(`errors:${this.props.errors.message}`)
		);

		/*return (
			<div className="c-alert c-alert--danger alert u-mb-medium">
				<span className="c-alert__icon">
					<i className="feather icon-slash"></i>
				</span>
				
				<div className="c-alert__content">
					<h4 className="c-alert__title">{i18n.t(`errors:${this.props.errors.message}`)}</h4>
				</div>
			</div>
		);*/
		
		return false;
	}
}

function mapStateToProps({ errors }, ownProps) {
	return { 
		errors
	 };
}

export default connect(mapStateToProps, { 
	showNotification,
	errorsResetErrors
})(ErrorAlert);