import { notify } from 'reapop';
import {
	isMobile
} from "react-device-detect";
import { imageResize } from '../helpers/s3';

export function showNotification(status, title, message, image, buttons, dismissAfter) {
		
	let notificationPosition = 'br';
	let closeButton = false;
	
	if(isMobile){
		notificationPosition = 'b';
	}
		
	if(dismissAfter || dismissAfter === 0){

	}else{
		dismissAfter = 5000;
	}
		
	if(!buttons){
		buttons = [];
		closeButton = true;
	}
	
	if(!image || image === '' || image === null){
		image = '';
	}else{
		image = imageResize(image, 100, 100);
	}
				
	return async (dispatch) => {
		
		dispatch(
			notify({
				status: status,
				title: title,
				message: message, 
				position: notificationPosition,
				dismissAfter: dismissAfter,
				allowHTML: true,
				buttons: buttons,
				dismissible: true,
				closeButton: closeButton,
				image: image
			})
		);
	}
}