import React, { Component } from 'react';
import i18n from '../../helpers/i18n'; 
import { connect } from 'react-redux';
import _ from 'lodash';

class Languages extends Component {
	
	changeLanguage(lng){		
	    i18n.changeLanguage(lng, () => {
			this.forceUpdate(() => {
				console.log('app forceUpdate', i18n.language);
			});
			
			this.props.forceUpate();
	    });
	}
	
	render() {
		
		if(this.props.brand.details && this.props.brand.details.languages.length > 1){
			
			let languages = this.props.brand.details.languages;
			
			if(this.props.ignore){
				languages = languages.filter(item => item !== i18n.language);
			}
			
			return (
				<ul className="languages">
					{_.map(languages, (language, key) => {
											
						return (
							<li key={key} className={i18n.language === language ? 'selected' : ''}>
								<img src={`/images/flags/${language}.svg`} onClick={() => this.changeLanguage(language)} alt={language} />
							</li>
						);
					})}
				</ul>
			);
		}
		
		return '';
	}
}

function mapStateToProps({ users, brand }, ownProps) {
	return { 
		users,
		brand
	};
}

export default connect(mapStateToProps)(Languages);
