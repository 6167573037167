import { trackingConstants } from '../constants/tracking';

const defaultState = {};

export default function (state = defaultState, action) {

	switch (action.type) {

		case trackingConstants.OPEM:

			return {
				...state,
				opener: action.payload
			}
			
		case trackingConstants.PWA:

			return {
				...state,
				pwa: true
			}
			
		default:
			return state;
	}
}