import React from 'react';
import ReactTooltip from 'react-tooltip';
import Button from './button';

class Info extends React.Component {

    render() {
	    
	    let icon = 'fal fa-question-circle';
	    
	    if(this.props.icon){
		    icon = this.props.icon;
	    }
	    
	    let button = '';
	    
	    if(this.props.cta){
		    
		    button = (
			    <Button
					{...this.props.cta}
				/>
		    );
	    }
		
		let tip = (
			<div>
				{this.props.content}
				{button}
			</div>
		);
		
		return (
			<div className="tooltip">
				<i className={`info ${icon}`} data-tip data-for={this.props.content}></i>
				<ReactTooltip 
					id={this.props.content}
					effect="solid"
					className={this.props.cta ? 'react-tooltip-clickable-link' : ''}
					delayHide={this.props.cta ? 200 : 0}
					delayShow={100}
				>
					{tip}
				</ReactTooltip>
			</div>
		);
	}
}

export default Info;