import { systemConstants } from '../constants/system';

export default function(state = {}, action) {
	
	switch (action.type) {
		      
		case systemConstants.TIME:
			
			let set = action.payload.data;
			set.local = new Date();
			
			return { 
				...state, 
				time: set
			}
			
		default:
			return state;
	}
}