import { axiosCreate } from '../helpers/axios';
import { systemConstants } from '../constants/system';
import { errorsConstants } from '../constants/errors';

export function ping(callback) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/system/ping');
										
			dispatch({ 
				type: systemConstants.PING,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function time(callback) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/system/time');
										
			dispatch({ 
				type: systemConstants.TIME,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}