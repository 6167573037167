import React, { Component } from 'react';
import { connect } from 'react-redux';
import { connectionSettings } from '../../../actions/connections';
import i18n from '../../../helpers/i18n'; 
import _ from 'lodash';
import CopyCode from '../../chrome/copy_code';
import { showNotification } from '../../../actions/notifications';

class IntegrationsSes extends Component {
	
	constructor(props){
		super(props);
       	this.props.connectionSettings(['ses']);
    }
    
    render() {		
		
		let email = false;
		
		if(this.props.email){
			email = this.props.email;
		}else if(this.props.retailer.details.email_local_email){
			email = this.props.retailer.details.email_local_email;
		}
		
		if(!email){
			
			return (
				<React.Fragment>	
					No custom sending email set!
				</React.Fragment>	
			);
		
		}else if(this.props.connections.ses){
		
			let ip = '';
			
			if(this.props.connections.settings && this.props.connections.settings.ses && this.props.connections.settings.ses.ip){
				ip = this.props.connections.settings.ses.ip;
			}
			
			let domain = email.split('@')[1];
						
			return (
				<React.Fragment>
				
					{!this.props.connections.ses.domain.connected && 
						
						<React.Fragment>
							
							{!this.props.developer && 
								<React.Fragment>
									<h4 className="c-alert__title">{i18n.t('retailers:integration_email_local_settings_disconnected_title')}</h4>
									<p className="u-mb-small">{i18n.t('retailers:integration_email_local_settings_disconnected_description')}</p>
								</React.Fragment>
							}
							
							<ol className="numbererd">
								<li>Log in to the account of the domain provider and find the DNS settings for {domain}. Look for your domain management area, DNS configuration, or similar.</li>
								
								{(this.props.developer || !this.props.connections.ses.domain.dns.txt.status) && 
									<li>
										Add the following TXT record:
										
										<div className="u-mt-small">
											Name: <CopyCode
												className="inline"
												value={this.props.connections.ses.domain.dns.txt.name}
												onCopy={() => {
													this.props.showNotification(
														'success',
														i18n.t('retailers:notification_integration_dns_type_copied_title'),
														i18n.t('retailers:notification_integration_dns_type_copied_description', { type: 'TXT' })
													);	
												}}
											/>
										</div>
										
										<div className="u-mt-xsmall u-mb-small">
											Value: <CopyCode
												className="inline"
												value={this.props.connections.ses.domain.dns.txt.value}
												onCopy={() => {
													this.props.showNotification(
														'success',
														i18n.t('retailers:notification_integration_dns_value_copied_title'),
														i18n.t('retailers:notification_integration_dns_value_copied_description', { type: 'TXT' })
													);	
												}}
											/>
										</div>
									</li>
								}
								
								{_.map(this.props.connections.ses.domain.dns.dkim, (dkim, key) => {
									
									if((this.props.developer || !dkim.status)){
										
										return (
											<li key={key}>
												Add the following DKIM record:
												
												<div className="u-mt-small">
													Name: <CopyCode
														className="inline"
														value={dkim.name}
														onCopy={() => {
															this.props.showNotification(
																'success',
																i18n.t('retailers:notification_integration_dns_type_copied_title'),
																i18n.t('retailers:notification_integration_dns_type_copied_description', { type: 'DKIM' })
															);	
														}}
													/>
												</div>
												
												<div className="u-mt-xsmall u-mb-small">
													Value: <CopyCode
														className="inline"
														value={dkim.value}
														onCopy={() => {
															this.props.showNotification(
																'success',
																i18n.t('retailers:notification_integration_dns_value_copied_title'),
																i18n.t('retailers:notification_integration_dns_value_copied_description', { type: 'DKIM' })
															);	
														}}
													/>
												</div>
											</li>
										);
									}
									
								})}
								
								<li>Save the changes.</li>
							</ol>
						</React.Fragment>
					}
					
					{(!this.props.developer && !this.props.connections.ses.email.verified) && 
						<React.Fragment>
							<h4 className={`c-alert__title ${this.props.connections.ses.domain.connected ? '' : 'u-mt-medium'}`}>{i18n.t('retailers:integration_email_local_settings_unverified_title')}</h4>
							<p className="u-mb-small">{i18n.t('retailers:integration_email_local_settings_unverified_description', { email: email })}</p>
						</React.Fragment>
					}								
					
				</React.Fragment>
			);
		}else{
			return null;
		}
	}
}

function mapStateToProps({ connections }, ownProps){
	return {
		connections
	};
}

export default connect(mapStateToProps, { 
	connectionSettings,
	showNotification
})(IntegrationsSes);