import React, { Component } from 'react';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import Loading from '../../../components/chrome/loading';

import IntegrationsConversion from '../../../components/retailers/integrations/conversion';

import EmptySection from '../../../components/chrome/empty';

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { fetchRetailersList } from '../../../actions/retailers';
import { showNotification } from '../../../actions/notifications';
import { checkWebsite } from '../../../actions/connections';
import SendDeveloper from '../../../components/retailers/send_developer';

class RetailersIntegrationsConversion extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}else{
				this.updateDomElements();
			}
       	});
       	
       	let checkWebsite = false; // DISABLED CHECKING DUE TO UNRELIABLE AS PORT BLOCKS 
       	
       	if(checkWebsite && typeof this.props.connections.website === 'undefined'){
	       	this.props.checkWebsite();
       	}  
       	
       	this.state = {
	       	id: id,
	       	checkWebsite: checkWebsite
       	}
	}
	
	updateDomElements(){
		
		if(this.props.retailers.retailers && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];
							
			let hub = false;
			
			if(retailer.domains){
				
				hub = retailer.domains.hub;
			
				if(retailer.domains.custom){
				
					if(retailer.domains.custom.connected){
						hub = retailer.domains.custom.domain;
					}
				}
			}
			
			this.setState({
				url: hub,
				empty: (retailer.details.slider_empty_disable && retailer.details.slider_empty_disable === '1') ? true : false
			});
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
		
	render() {
			
		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}else if(this.state.checkWebsite && (typeof this.props.connections.website === 'undefined' || this.props.connections.website === 'checking')){
			return (
				<Loading />
			);
		}
		
		let retailer = this.props.retailers.retailers[this.state.id];
				
		return (

			<React.Fragment>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_conversion_title')}
					</h2>
				</header>
									
				<div className="row">
				
					<div className="col-xl-6">
						<div className="c-card">
							<h4>{i18n.t('retailers:integration_conversion_settings_title')}</h4>
							<p className="u-mb-medium">{i18n.t('retailers:integration_conversion_settings_intro', { website: retailer.details.url_website ? retailer.details.url_website : 'none currently set' })}</p>
						</div>
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
										
							{this.state.checkWebsite && 
								
								<React.Fragment>
									{!retailer.details.url_website && 
										<EmptySection
											icon="fa-exclamation-circle"
											title={i18n.t('retailers:notification_integration_conversion_nowebsite_title')}
											description={i18n.t('retailers:notification_integration_conversion_nowebsite_description')}
											cta={{
												label: i18n.t('retailers:action_add_website'),
												url: '/settings/branding'
											}}
										/>								
									}
									
									{retailer.details.url_website && !this.props.connections.website && 
										<EmptySection
											icon="fa-exclamation-circle"
											title={i18n.t('retailers:notification_integration_conversion_disconnected_title')}
											description={i18n.t('retailers:notification_integration_conversion_disconnected_description')}
											cta={{
												label: i18n.t('retailers:action_add_code'),
												url: '/settings/integrations/code'
											}}
										/>
									}
								</React.Fragment>
							}
							
							{retailer.details.url_website && (!this.state.checkWebsite || this.props.connections.website) && 
								<React.Fragment>
									<h4 className="u-mb-medium">{i18n.t('retailers:integration_conversion_instructions_title')}</h4>
								
									<IntegrationsConversion
										retailer={retailers.retailers[this.state.id]}
										code_url="/settings/integrations/code"
									/>
									
									<br/>
									<SendDeveloper />
								</React.Fragment>
							}
						</div>
						
					</div>					
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, retailers, connections }, ownProps){
	return {
		account,
		retailers,
		connections
	};
}

export default connect(mapStateToProps, { 
	showNotification,
	fetchRetailersList,
	checkWebsite
})(RetailersIntegrationsConversion);