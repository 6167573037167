import { templatesConstants } from '../constants/templates';
import _ from 'lodash';

export default function(state = {}, action) {

	switch (action.type) {
		      
		case templatesConstants.TEMPLATE_LIST:

			return { 
				...state, 
				templates: { ...state.templates, ..._.mapKeys(action.payload.data, 'id') }
			}
			
		case templatesConstants.TEMPLATE_BRANDING:

			return { 
				...state, 
				branding: action.payload.data
			}
			
		default:
			return state;
	}
}