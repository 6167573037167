import { templatesConstants } from '../constants/templates';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function fetchTemplates(type, callback) {

    return async (dispatch) => {
	    
	    let url = '/templates/list';
	    
	    if(type){
		    url += `?type=${type}`;
	    }
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: templatesConstants.TEMPLATE_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
				
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchBranding(callback) {

    return async (dispatch) => {

		try {
			const request = await axiosCreate().get('/templates/branding');
										
			dispatch({ 
				type: templatesConstants.TEMPLATE_BRANDING,
				payload: request
			});
			
			if(callback){
				callback();
			}
				
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}