import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import CopyCode from '../../chrome/copy_code';
import { showNotification } from '../../../actions/notifications';

class IntegrationsCode extends Component {

	render() {		
		
		let hub = this.props.retailer.domains.hub;
		
		if(this.props.retailer.domains.custom){
		
			if(this.props.retailer.domains.custom.connected){
				hub = this.props.retailer.domains.custom.domain;
			}
		}		
				
		return (
			<ol className="numbererd">
				<li>
					Copy the unique website code below:
					
					<CopyCode
						className="u-mt-small u-mb-small"
						value={`<script src="https://${hub}/cch.js" data-key="${this.props.retailer.details.key_javascript}"></script>`}
						onCopy={() => {
							this.props.showNotification(
								'success',
								i18n.t('retailers:notification_integration_code_copied_title'),
								i18n.t('retailers:notification_integration_code_copied_description')
							);	
						}}
					/>
					
				</li>
				<li>Paste the code before the closing &lt;/head&gt; tag on all pages of the website.</li>
			</ol>
		);
	}
}

function mapStateToProps({ }, ownProps){
	return {

	};
}

export default connect(mapStateToProps, { 
	showNotification,
})(IntegrationsCode);