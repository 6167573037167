import { libraryConstants } from '../constants/library';
import _ from 'lodash';

export default function(state = { selected: [] }, action) {

	switch (action.type) {
		      
		case libraryConstants.LIBRARY_SELECTION:

			let items = state.selected;
						
			_.forEach(action.payload.assets, (asset, key) => {					
												
				// DOES THIS EXIST?
				let existing = _.findIndex(items, { 'type': action.payload.type, 'id': asset.id });
								
				switch(action.payload.action){
					
					case 'add':
						if(existing === -1){
							items.push({
								type: action.payload.type, 
								headline: asset.headline ? asset.headline : 0, 
								id: asset.id
							});
						}else{
							items[existing].headline = asset.headline ? asset.headline : 0;
						}
						break;
						
					case 'delete':
						if(existing >= 0){
							items.splice(existing, 1);
						}
						break;
						
					default:
					
						break;
				}
			});
					
			return { 
				...state, 
				selected: items
			}	
			
		case libraryConstants.LIBRARY_SELECTION_CLEAR:
			
			return { 
				...state, 
				selected: []
			}

		default:
			return state;
	}
}