import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import moment from 'moment';
import SubscribersUserToolbar from './toolbar_user';

import { checkPermission } from '../../helpers/permissions';
import { fetchSubscribersUsers, fetchSubscribersGroups, saveSubscriber } from '../../actions/subscribers';

var formName = 'formSubscribersUserEdit';

class SubscribersUser extends Component {

	constructor(props){
		super(props);
		
		this.props.stopSubmission(formName);	
		
		let { id, user } = this.props.match.params;
		
		if(!checkPermission(this.props.account.permissions, 'SUBSCRIBERS')){
			this.props.history.push('/');
		}

		// MUST HAVE CORRECT PERMISSIONS!
		if(!id){
			this.props.history.push('/');
		}
		
		this.props.fetchSubscribersGroups(id, () => {
	       	this.updateDomElements();
       	});
       	
		this.props.fetchSubscribersUsers(id, () => {
	       	this.updateDomElements();
       	});

		this.props.fetchSubscribersUsers(id);
					       	       			
		this.state = {
			id: id,
			user: user
		}
		
		// DO THIS SO THE VALIDATION IS RE-RENDERED AS SOMETIMES FAILS IN OVERLAY!
	    this.props.change('force_validation', 'rerender now!');	
		
		this.updateDomElements();		
	}
	
	onInputGroupChange(selected){
				
		this.setState({
			group: selected
		});
	}
	
	onInputGenderChange(selected){
		
		let value = 'null';
				
		if(selected){
			value = selected.value;
		}

		this.setState({
			gender: value
		});
	}
	
	updateDomElements(){
		
		if(this.props.subscribers.groups.local && this.props.subscribers.groups.local[this.state.id]){
			
			let groups = [];
			let gender = '';
			let init = {}
			let dob = '';
			
			if(this.props.subscribers.users && this.state.user && this.props.subscribers.users[this.state.user]){
		
				let user = this.props.subscribers.users[this.state.user];
							
				_.forEach(this.props.subscribers.groups.local, (group, key) => {
						
					if(group.subscribers && group.subscribers.includes(user.id)){
						groups.push(group.id);
					}
				}, this);
				
				if(user.dob){
					dob = moment(user.dob.date).format('DD/MM/YYYY');
				}
				
				init = {
					forename: user.forename,
					surname: user.surname,
					email: user.email,
					dob: dob			
				};	
				
				gender = user.gender;
				
			}else{
				groups.push(parseFloat(this.state.id));
			}
						
			/*_.forEach(groups, (group, key) => {	
										
				groups[key] = {
					label: this.props.subscribers.groups.local[group].name,
					value: group
				}
			});*/
					
			this.setState({
				group: groups,
				gender: gender
			});
			
			init.group = groups;
			
			this.props.initialize(init);
		}
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
			
		let posting = _.clone(values);
		
		posting.group = _.map(this.state.group, (group, key) => {					
			  	
		  	if(group.value){
			  	return group.value;
		  	}else{
			  	return group;
		  	}
		  	
		}, this);
				
		if(posting.dob){
			posting.dob = moment(posting.dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
		}else{
			posting.dob = 'null';
		}
		
		if(this.state.gender){
			
			if(this.state.gender.value){
				posting.gender = this.state.gender.value;
			}else{
				posting.gender = this.state.gender;
			}
		}else{
			posting.gender = 'null';
		}
			  	
	  	this.props.saveSubscriber(formName, this.state.user, posting, () => {
			this.props.history.push(`/subscribers/audience/${this.state.id}/users`);
	  	});
	}
	
	renderPage(){
		
		let { subscribers } = this.props;

		if(!subscribers.groups.local || !subscribers.groups.local[this.state.id] || !subscribers.users || (this.state.user && !subscribers.users[this.state.user])){
			
			return (
				<Loading />
			);
		}	
		
		return (
			
					
			<div className="c-card">
				<h4 className="u-mb-medium">{i18n.t('subscribers:user_title_general')} </h4>
				
				<div className="row">
					<div className="col-xl-6 col-md-12">
						<Field
							label={i18n.t('subscribers:user_label_forename')} 
							name="forename"
							type="text"
							component={InputField}
						/>
					</div>
					<div className="col-xl-6 col-md-12">
						<Field
							label={i18n.t('subscribers:user_label_surname')} 
							name="surname"
							type="text"
							component={InputField}
						/>
					</div>
				</div>
												
				<div className="row">
					<div className="col-xl-6 col-md-12">
						<Field
							label={i18n.t('subscribers:user_label_email')} 
							name="email"
							type="email"
							component={InputField}
						/>
					</div>

					<div className="col-xl-6 col-md-12">
						<Field
							label={i18n.t('subscribers:user_label_dob')} 
							name="dob"
							type="date"
							component={InputField}
						/>
					</div>
				</div>	
				
				<div className="row">
					<div className="col-xl-6 col-md-12">
						<Field
							label={i18n.t('subscribers:user_label_gender')} 
							name="gender"
							type="suggest"
							isClearable={true}
							options={[
							{
								label: 'Male',
								value: 'male'	
							},
							{
								label: 'Female',
								value: 'female'	
							},
							{
								label: 'Other',
								value: 'other'	
							}
							]}
							selected={this.state.gender}
							onChangeFunc={this.onInputGenderChange.bind(this)}
							component={InputField}
						/>
					</div>
					<div className="col-xl-6 col-md-12">
						<Field
							label={i18n.t('subscribers:user_label_group', { count: this.state.group ? this.state.group.length : 1 })} 
							name="group"
							type="suggest"
							multiple={true}
							options={_.map(this.props.subscribers.groups.local, (group, key) => {
																				
								return ({
									label: group.name,
									value: group.id							
								});
							})}
							selected={this.state.group}
							onChangeFunc={this.onInputGroupChange.bind(this)}
							component={InputField}
						/>
					</div>
				</div>														
			</div>
		);		
	}
	
	render() {
	
		const { handleSubmit } = this.props;
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{this.state.user ? i18n.t('subscribers:user_title_edit') : i18n.t('subscribers:user_title_add')} 
					</h2>
				</header>
				
				<SubscribersUserToolbar
					formName={formName}
					section="details"
					id={this.state.id}
					user={this.state.user}
					{...this.props}
				/>	

				{this.renderPage()}			
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.forename) {
		errors.forename = 'Enter the users forename!';
	}
	
	if (!values.surname) {
		errors.surname = 'Enter the users surname!';
	}
	
	if (!values.email) {
		errors.email = 'Enter the users email!';
	}
	
	if (!values.group) {
		errors.group = 'Enter at least one group for this user!';
	}
		
	if(values.dob){

		let date = moment(values.dob, 'DD/MM/YYYY');
		
		if(values.dob.length !== 10 || !date.isValid()){
			errors.dob = 'Enter a valid date for clients date of birth!';
		}
	}

	return errors;
}

function mapStateToProps({ users, account, subscribers }, ownProps){
	return {
		users,
		account,
		subscribers
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		fetchSubscribersUsers,
		fetchSubscribersGroups,
		saveSubscriber
	} )(SubscribersUser)
);