import { retailersConstants } from '../constants/retailers';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchRetailersList(id, callback) {

    return async (dispatch) => {
		
		let url = '/retailers/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: retailersConstants.RETAILERS_LIST,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveRetailer(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/retailers/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/retailers/save', values);
			}	
								
			dispatch({
				type: retailersConstants.RETAILERS_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('retailers:notification_updated_title'),
					i18n.t('retailers:notification_updated_description'),
					request.data.retailers[0].image
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('retailers:notification_added_title'),
					i18n.t('retailers:notification_added_description'),
					request.data.retailers[0].image
				));
			}

			if(callback)
				callback(request.data.retailers[0].id);				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function fetchRetailersGroups(id, callback) {

    return async (dispatch) => {
		
		let url = '/retailers/groups';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: retailersConstants.RETAILERS_GROUPS_LIST,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveRetailersGroup(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/retailers/save_group/${id}`, values);
			}else{
				request = await axiosCreate().post('/retailers/save_group', values);
			}	
								
			dispatch({
				type: retailersConstants.RETAILERS_GROUPS_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('retailers:notification_group_updated_title'),
					i18n.t('retailers:notification_group_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('retailers:notification_group_added_title'),
					i18n.t('retailers:notification_group_added_description')
				));
			}

			if(callback)
				callback(request.data.groups[0].id);				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function assignRetailersGroup(formName, id, values, callback) {

	return async (dispatch) => {
				
		try {
			
			values.group = id;
					
			const request = await axiosCreate().post('/retailers/assign_group', values);
								
			dispatch({
				type: retailersConstants.RETAILERS_GROUPS_ASSIGN,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('retailers:notification_group_assigned_title'),
				i18n.t('retailers:notification_group_assigned_description')
			));

			if(callback)
				callback();				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function saveRetailerIntegration(formName, type, data, callback){
	
	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			request = await axiosCreate().post('/retailers/integration/' + type, data);
								
			dispatch({
				type: retailersConstants.RETAILERS_INTEGRATION_SAVE,
				payload: request
			});
			
			if(callback)
				callback(request.data.retailers[0]);				
			
			dispatch(stopSubmission(formName));

		} catch (error) {	
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
	
}

export function getRetailerConnectionConnect(type, callback) {

	return async (dispatch) => {
				
		try {
						
			const request = await axiosCreate().post('/retailers/connect/' + type, { redirect: window.location.origin + '/connect/' + type });
			callback(request.data);

		} catch (error) {			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchDeveloperRetailer(id, callback) {

    return async (dispatch) => {

		try {
			const request = await axiosCreate().get(`/retailers/developer/${id}`);
										
			dispatch({ 
				type: retailersConstants.RETAILERS_DEVELOPER,
				payload: request.data.retailer
			});
			
			callback();
			 			
		} catch(error) {
			dispatch({ 
				type: retailersConstants.RETAILERS_DEVELOPER,
				payload: false
			});
			
			callback();
		};
	}
}