import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ModalContainer } from 'react-router-modal';
import { connect } from 'react-redux';
import { Animated } from "react-animated-css";

import "../../style/animate.min.css";

class ModalWrapper extends Component {
	
	render() {
		
		let { overlay } = this.props; 
		let isVisible = true;
		let className = 'overlay';
						
		// FIRST PAGE LOAD
		if(typeof overlay.show == 'undefined'){
			className += ' hidden';
		}
		
		// SHOW OR HIDE
		if(!overlay.show){
			isVisible = false;
		}
		
		// BACK ACTION?
		let backButton = '';
		if(overlay.back){
			backButton = (
				<div className="back">
					<Link to={overlay.back.cancel ? overlay.back.cancel : overlay.back.url}>
						<button>
							<i className="fal fa-chevron-left"></i>
						</button>
					</Link>
				</div>
			)
		}

		// CLOSE BUTTON
		let closeButton = '';
		if(!overlay.back && overlay.closeUrl){
			closeButton = (
				<div className="close">
					<Link to={overlay.closeUrl}>
						<button>
							<i className="fal fa-times"></i>
						</button>
					</Link>
				</div>
			)
		}

		// ANY EXTRA CLASSES
		if(overlay.className){
			className += ' ' + overlay.className;
		}
				
		return (
			<Animated className={className} animationIn="slideInUp" animationOut="fadeOut" isVisible={isVisible}>
				{backButton}
				{closeButton}
				<div className="content container">
					<div className="modal-container">
						<ModalContainer />
					</div>
				</div>
			</Animated>
		);
	}
}

function mapStateToProps({ overlay }, ownProps) {
	//return { post: posts[ownProps.match.params.id] };
	return { 
		overlay
	 };
}

export default connect(mapStateToProps)(ModalWrapper);
