import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 
import { unsubscribeSubscriber } from '../../actions/subscribers';
import Swal from 'sweetalert2';
import { showNotification } from '../../actions/notifications';

class SubscribersUserToolbar extends Component {
		
	render() {
		
		let buttons = [];
		
		let tabs = [{
			label: i18n.t('subscribers:toolbar_user_details'),
			url: `${this.props.parentPath}/audience/${this.props.id}/users/${this.props.user}`,
			selected: this.props.section === 'details' ? true : false
		}];
		
		if(this.props.user){
			tabs.push({
				label: i18n.t('subscribers:toolbar_user_history'),
				url: `${this.props.parentPath}/audience/${this.props.id}/users/${this.props.user}/history`,
				selected: this.props.section === 'history' ? true : false
			});
		}
								
		switch(this.props.section){
				
			case 'details':
				if(this.props.user){
					buttons.push({
						onClick: () => {
							
							Swal.fire({
								title: i18n.t('subscribers:unsubscribe_dialog_title'),
								text: i18n.t('subscribers:unsubscribe_dialog_description'),
								icon: 'warning',
								showCancelButton: true,
								confirmButtonText: i18n.t('dialog_confirm'),
								cancelButtonText: i18n.t('dialog_cancel'),
								focusConfirm: false
							}).then((result) => {
		
								if(result.value) {
									this.props.unsubscribeSubscriber(this.props.user, () => {
										
										this.props.showNotification(
											'success',
											i18n.t('subscribers:notification_unsubscribed_title'),
											i18n.t('subscribers:notification_unsubscribed_description')
										);
										
										this.props.history.push(`/subscribers/audience/${this.props.id}/users`);
									});
								}
							});
						},
						outline: true,
						className: "c-btn--danger",
						label: i18n.t('subscribers:unsubscribe_submit'),
					});
				}
				
				buttons.push({
					label: this.props.user ? i18n.t('subscribers:action_user_edit_submit') : i18n.t('subscribers:action_user_add_submit'),
					submit: this.props.formName
				});	
				break;
				
			case 'history':
				
				break;
				
			default:
			
				break;			
		}
		
		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
			/>			
		);
	}
}

function mapStateToProps({ users }, ownProps){
	return {
		users
	};
}

export default connect(mapStateToProps, { 
	unsubscribeSubscriber,
	showNotification
})(SubscribersUserToolbar);