import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userForgotten } from '../../actions/users.js';
import ErrorAlert from '../../components/chrome/error';
import InputField from '../../components/forms/field.js';
import Submit from '../../components/forms/submit';
import { startSubmission, stopSubmission } from '../../actions/forms';
import { imageResize } from '../../helpers/s3';

import i18n from '../../helpers/i18n'; 

var formName = 'formUserForgottrn';

class UserForgotten extends Component {

	constructor(props) {
	    super(props);
	    this.props.stopSubmission(formName);
	}

  	onSubmit(values) {
	  		  	
	  	this.props.startSubmission(formName);
	 
		this.props.userForgotten(formName, values.email, () => {			
			this.props.history.push('/login');
		});
	}
  	
	render(){
		
		const { handleSubmit } = this.props;

		return (
			<div className="o-page o-page--center">
        		{this.props.brand.details &&
					<img className="u-mb-medium logo" src={imageResize(`brands/${this.props.brand.details.directory}/general/logo.png`, 500)} alt={this.props.brand.details.name} />
				}
				<div className="o-page__card">
					<div className="c-card c-card--center">
						
						<div className="top">
							<h3>{i18n.t('users:forgotten_title')}</h3>
							<p>{i18n.t('users:forgotten_intro')}</p>	
						</div>
							
						<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
							
							<Field
								label={i18n.t('users:forgotten_label_email')} 
								name="email"
								type="email"
								component={InputField}
							/>
															
							<ErrorAlert />
							<Submit 
								label={i18n.t('users:forgotten_action_submit')} 
								form={formName} 
							/>
							
						</form>
					</div>
					<div className="u-text-center u-mt-small">
						<Link to="/login" className="u-text-small">
							{i18n.t('users:forgotten_action_cancel')}
						</Link>
					</div>
				</div>
        	</div>
		);
	}
}

function validate(values) {

	const errors = {};
	var validator = require("email-validator");

	if (!values.email) {
		errors.email = 'Enter your email address!';
	}else if(!validator.validate(values.email)){
		errors.email = 'Enter a valid email address!';
	}
	
	return errors;
}


function mapStateToProps({ brand }, ownProps) {
	return { 
		brand
	};
}
export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		userForgotten,
		startSubmission, 
		stopSubmission
	} )(UserForgotten)
);