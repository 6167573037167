import { overlayConstants } from '../constants/overlay';

export function overlayShow(closeUrl = false, className = false, back = false) {
			
	return {
		type: overlayConstants.SHOW,
		payload: {
			closeUrl,
			className,
			back
		} 
	};
}

export function overlayHide(callback) {
		
	return async (dispatch) => {
	    	    
	    dispatch({ 
			type: overlayConstants.HIDE
		});
				
		if(callback)
			callback();
	}
}