import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import Table from '../../components/chrome/table';
import _ from 'lodash';
import OverlaySettings from '../../components/chrome/overlay_settings';

import Loading from '../../components/chrome/loading';
import EmptySection from '../../components/chrome/empty';
import { imageResize } from '../../helpers/s3';

import { setSectionOptions } from '../../actions/chrome';
import RegionalDetailsToolbar from './toolbar';
import { checkPermission } from '../../helpers/permissions';
import { fetchRegionalList } from '../../actions/regional';
import { usersFetch, userReinvite, userDelegateLogin } from '../../actions/users';
import { generateBack } from '../../helpers/back';

var formName = 'formRegionalUsers';

class RegionalUsers extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'REGIONAL_USERS')){
			this.props.history.push('/regional');
		}

		if(!id){
			this.props.history.push('/regional');
		}
		
		this.props.fetchRegionalList(id, () => {
	       	
	       	if(this.props.regional.groups){
		       
		       	if(!this.props.regional.groups[id]){
					this.props.history.push('/regional');
				}else{
					this.updateDomElements();
				}
			}
       	});

		this.props.usersFetch(false, id);
					       	       			
		this.state = {
			id: id
		}
		
		this.updateDomElements();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
		
		if(this.props.regional.groups && this.state.id){
			//let regional = this.props.regional.groups[this.state.id];			
		}
	}
	
	renderPage(){
		
		let { regional } = this.props;
				
		let users = [];
		
		if(regional.groups[this.state.id].users && this.props.users.users){
						
			_.forEach(regional.groups[this.state.id].users, (user, key) => {	
				
				if(this.props.users.users[user]){
					users.push(this.props.users.users[user]);
				}
			}, this)			
		}
								
		if(_.isEmpty(users)){

			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t('regional:empty_users_title', { type: this.state.type })}
					description={i18n.t('regional:empty_users_description', { type: this.state.type })}
					cta={{
						label: i18n.t('regional:action_add_user'),
						url: `${this.props.parentPath}/user/regional?id=${this.state.id}&b=${generateBack(window.location.pathname)}`
					}}
				/>
			);
		}
		
		let back = generateBack(window.location.pathname);
		
		return (
			<div className={this.state.own ? 'container' : null}>
				<div className="c-card">
					<Table 
						columns={[
							{
								key: "image",
								label: "",
								format: 'image_circle',
								className: 'tight'
							},
							{
								key: "name",
								label: "Name",
								sortable: true
							},
							{
								key: "status",
								label: "Status",
								sortable: true
							},
							{
								key: "actions",
								label: "Actions",
								className: "tight right"
							}
						]}
						data={_.map(users, (user, key) => {
										
							let name = `${user.forename} ${user.surname}`;	
							let actions = ['edit'];
														
							if(user.active === '0'){
								actions.push('resend');
							}else if(checkPermission(this.props.account.permissions, 'REGIONAL_USERS_LOGIN')){
								actions.push('login');
							}
							
							return ({
								id: user.id,
								image: {
									src: user.image ? imageResize(user.image, 100) : false,
									icon: 'fal fa-user',
									alt: name
								},
								name: name,
								status: user.active === '1' ? i18n.t('regional:users_label_status_active') : i18n.t('regional:users_label_status_invited'),
								actions: actions								
							});
						})}
						actions={{
							edit: {
								tooltip: i18n.t('regional:users_action_edit'),
								icon: 'fal fa-edit',
								url: `${this.props.parentPath}/user/regional/[ID]?b=${back}`
							},
							resend: {
								tooltip: i18n.t('regional:users_action_resend_invite'),
								icon: 'fal fa-envelope',
								onClick: (id, e) => {
									this.props.userReinvite(id);
								}
							},
							login: {
								tooltip: i18n.t('regional:users_action_login'),
								icon: 'fal fa-sign-in',
								onClick: (id, e) => {
									this.props.userDelegateLogin(id, () => {
										this.props.history.push('/');
									});
								}
							},
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={(row) => {
							this.props.history.push(`${this.props.parentPath}/user/regional/${row.id}?b=${back}`);
						}}
					/>
				</div>
			</div>
		)
	}
	render() {
		
		let { regional } = this.props;
				
		if(!regional.groups || !regional.groups[this.state.id]){
			return (
				<Loading />
			);
		}
		
		return (

			<React.Fragment>
			
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{regional.groups[this.state.id].name}
					</h2>
				</header>
				
				<RegionalDetailsToolbar
					formName={formName}
					section="users"
					id={this.state.id}
					{...this.props}
				/>

				{this.renderPage()}
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, users, regional }, ownProps){
	return {
		users,
		regional,
		account
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchRegionalList,
	usersFetch,
	userReinvite,
	userDelegateLogin
})(RegionalUsers);