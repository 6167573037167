import { messagesConstants } from '../constants/messages';

export default function(state = {}, action) {
		
	switch(action.type){
			
		case messagesConstants.FETCH_NOTIFICATIONS:
			
			return { 
				...state, 
				notifications: action.payload.data 
			}
			
		case messagesConstants.FETCH_SUGGESTIONS:
			
			return { 
				...state, 
				suggestions: action.payload.data 
			}
			
		default:
			return state;
	}
	
	
}