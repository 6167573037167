import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/chrome/loading';
import { connectionConfirm } from '../../actions/connections';

class Connections extends Component {

	constructor(props) {
		
		super(props);

		const { type } = this.props.match.params;

		if(!type){
			this.props.history.push('/');
		}
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		if(!queryString.token){
			this.props.history.push('/');
		}
		
		switch(type){
			
			case 'twitter':
				if(!queryString.oauth_token || !queryString.oauth_verifier){
					this.props.history.push('/');
				}
				
				this.props.connectionConfirm('twitter', { token: queryString.token, oauth_token: queryString.oauth_token, oauth_verifier: queryString.oauth_verifier }, () => {
					localStorage.setItem('connection_refresh', 'twitter');
					window.close();
				});
				break;
				
			default:
			
				break;
		}
	}

	render() {

		return (
			<Loading 
				className="full brand"
			/>
		);
	}
}

function mapStateToProps({ connections }, ownProps){
	return {
		connections
	};
}

export default connect(mapStateToProps, { 
	connectionConfirm
})(Connections);
