import { optionsConstants } from '../constants/options';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function fetchTags(callback) {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get('/options/tags');
										
			dispatch({ 
				type: optionsConstants.TAGS_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchFonts(callback) {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get('/options/fonts');
										
			dispatch({ 
				type: optionsConstants.FONTS_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchTimezones(callback) {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get('/options/timezones');
										
			dispatch({ 
				type: optionsConstants.TIMEZONES_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}