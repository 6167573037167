import { brandConstants } from '../constants/brand';

const defaultState = { details: false };

export default function(state = defaultState, action) {
		
	switch (action.type) {				
		      
		case brandConstants.BRAND_DETAILS:
			
			let details = action.payload.data;
			
			if(details.languages){
				details.languages = details.languages.split(',');			
			}
												
			return { 
				...state, 
				details: details
			}
			
		default:
			return state;
	}
}