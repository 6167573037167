import { chromeConstants } from '../constants/chrome';

let defaultState = {
	title: '',
	section: false
}

export default function(state = defaultState, action) {

	switch (action.type) {
		      
		case chromeConstants.SECTIONTITLE:
			
			return { 
				...state, 
				title: action.payload.title,
				section: action.payload.section,
				back: action.payload.back
			}
			
		default:
			return state;
	}
}