import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import CopyCode from '../../chrome/copy_code';
import { showNotification } from '../../../actions/notifications';

class IntegrationsEmail extends Component {

	render() {		
		
		let hub = this.props.retailer.domains.hub;
		
		if(this.props.retailer.domains.custom){
		
			if(this.props.retailer.domains.custom.connected){
				hub = this.props.retailer.domains.custom.domain;
			}
		}		
		
		return (
			<ol className="numbererd">
				<li>
					Copy the unique embed code below:
					
					<CopyCode
						className="u-mt-small u-mb-small"
						value={`<iframe src="https://${hub}/subscribe" style="border:none"></iframe>`}
						onCopy={() => {
							this.props.showNotification(
								'success',
								i18n.t('retailers:notification_integration_email_local_url_copied_title'),
								i18n.t('retailers:notification_integration_email_local_url_copied_description')
							);	
						}}
					/>
					
				</li>
				<li>Paste this on the website where the sign up form should appear.</li>
			</ol>
		);
	}
}

function mapStateToProps({ }, ownProps){
	return {

	};
}

export default connect(mapStateToProps, { 
	showNotification,
})(IntegrationsEmail);