import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import Table from '../../components/chrome/table';
import EmptySection from '../../components/chrome/empty';
import moment from 'moment';
import 'moment/min/locales';
import _ from 'lodash';
import i18n from '../../helpers/i18n'; 
import { checkPermission } from '../../helpers/permissions';
import { imageResize } from '../../helpers/s3';
import { Link } from 'react-router-dom';

import { fetchArticlesList } from '../../actions/articles';
import { setSectionOptions } from '../../actions/chrome';

class Articles extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'ARTICLES')){
			this.props.history.push('/');
		}
		
		moment.locale(i18n.language);
		
		this.props.setSectionOptions('articles', i18n.t('articles:title'));
		this.props.fetchArticlesList();
		
		this.state = {
			type: 'active',
			search: false,
			filters: {
				keyword: false
			}
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onChangeType(type){
		this.setState({
			type: type,
		});
	}
	
	filterData(type){
		
		let articles = this.props.articles.articles;
		let now = moment.utc().format('YYYY-MM-DD HH:mm');
		let data = {
			active: [],
			approved: [],
			pending: [],
			draft: []
		};
				
		// APPLY FILTERS
		if(this.state.search){
			
			if(this.state.filters.keyword){
				articles = _.filter(articles, article => (article.title.toLowerCase().includes(this.state.filters.keyword.toLowerCase()) ));
			}
		}
		
		if(type === 'counter' || this.state.type === 'active'){
			data.active = _.filter(articles, article => (
				article.status === 1 && 
				(
					(
						article.publish !== null && 
						moment(article.publish.date).format('YYYY-MM-DD HH:mm') <= now
					)
					
					||
					
					(
						article.publish_campaign && 
						article.publish_campaign.date_start !== null && 
						moment(article.publish_campaign.date_start.date).format('YYYY-MM-DD HH:mm') <= now && 
						(article.publish_campaign.status === 'launched' || article.publish_campaign.status === 'deploy' || article.publish_campaign.status === 'deployed' || article.publish_campaign.status === 'deactivated')
					)
				)
			));
		}
		
		if(type === 'counter' || this.state.type === 'approved'){
			data.approved = _.filter(articles, article => (
				article.status === 1 && 
				(
					(
						article.publish !== null && 
						moment(article.publish.date).format('YYYY-MM-DD HH:mm') > now
					)
					
					||
					
					(
						article.publish_campaign && 
						article.publish_campaign.date_start !== null && 
						(
							moment(article.publish_campaign.date_start.date).format('YYYY-MM-DD HH:mm') > now || 
							article.publish_campaign.status === 'draft'
						)
					)
					
					|| 
					
					(
						article.publish === null && 
						!article.publish_campaign
					)
				)
			));
		}
		
		if(type === 'counter' || this.state.type === 'pending'){
			data.pending = _.filter(articles, article => (article.status === 2));
		}
		
		if(type === 'counter' || this.state.type === 'draft'){
			data.draft = _.filter(articles, article => (article.status === 0));
		}
		
		if(type === 'counter'){
			
			return {
				active: data.active.length,
				approved: data.approved.length,
				pending: data.pending.length,
				draft: data.draft.length	
			};
					
		}else{
			return data[this.state.type];
		}
	}
	
	renderTable(){

		let data = this.filterData();	
		
		if(_.isEmpty(data)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t('articles:empty_title', { type: this.state.type })}
					description={i18n.t('articles:empty_description', { type: this.state.type })}
					cta={{
						label: i18n.t('articles:action_add'),
						url: `${this.props.location.pathname}/article`
					}}
				/>
			);			
		}
		
		let actions = [];
		let cols = [
			{
				key: "image",
				label: "",
				format: 'image_circle',
				className: 'tight'
			},
			{
				key: "title",
				label: "Title",
				sortable: true,
				format: 'image_pre'
			},
			{
				key: "date",
				label: "Publish Date",
				sortable: true,
				format: 'datetime'
			}
		];
		
		switch(this.state.type){
			
			case 'active':
				actions = ['edit'];
				break;
				
			case 'approved':
				actions = ['edit'];
				break;
				
			case 'pending':
				actions = ['edit'];
				break;
				
			case 'draft':
				actions = ['edit'];
				break;
				
			default:
			
				break;
		}
		
		cols.push({
			key: "actions",
			label: "Actions",
			className: "tight right"
		});
		
		return (
			<div className="c-card">
				<Table 
					columns={cols}
					data={_.map(data, (article, key) => {	
						
						let rowActions = _.clone(actions);
						
						/*if(article.readonly === 1 && rowActions.includes('edit')){
							_.remove(rowActions, o => o === 'edit');
							rowActions.push('view');
						}*/
						
						let publishText = false;
						
						if(article.publish === null){
							
							if(article.publish_campaign){
			
								if(article.publish_campaign.status === 'draft'){
									publishText = (
										<React.Fragment>
											Pending campaign launch: <Link to={`/campaigns/${article.publish_campaign.id}`}>{article.publish_campaign.internal_name}</Link>
										</React.Fragment>									
									);
								}else{
									publishText = (
										<React.Fragment>
											Campaign: <Link to={`/campaigns/${article.publish_campaign.id}`}>{article.publish_campaign.internal_name}</Link>
										</React.Fragment>									
									);
								} 
							}else{
								publishText = (
									<React.Fragment>
										Pending campaign assignment
									</React.Fragment>									
								);
							}
						}
						
						return ({
							id: article.id,
							image: {
								src: article.image ? imageResize(article.image, 100, 100) : false,
								icon: 'fal fa-file-alt',
								alt: article.title
							},
							title: {
								value: article.title,
								icon: article.readonly === 1 ? 'fal fa-inbox-in' : false,
								tooltip: article.readonly === 1 ? 'Received article' : false
							},
							date: {
								value: article.publish ? article.publish.date : (article.publish_campaign ? article.publish_campaign.date_start.date : false),
								text: publishText	
							},
							actions: rowActions							
						});
					})}
					actions={{
						edit: {
							tooltip: i18n.t('articles:action_edit'),
							icon: 'fal fa-edit',
							url: `${this.props.location.pathname}/article/[ID]`
						},
						view: {
							tooltip: i18n.t('articles:action_view'),
							icon: 'fal fa-eye',
							url: `${this.props.location.pathname}/article/[ID]`
						},
						analytics: {
							tooltip: i18n.t('articles:action_analytics'),
							icon: 'fal fa-analytics',
							url: `${this.props.location.pathname}/article/[ID]/analytics`
						}
					}}
					sort={{
						column: 'title',
						order: 'asc'
					}}
					rowClick={(row) => {
						this.props.history.push(`${this.props.location.pathname}/article/${row.id}`);
					}}
				/>
			</div>
		);
	}

	render() {

		let { articles } = this.props;

		if(!articles.articles){
			return (
				<Loading />
			);
		}
		
		let counter = this.filterData('counter');

		return (
			<React.Fragment>
							
				<Toolbar
					tabs={[
						{
							label: i18n.t('articles:toolbar_active'),
							counter: String(counter.active),
							onClick: () => { this.onChangeType('active') },
							selected: this.state.type === 'active' ? true : false
						},
						{
							label: i18n.t('articles:toolbar_approved'),
							counter: String(counter.approved),
							onClick: () => { this.onChangeType('approved') },
							selected: this.state.type === 'approved' ? true : false
						},
						{
							label: i18n.t('articles:toolbar_approval'),
							counter: String(counter.pending),
							onClick: () => { this.onChangeType('pending') },
							selected: this.state.type === 'pending' ? true : false
						},
						{
							label: i18n.t('articles:toolbar_draft'),
							counter: String(counter.draft),
							onClick: () => { this.onChangeType('draft') },
							selected: this.state.type === 'draft' ? true : false
						}
					]}
					buttons={[
						{
							label: i18n.t('articles:action_add'),
							url: `${this.props.location.pathname}/article`
						}
					]}
					filter={{
						toggleFunc: (status) => {
							this.setState({
								search: status
							});
						},
						onChangeFunc: (event, type) => { 
							
							let filters = this.state.filters;
							
							switch(type){
								
								case 'keyword':
									filters.keyword = event.target.value;
									break;
								
								default:
									return;
							}
					
							this.setState({
								filters: filters
							})
						},
						fields: [
							{
								placeholder: i18n.t('articles:filter_keyword'),
								name: "keyword",
								type: 'text'
							}
						],
						focus: 'keyword'
					}}
				/>	
								
				<div className="container">	
					{this.renderTable()}
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ articles, account }, ownProps){
	return {
		articles,
		account
	};
}

export default connect(mapStateToProps, { 
	fetchArticlesList,
	setSectionOptions
})(Articles);