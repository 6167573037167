import axios from 'axios';

export const key = '5VKAK75SBBU63FDC95RM';
export let url = 'https://api.pushcampaign.io';

if(process.env.NODE_ENV === 'development'){
	url = 'http://127.0.0.1:8000';
}else if(process.env.NODE_ENV === 'development' || window.location.hostname.indexOf('staging') > -1){
	url = 'https://api.staging.pushcampaign.io';
}

export function axiosCreate(progressUpdater) {
	
	var config = {
		baseURL: url,
		headers: {
			'API-KEY': key,
			'APP-HOST': window.location.hostname
		}
	}
	
	if(progressUpdater){
		
		config.onUploadProgress = (progressEvent) => {
			var percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);			
			progressUpdater(percentComplete);
		}
		
		config.headers['content-type'] = 'multipart/form-data';
	}

	if(localStorage.getItem('user_id')){
		config['headers']['AUTH-TOKEN'] = localStorage.getItem('user_token');
		config['headers']['AUTH-ID'] = localStorage.getItem('user_id');
		
		if(localStorage.getItem('delegate_id') && localStorage.getItem('delegate_id') !== 'null'){
			config['headers']['AUTH-DELEGATE-ID'] = localStorage.getItem('delegate_id');
		}
	}

	return axios.create(config);
}
