import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmptySection from '../../components/chrome/empty';
import i18n from '../../helpers/i18n'; 
import { ping } from '../../actions/system';

class Maintenance extends Component {
	
	constructor(props){
		super(props);		
		this.maintenanceCheckTimer = false;
	}
	
	componentDidMount(){
		
		// RUN TO CHECK IF BACK UP?!
		this.maintenanceCheckTimer = setInterval(() => {
			this.recheck();
		}, 5000);
		
		// REMOVE LOADING SCREEN IF THERE?
		let loading = document.getElementsByClassName('loading');
		
		if(loading.length > 0){
			loading[0].remove();
		}
	}
	
	componentWillUnmount(){
		clearInterval(this.maintenanceCheckTimer);
	}
	
	recheck(){
		
		this.props.ping((res) => {
			window.location = '/';
		});
	}
	
	render(){
		
		return (
			<EmptySection
				icon="fa-wrench"
				title={i18n.t('translation:maintenance_title')}
				description={i18n.t('translation:maintenance_description')}
				cta={{
					label: i18n.t('translation:maintenance_cta'),
					onClick: this.recheck.bind(this)
				}}
			/>
		);
	}
}

export default connect(null, { 
	ping
})(Maintenance);