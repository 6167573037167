import React, { Component } from 'react';
import DatePicker from '../../components/forms/datepicker.js';
import Info from '../../components/chrome/info.js';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import i18n from '../../helpers/i18n'; 
import makeAnimated from 'react-select/animated';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import 'moment/min/locales';
import _ from 'lodash';
import TextareaAutosize from 'react-autosize-textarea';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { SketchPicker } from 'react-color'
import MaskedInput from 'react-input-mask';

import { dateString } from '../../helpers/dates';
const animatedComponents = makeAnimated();

export default class InputField extends Component {

	constructor(props){
		super(props);
		moment.locale(i18n.language);
		
		this.state = {
			datepickerPopup: 'closed',
			displayColorPicker: false,
			color: '#000000'
		}
	}
	
	onInputChange(event) {
				
		// ALWAYS CALL DEFAULT ONCHANGE HERE
        if(this.props.input){
	        this.props.input.onChange(event);
		}
		
        // THEN CALL THE CUSTOM ONCHANGE IF SET
        if(this.props.onChangeFunc){
	        this.props.onChangeFunc(event);
	    }
	}
	
	onInputBlur(event) {
				
		// ALWAYS CALL DEFAULT ONBLUR HERE
        if(this.props.input && this.props.input.onBlur){
	        //this.props.input.onBlur(event);
		}
		
        // THEN CALL THE CUSTOM ONBLUR IF SET
        if(this.props.onBlurFunc){
	        this.props.onBlurFunc(event);
	    }
	}
	
	onColorClick(){
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};
	
	onColorClose(){
		this.setState({ displayColorPicker: false })
	};
	
	onColorChange(color){
		
		this.setState({ color: color.hex })
		
		// THEN CALL THE CUSTOM ONCHANGE IF SET
        if(this.props.onChangeFunc){
	        this.props.onChangeFunc(color.hex);
	    }
	};
	
	renderInput(className) {
		
		let placeholder;
						
		switch (this.props.type) {

			case 'echo':
			
				let echo = this.props.value;
				
				if(this.props.echo){
					echo = this.props.echo;
				}
				
				return (
					<div className={`u-mb-small ${className}`}>
						{echo}
					</div>
				);
				
			case 'text':
			case 'email':
			case 'hidden':
			case 'number':
			case 'password':
			case 'url':
				
				return (
					<input
						{...this.props.input}
						type={this.props.type}
						//value={this.props.value}
						disabled={this.props.disabled}
						placeholder={this.props.placeholder}
						className={`u-mb-small ${className} c-input`}
						onChange={this.onInputChange.bind(this)}
						onBlur={this.onInputBlur.bind(this)}
					/>
				);
				
			
			case 'textarea':

				return (
					<textarea
						{...this.props.input}
						className={`u-mb-small ${className} c-input`}
						onChange={this.onInputChange.bind(this)}
						//value={this.props.value}
						placeholder={this.props.placeholder}
					>
					</textarea>
				);
				
			case 'textarea-auto':

				return (
					<TextareaAutosize
						{...this.props.input}
						className={`u-mb-small ${className} c-input`}
						onChange={this.onInputChange.bind(this)}
						//value={this.props.value}
						placeholder={this.props.placeholder}
					>
					</TextareaAutosize>
				);

			case 'switch':				

				return (
					<label className={`c-switch ${this.props.labelSwitch ? 'u-mr-small' : ''} u-mb-small ${className}`}>						
						<input 
							{...this.props.input}
							className={`${className} c-switch__input`}
							type="checkbox"
							checked={this.props.selected}
							onChange={this.onInputChange.bind(this)}
							disabled={this.props.disabled ? 'disabled' : false}
						/>
						
							<span className="c-switch__label">
								{this.props.labelSwitch}
							</span>
					</label>
				)
				
			case 'datepicker':

				let value = '';
				let style = {};
				
				if(this.props.range){
					value = dateString('date', this.props.selected.startDate, this.props.selected.endDate);
				}else if(this.props.timepicker){
					value = dateString('datetime', this.props.selected);
				}else{
					value = dateString('date', this.props.selected);
				}
				
				if(this.props.disabled){
					return (
						<div className="u-mb-small">
							{value}
						</div>
					);
				}
				
				if(this.props.autoWidth){	
					
					var lDiv = document.createElement('div');

				    document.body.appendChild(lDiv);
				
				    /*if (pStyle != null) {
				        lDiv.style = pStyle;
				    }*/
				    
				    lDiv.style.fontSize = ".75rem";
				    lDiv.style.position = "absolute";
				    lDiv.style.left = -1000;
				    lDiv.style.top = -1000;
				
				    lDiv.innerHTML = value;
				
				    var lResult = {
				        width: lDiv.clientWidth,
				        height: lDiv.clientHeight
				    };
				
				    document.body.removeChild(lDiv);
				    lDiv = null;
				    
				    
				    style = { width: (lResult.width + 40) + 'px', textAlign: 'center' }
				}
								
				return (
					<React.Fragment>
						<input
							{...this.props.input}
							type="text"
							autoComplete="off"
							disabled={this.props.disabled}
							className={`${className} c-input u-mb-small`}
							value={value}
							onFocus={() => {
								this.setState({
									datepickerPopup: 'open'
								});
							}}
							onClick={() => {
								this.setState({
									datepickerPopup: 'open'
								});
							}}
							style={style}
						/>
						<DatePicker 
							popup={this.state.datepickerPopup}
							selected={this.props.selected}
							type={this.props.type}
							alignRight={this.props.alignRight ? true : false}
							onChange={(date) => {
																
								this.setState({
									datepickerPopup: 'open'
								});
								
								if(this.props.onChangeFunc){
									this.props.onChangeFunc(date);
								}
							}}
							close={() => {
								this.setState({
									datepickerPopup: 'closed'
								});
							}}
							range={this.props.range}
							timepicker={this.props.timepicker}
							minDate={this.props.minDate ? this.props.minDate : false}
							maxDate={this.props.maxDate ? this.props.maxDate : false}
						/>						
					</React.Fragment>
				);

			case 'suggest':
			
				let options = [];
				let selected = [];
			
				_.forEach(this.props.options, (item, key) => {					
									
					if(this.props.multiple && this.props.selected && this.props.selected !== null && (this.props.selected.includes(item.value) || _.find(this.props.selected, { value: item.value }))){
						options.push(item);
						selected.push(item);
					
					}else if(item.options && this.props.selected !== null){
						options.push(item);
						
						_.forEach(item.options, (sub, key2) => {	
							
							if(this.props.multiple && _.find(this.props.selected, { value: sub.value })){
								selected.push(sub);
							}else if(this.props.selected === sub.value){
								selected.push(sub);
							}
						});
												
					}else if(this.props.selected !== null && this.props.selected === item.value){
						options.push(item);
						selected.push(item);
					}else if(!this.props.custom || item.fixed || item.__new__){
						options.push(item);
					}
				});
				
				if(this.props.custom && this.props.selected){
					
					_.forEach(this.props.selected, (item, key) => {					
									
						if(item.__isNew__){
							options.push(item);
							selected.push(item);
						}
					});
				}
				
				if(this.props.sort){
										
					options.sort(function(a,b){
						
						let itemA = a.label.toLowerCase();
						let itemB = b.label.toLowerCase();
										
						if(itemA < itemB) return -1;
					    if(itemA > itemB) return 1;
					    return 0;
					});
								
					switch(this.props.sort){
						
						case 'desc':
							options.reverse();
							break;
							
						default:
						
							break;
					}
				}
				
				let settings = {}
				let SelectComponent = Select;
				
				if(this.props.loadOptions){
					
					if(this.props.custom){
						SelectComponent = AsyncCreatableSelect;
					}else{
						SelectComponent = AsyncSelect;
					}
					
					settings.loadOptions = this.props.loadOptions;
					
					if(this.props.selected){
						selected = this.props.selected;
					}
					
				}else if(this.props.custom){
					SelectComponent = CreatableSelect;
				}
				
				if(this.props.isClearable){
					settings.isClearable = true;
				}
				
				
				/*let selected = [];
				
				if(this.props.multiple && this.props.selected){
										
					_.forEach(options, (item, key) => {					
			
						if(found = _.find(options, { value: item })){
							selected.push(found);
						}
					});
				}*/

				const SingleValue = ({ children, ...props }) => {
					
					let label = props.data.label;

					return(
						<components.SingleValue {...props}>
							{props.data.icon && 
				            	<i className={`${props.data.icon} u-mr-xxsmall`} />
				            }
				            <span>{label}</span>
						</components.SingleValue>
					);
				};
				
				const MultiValueLabel = props => {
										
					return (
					    <components.MultiValueLabel {...props}>
				            {props.data.icon && 
				            	<i className={`${props.data.icon} u-mr-xxsmall`} />
				            }
				            <span>{props.data.label}</span>
				        </components.MultiValueLabel>
					);
				};
												
				const Option = props => {
										
					let iconImage = '';
				
					if(props.data.iconDropdown || props.data.imageDropdown){
												
						if(props.data.iconDropdown){
							iconImage = (
								<i className={`c-avatar__img ${props.data.iconDropdown}`} />
							);						
						}else if(props.data.imageDropdown){
														
							if(props.data.imageDropdown.background){
								
								let size = 'cover';
							
								if(props.data.imageDropdown.size){
									size = props.data.imageDropdown.size;
								}
								
								iconImage = (
									<div style={{ backgroundSize: size, backgroundImage: `url(${props.data.imageDropdown.background}` }} className="c-avatar__img background"></div>
								);	
													
							}else{
								iconImage = (
									<img src={props.data.imageDropdown} className="c-avatar__img" alt={props.data.labelDropdown ? props.data.labelDropdown : props.data.label} />
								);
							}													
						} 
						
						iconImage = (
							<div className="c-avatar c-avatar--xsmall">
								{iconImage}
							</div>
						);
						
					}else if(props.data.icon){
						
						iconImage = (
							<i className={`${props.data.icon} u-mr-xxsmall`} />
						);
					}
										
					return (
						<components.Option {...props}>
							
							{iconImage}
							
							<div>
								{props.data.labelDropdown && 
									
									<div>
										{props.data.labelDropdown}
										
										{props.data.labelDropdownSub && 
											<div className="sub">
												{props.data.labelDropdownSub}
											</div>
										}
									</div>
								}
								
								{!props.data.labelDropdown && 
									props.data.label
								}
							</div>
						</components.Option>
					);
				};
				
				if(this.props.onBlur){
					settings.onBlur = this.onBlur.bind(this);
				}
											
				return (
					<SelectComponent
			            {...this.props}
			            components={{
				            animatedComponents ,
							SingleValue,
							Option,
							MultiValueLabel
				        }}
						className={`u-mb-small ${className}`}
						classNamePrefix="suggest"
						options={options}
						value={selected}
						openOnFocus={true}
						minLength={this.props.minLength ? this.props.minLength : 1}
						searchable={this.props.searchable}
						isMulti={this.props.multiple}
						onChange={this.onInputChange.bind(this)}
						noOptionsMessage={() => this.props.emptyMessage ? this.props.emptyMessage : i18n.t('field_suggest_empty') }
						placeholder={this.props.placeholder ? this.props.placeholder : i18n.t('field_suggest_placeholder')}
						formatCreateLabel={(inputValue) => { 
							return `${i18n.t('field_suggest_create')}: ${inputValue}`; 
						}}
						onBlur={this.onInputBlur.bind(this)}
						{...settings}
					/>
				);
				
			case 'tel':
				return (
					<PhoneInput
						containerClass="react-tel-input u-mb-small"
						inputClass={`c-input ${className}`}
						onChange={this.onInputChange.bind(this)}
						value={this.props.selected}
						enableAreaCodes={false}
						country={this.props.country ? this.props.country.toLowerCase() : false}
					/>
				);
				
			case 'color':
			
				return (
					<div className="color-picker">
						<div className="swatch" onClick={ this.onColorClick.bind(this) }>
							<div className="color" style={{ background: this.props.color }} />
						</div>
						{this.state.displayColorPicker &&
							 <div className="popover">
								<div className="cover" onClick={ this.onColorClose.bind(this) } />
								<SketchPicker 
									color={this.props.color} 
									onChangeComplete={ this.onColorChange.bind(this) } 
									disableAlpha={true}
									presetColors={[]}
								/>
							</div>
						}
				
					</div>
				);
				
			case 'masked':
				
				let maskChar = '_';													
				placeholder ='';
				
				if(this.props.placeholder){
					placeholder = this.props.placeholder;
				}
				
				if(this.props.maskChar){
					maskChar = this.props.maskChar;
				}
											
				return (
					<MaskedInput 
						{...this.props} 
						{...this.props.input} 
						placeholder={placeholder} 
						mask={this.props.mask}
						maskChar={maskChar}
						className={className}
						type="text"
					/>
				);
				
			case 'date':
			
				placeholder = "DD/MM/YYYY";
				let mask = '12/34/9999';
				let newMask = mask;
				let splitValue = '';
				
				return (
					<MaskedInput
						{...this.props} 
						{...this.props.input}
						type="text"
						className={`u-mb-small ${className} c-input`}
						mask={mask}
						formatChars={{
						    '1': '[0-3]', // D1
						    '2': '[0-9]', // D2
						    '3': '[0-1]', // M1
						    '4': '[0-9]', // M2
						    
						    '5': '[0-1]', // D2 - if D1 starts with 3
						    '6': '[0-2]', // M2 - if M1 starts with 1
						    
						    '9': '[0-9]' // Y
						}}
						placeholder={placeholder}
						maskPlaceholder={placeholder}
						onChange={(event) => {
							
							var value = event.target.value;														
							newMask = mask;
							
							if(value !== ''){
								
								splitValue = value.split('/');
							
								if(splitValue[0].startsWith('3')){								
									newMask = newMask.substr(0,1) + '5' + newMask.substr(2);
								}
								
								if(splitValue[1].startsWith('1')){								
									newMask = newMask.substr(0,4) + '6' + newMask.substr(5);
								}
							}
							
							var newState = {
								mask: newMask,
								value: value
							};

							this.setState(newState);
						}}
						{...this.state}
					/>	
				);							
				break;
				
			default:
				return;
		}
	}

	render() {

		let label = '';
		let labelRight = '';
		let labelSub = '';
		let labelTooltip = '';
		let errorMessage = '';
		let touched = false;
		let error = false;
		let append = false;
		let prepend = false;
		let clear = false;
			
		if(this.props.meta){
			
			if(this.props.meta.touched){
				touched = this.props.meta.touched;
			}
			
			if(this.props.meta.error){
				error = this.props.meta.error;
			}
		}
		
		if(this.props.tooltip){
			labelTooltip = (
				<Info {...this.props.tooltip} />
			);
		}
		
		if(this.props.labelRight){
			labelRight = (
				<div className="right">
					{this.props.labelRight}
				</div>
			)
		}

		let className = `${touched && error ? 'c-input--danger' : ''}`;

		if(this.props.className){
			className = `${className} ${this.props.className}`;
		}
		
		if (this.props.label) {
			label = (
				<label className="c-field__label">
					{this.props.label}
					{labelTooltip !== '' ? labelTooltip : null}
					{this.props.labelRight ? labelRight : null}
					{this.props.labelIcon ? this.props.labelIcon : null}
				</label>
			);
		}

		if (this.props.labelSub) {
			labelSub = (
				<div className="u-mb-xsmall">
					{this.props.labelSub}
				</div>
			);
		}
		
		if (this.props.prepend) {
			prepend = (
				<div className="pended">
					{this.props.prepend}
				</div>
			);
		}

		if (this.props.append) {
			append = (
				<div className="pended">
					{this.props.append}
				</div>
			);
		}
		
		if(this.props.onClearFunc){
			clear = (
				<span 
					className="clear-icon"
					onClick={this.props.onClearFunc}
				>
					<i className="fal fa-times-circle"></i>
				</span>
			);
		}

		if (this.props.errorMessage) {
			errorMessage = (
				<div className="invalid-feedback">
					{touched ? error : ''}
				</div>
			);
		}

		return (
			<div className={`c-field ${prepend ? 'prepend' : ''} ${append ? 'append' : ''} ${clear ? 'clear-field' : ''} labelSub`}>
				{label !== '' ? label : null}
				{labelSub !== '' ? labelSub : null}
				
				<div className="field">
					{prepend ? prepend : null}
					{this.renderInput(className)}
					{append ? append : null}
					{clear ? clear : null}
				</div>
				
				{errorMessage !== '' ? errorMessage : null}
			</div>					
		);

		/*return (
			<div className={group}>
				{label}
				<div className={group}>
					{prepend}
					{this.renderInput(className)}
					{append}
				</div>
				{errorMessage}
			</div>	
		);*/
	}
}