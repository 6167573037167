import React from 'react';
import i18n from '../../helpers/i18n'; 
import { Link } from 'react-router-dom';

const SendDeveloper = () => {

	return (
		<div className="c-card c-alertc-alert--danger u-mb-zero u-mt-large">
            <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
				<i className="fal fa-share" style={{ color: '#FFF' }}></i>
			</span>
		
			<div className="c-alert__content">
				<h4 className="c-alert__title">{i18n.t('retailers:integration_developer_share_title')}</h4>
				<p className="u-mb-small">{i18n.t('retailers:integration_developer_share_description')}</p>
				
				<Link type="button" className="c-btn c-btn--info" to="/settings/integrations/developer">
					{i18n.t('retailers:integration_developer_share_action')}
				</Link>
			</div>
		</div>
	);
}

export default SendDeveloper;