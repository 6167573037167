import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import { fetchCollectionsList, fetchAssetsList, saveAsset } from '../../actions/assets';
import { fetchArticlesList } from '../../actions/articles';
import { fetchProductList } from '../../actions/products';
import { saveCampaignItems, fetchCampaignsList } from '../../actions/campaigns';
import { makeSelection, clearSelection } from '../../actions/library';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import AssetGridItem from '../../components/assets/grid_item';
import EmptySection from '../../components/chrome/empty';
import DropzoneArea from '../../components/forms/dropzone';
import { generateBack } from '../../helpers/back';
import { startSubmission } from '../../actions/forms';
import Button from '../../components/chrome/button';
import CountUp from 'react-countup';

var formName = 'formLibrary';

class Library extends Component {

	constructor(props){
		super(props);
		
		const { type, id } = this.props.match.params;

		if(!type || !id){
			this.props.history.push(this.props.parentPath);
		}
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		let section = 'selected';
		
		if(queryString.type){
			section = queryString.type;
		}
				
		this.state = {
			id: id,
			parent: type, 
			type: section,
			search: false,
			keyword: false,
			title: i18n.t('library:title'),
			collection: false
		}
		
		this.props.fetchAssetsList(); // TODO: Assets better get!
		this.props.fetchCollectionsList();
		this.props.fetchArticlesList();
		this.props.fetchProductList();
	}
	
	componentDidMount(){
		
		switch(this.state.parent){
			
			case 'campaign':
								
				const loadCampaign = () => {

					let campaign = this.props.campaigns.campaigns[this.state.id];	
					
					if(campaign.items.assets){
						_.forEach(campaign.items.assets, (asset, key) => {	
							
							let headline = 0;
							
							if(campaign.items.headline.includes(asset)){
								headline = 1;
							}
														
							this.props.makeSelection('asset', [{ id: asset, headline: headline }]);
						}, this);

						_.forEach(campaign.items.articles, (article, key) => {	
							this.props.makeSelection('article', [{ id: article }]);
						}, this);
						
						_.forEach(campaign.items.products, (product, key) => {	
							this.props.makeSelection('product', [{ id: product }]);
						}, this);
					}
					
					this.setState({
						title: i18n.t('campaigns:library_title', { name: campaign.internal_name }) 
					});
				}
				
				if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id]){
					loadCampaign();
				}
				
				this.props.fetchCampaignsList(this.state.id, () => {
		       	
			       	if(this.props.campaigns.campaigns && !this.props.campaigns.campaigns[this.state.id]){
						this.props.history.push(this.props.parentPath);
					}else if(this.props.campaigns.campaigns){
						loadCampaign();
					}
		       	});
				
				break;
				
			default:
				this.props.history.push(this.props.parentPath);
				break;
		}
	}
	
	onChangeType(type){
		this.setState({
			type: type
		});
	}
	
	onSelectItem(type, id, headline){	
		
		let action = (_.find(this.props.library.selected, { 'type': type, 'id': id }) ? 'delete' : 'add');
		
		if(action === 'delete' && typeof headline !== 'undefined'){
			action = 'add';
		}else if(!headline){
			headline = 0;
		}	
		
		this.props.makeSelection(type, [{ id: id, headline: headline }], action);
	}
	
	filterData(retType){
		
		let type = false;
		let assets = [];
		let counter = {
			image: 0,
			video: 0,
			article: 0, 
			product: 0,
			selected: 0,
			collection: 0,
			document: 0
		};
		
		let collections = [];
					
		_.forEach(this.props.assets.collections, (collection, key) => {					
								
			collection.sub = [];
			
			const organiseSub = (id) => {
				
				let collections = _.filter(this.props.assets.collections, { parent_id: id });
				
				_.forEach(collections, (child, key) => {
					collection.sub.push(child.name);
					collections[key].children = organiseSub(child.id);
				});
				
				return collections;
			}
			
			organiseSub(collection.id);
			
			_.forEach(this.props.assets.assets, (asset, key) => {					
										
				if(collection.assets.includes(asset.id)){
					
					if(this.state.keyword){
						
						if(asset.name !== '' && asset.name !== null && asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
							assets.push(asset);
						}else if(asset.tags){
							
							if(_.filter(this.cleanTags('asset', asset.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
								assets.push(asset);
								return;
							}
						}
						
					}else{
						assets.push(asset);
					}
				}
			});
			
			collection.counter = assets.length;
			collection.sub_counter = collection.sub.length;
			
			if(this.state.keyword){
								
				collection.sub_counter = _.filter(collection.sub, title => (title.toLowerCase().includes(this.state.keyword.toLowerCase()))).length;
				
				if(collection.counter > 0 || collection.sub_counter > 0){
					++counter['collection'];
				}else{
					
					if(collection.name !== '' && collection.name !== null && collection.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
						++counter['collection'];
					}else if(collection.tags){
						
						if(_.filter(this.cleanTags('collection', collection.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
							++counter['collection'];
							return;
						}
					}
				}
							
			}else{
				++counter['collection'];
			}

		}, this);
		
		assets = [];
						
		_.forEach(this.props.assets.assets, (asset, key) => {	
		
			type = asset.type;
			
			switch(type){
				
				case 'youtube':
				case 'vimeo':
					type = 'video';	
					break;
					
				case 'pdf':
				case 'document':
				case 'presentation':
				case 'spreadsheet':
					type = 'document';	
					break;
					
				default:
				
					break;
			} 	
				
			if(this.state.search && this.state.keyword){
				
				if(asset.name !== '' && asset.name !== null && asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
					
					if(this.state.type === 'collection'){
							
						if(this.state.collection && asset.collection_id === this.state.collection){
							assets.push(asset);
						}
						
					}else if(this.state.type === type){
						assets.push(asset);
					}
					
					if(_.find(this.props.library.selected, { 'type': 'asset', 'id': asset.id })){
						
						if(this.state.type === 'selected'){
							assets.push(asset);
						}
						
						++counter['selected'];
					}
					
					++counter[type];
				}
				
			}else{
				
				if(this.state.type === 'collection'){
										
					if(this.state.collection && asset.collection_id === this.state.collection){
						assets.push(asset);
					}
					
				}else if(this.state.type === type){
					assets.push(asset);
				}
				
				if(_.find(this.props.library.selected, { 'type': 'asset', 'id': asset.id })){
						
					if(this.state.type === 'selected'){
						assets.push(asset);
					}
					
					++counter['selected'];
				}
				
				++counter[type];
			}
			
		}, this);
						
		_.forEach(this.props.articles.articles, (article, key) => {	
			
			article.type = 'article';				
											
			if(this.state.search && this.state.keyword){
								
				if(article.title !== '' && article.title !== null && article.title.toLowerCase().includes(this.state.keyword.toLowerCase())){
					
					if(this.state.type !== 'collection' && this.state.type === 'article'){
						assets.push(article);
					}
					
					if(_.find(this.props.library.selected, { 'type': 'article', 'id': article.id })){
						
						if(this.state.type === 'selected'){
							assets.push(article);
						}
						
						++counter['selected'];
					}
					
					++counter['article'];
				}
				
			}else{
				
				if(this.state.type !== 'collection' && this.state.type === 'article'){
					assets.push(article);
				}
				
				if(_.find(this.props.library.selected, { 'type': 'article', 'id': article.id })){
						
					if(this.state.type === 'selected'){
						assets.push(article);
					}
					
					++counter['selected'];
				}
				
				++counter['article'];
			}

		}, this);
		
		
		_.forEach(this.props.products.products, (product, key) => {	
			
			product.type = 'product';				
											
			if(this.state.search && this.state.keyword){
				
				if(product.title !== '' && product.title !== null && product.title.toLowerCase().includes(this.state.keyword.toLowerCase())){
					
					if(this.state.type !== 'collection' && this.state.type === 'product'){
						assets.push(product);
					}
					
					if(_.find(this.props.library.selected, { 'type': 'product', 'id': product.id })){
						
						if(this.state.type === 'selected'){
							assets.push(product);
						}
						
						++counter['selected'];
					}
					
					++counter['product'];
				}
				
			}else{
				
				if(this.state.type !== 'collection' && this.state.type === 'product'){
					assets.push(product);
				}
				
				if(_.find(this.props.library.selected, { 'type': 'product', 'id': product.id })){
						
					if(this.state.type === 'selected'){
						assets.push(product);
					}
					
					++counter['selected'];
				}
				
				++counter['product'];
			}

		}, this);
					
		if(retType === 'counter'){
			return counter;
		}else{
			return assets;
		}
	}
	
	renderFiles(){
		
		let assets = [];
		let collections = false;
		
		if(this.state.type == 'collection'){
			
			collections = [];
			
			const collections_root = _.filter(this.props.assets.collections, { parent_id: this.state.collection ? this.state.collection : null });
			
			_.forEach(collections_root, (collection, key) => {					
									
				collection.sub = [];
				
				const organiseSub = (id) => {
					
					let collections = _.filter(this.props.assets.collections, { parent_id: id });
					
					_.forEach(collections, (child, key) => {
						collection.sub.push(child.name);
						collections[key].children = organiseSub(child.id);
					});
					
					return collections;
				}
				
				organiseSub(collection.id);
				
				_.forEach(this.props.assets.assets, (asset, key) => {					
											
					if(collection.assets.includes(asset.id)){
						
						if(this.state.keyword){
							
							if(asset.name !== '' && asset.name !== null && asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
								assets.push(asset);
							}else if(asset.tags){
								
								if(_.filter(this.cleanTags('asset', asset.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
									assets.push(asset);
									return;
								}
							}
							
						}else{
							assets.push(asset);
						}
					}
				});
				
				collection.counter = assets.length;
				collection.sub_counter = collection.sub.length;
				
				if(this.state.keyword){
									
					collection.sub_counter = _.filter(collection.sub, title => (title.toLowerCase().includes(this.state.keyword.toLowerCase()))).length;
					
					if(collection.counter > 0 || collection.sub_counter > 0){
						collections.push(collection);
					}else{
						
						if(collection.name !== '' && collection.name !== null && collection.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
							collections.push(collection);
						}else if(collection.tags){
							
							if(_.filter(this.cleanTags('collection', collection.tags), tag => (tag.toLowerCase().includes(this.state.keyword.toLowerCase()))).length > 0){
								collections.push(collection);
								return;
							}
						}
					}
					
								
				}else{
					collections.push(collection);
				}
	
			}, this);
						
			collections = _.orderBy(collections, ['created.date'],['desc']); 	
		}
		
		assets = this.filterData();		
					
		if((this.state.type === 'collection' && !this.state.collection) && _.isEmpty(collections) || (this.state.type !== 'collection' && _.isEmpty(assets))){
			
			let action_article = {
				label: i18n.t('library:action_add_article'),
				url: `${this.props.parentPath}/article?b=${generateBack(window.location.pathname + '?type=article', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			let action_product = {
				label: i18n.t('library:action_add_product'),
				url: `${this.props.parentPath}/product?b=${generateBack(window.location.pathname + '?type=product', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			let action_image = {
				label: i18n.t('library:action_add_image'),
				url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=image', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			let action_video = {
				label: i18n.t('library:action_add_video'),
				url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=video', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			let action_document = {
				label: i18n.t('library:action_add_document'),
				url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=document', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			switch(this.state.type){
				
				case 'article':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_article')}
							description={i18n.t('library:empty_description_article')}
							cta={action_article}
						/>
					);
					break;
					
				case 'product':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_product')}
							description={i18n.t('library:empty_description_product')}
							cta={action_product}
						/>
					);
					break;
					
				case 'image':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_image')}
							description={i18n.t('library:empty_description_image')}
							cta={action_image}
						/>
					);
					break;
					
				case 'video':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_video')}
							description={i18n.t('library:empty_description_video')}
							cta={action_video}
						/>
					);
					break;
					
				case 'document':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_document')}
							description={i18n.t('library:empty_description_document')}
							cta={action_document}
						/>
					);
					break;
					
				case 'collection':
					return (
							<EmptySection
								icon="fa-exclamation-circle"
								title={i18n.t('library:empty_title_collection')}
								description={i18n.t('library:empty_description_collection')}
							/>
						);
					break;
					
				default:
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_selected')}
							description={i18n.t('library:empty_description_selected')}
							cta={[
								this.renderButtonGroup('add'),
								this.renderButtonGroup('select')
							]}
						/>
					);
					break;
			}			
		}
		
		assets = _.orderBy(assets, ['created.date'],['desc']); 
				
		let collection = false;
		collections = false;
		
		if(this.state.type == 'collection'){
			
			if(this.state.collection){
				collection = this.props.assets.collections[this.state.collection];
			}
			
			collections = _.filter(this.props.assets.collections, { parent_id: this.state.collection ? this.state.collection : null });
		}
				
		return (
         	<div className="cta-push">
			 	<div className="c-card u-pb-zero">
		         	<div className="row assets u-pb-zero u-pt-zero">
		         	
		         		{collection && 
							<div className="col-sm-6 col-lg-4 col-xl-2">
								
								<a 
									href="javascript:;"
									onClick={() => { 
										this.setState({
											collection: collection.parent_id
										});
									}} 
									className="c-card c-card-collection is-animated u-text-center equalize"
								>
									<div className="u-mb-small directory">
										<i className="fal fa-folder-open"></i>
									</div>
									<div className="info">
										{collection.parent_id && 
											<React.Fragment>
												<h4>Up A Level</h4>
												<p>{this.props.assets.collections[collection.parent_id].name}</p>
											</React.Fragment>
										||
											<React.Fragment>
												<h4>Up A Level</h4>
												<p>All Collections</p>
											</React.Fragment>
										}
									</div>
								</a>					
				            </div>						
						}	
						
						{collections && 
							<React.Fragment>
								{_.map(collections, (child, key) => {
								    
									child.sub = [];
									child.counter = 0;
					
									const organiseSub = (id) => {
										
										let tmp = _.filter(this.props.assets.collections, { parent_id: id });
										
										_.forEach(tmp, (child2, key) => {
											child.sub.push(child2.name);
											tmp[key].children = organiseSub(child2.id);
										});
										
										return tmp;
									}
									
									organiseSub(child.id);
									
									_.forEach(this.props.assets.assets, (asset, key) => {					
												
										if(child.assets.includes(asset.id)){
											++child.counter;
										}
									});
									
									return(
										<div className="col-sm-6 col-lg-4 col-xl-2" key={key}>
											
											<a 
												href="javascript:;" 
												onClick={() => { 
													this.setState({
														collection: child.id
													}); 
												}} 
												className="c-card c-card-collection is-animated u-text-center equalize"
											>
												<div className="u-mb-small directory">
													<i className="fal fa-folder"></i>
												</div>
												<div className="info">
													<h4>{child.name}</h4>
													<p>
														<CountUp 
															delay={0} 
															duration={1} 
															end={child.counter}
															separator="," 
															preserveValue={true}
														/> {child.counter === 1 ? 'Asset' : 'Assets'}
														
														{child.sub.length > 0 && 
															<React.Fragment>
																{` / `}
																<CountUp 
																	delay={0} 
																	duration={1} 
																	end={child.sub.length}
																	separator="," 
																	preserveValue={true}
																/> {child.sub.length === 1 ? 'Collection' : 'Collections'}
															</React.Fragment>
														}
													</p>
												</div>
											</a>					
							            </div>
							        );
						        })}
						    </React.Fragment>
						}

						{_.map(assets, (asset, key) => {
							
							let assetFinal = _.clone(asset);	
							
							let type = 'asset';
							let section = 'video';
														
							if(asset.type === 'article'){
								type = 'article';
								section = type;
								assetFinal.thumbnail = asset.image ? asset.image : false;
							}else if(asset.type === 'product'){
								type = 'product';
								section = type;
								assetFinal.thumbnail = asset.image ? asset.image : false;
							}else if(asset.type === 'image'){
								section = 'image'
							}
							
							let selected = _.find(this.props.library.selected, { 'type': type, 'id': asset.id });							
							let headlineChange = false;
							
							switch(this.state.parent){
								
								case 'campaign':									
									/*console.log(this.props.library.selected);
									
									if(this.props.campaigns.campaigns[this.state.id].items.assets.includes(asset.id)){
										
										if(this.props.campaigns.campaigns[this.state.id].items.headline.includes(asset.id)){
											headline = 1; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
										}else{
											headline = 0; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
										}
									}
									*/
																	
									if(selected){
																														
										if(selected.headline && selected.headline === 1){
											assetFinal.headline = 1; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
										}else{
											assetFinal.headline = 0; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
										}
									}
									
									assetFinal.headlineSelection = 1;
																										
									headlineChange = () => {
										this.onSelectItem(type, asset.id, assetFinal.headline === 1 ? 0 : 1); 
									}
									break;
							}
			
							return (
								<AssetGridItem 
									key={key}
									asset={assetFinal}
									onSelectItem={() => { this.onSelectItem(type, assetFinal.id, selected ? undefined : assetFinal.headline) }}
									selected={selected}
									size="small"
									back={generateBack(window.location.pathname + '?type=' + section, 'library', window.location.pathname + '?type=' + this.state.type)}
									actions={['edit']}
									icon={selected ? 'fa-minus' : 'fa-plus'}
									headlineChange={headlineChange}
									{...this.props}
								/>						
							);
						})}
					</div>
				</div>
			</div>
		);		
	}
	
	renderCta(){
		
		let buttons = [];
		
		switch(this.state.type){
				
			case 'article':
				buttons.push({
					label: i18n.t('library:action_add_article'),
					url: `${this.props.parentPath}/article?b=${generateBack(window.location.pathname + '?type=article', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'product':
				buttons.push({
					label: i18n.t('library:action_add_product'),
					url: `${this.props.parentPath}/product?b=${generateBack(window.location.pathname + '?type=product', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'image':
				buttons.push({
					label: i18n.t('library:action_add_image'),
					url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=image', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'video':
				buttons.push({
					label: i18n.t('library:action_add_video'),
					url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=video', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'selected':
				buttons.push(this.renderButtonGroup('add', 'cta'));
				buttons.push(this.renderButtonGroup('select', 'cta'));				
				break;
				
			default:
				break;
		}
		
		return (
			<div id="cta">
				{_.map(buttons, (button, key) => {
					
					return (
						<Button
							key={key}
							{...button}
						/>
					)
				})}
			</div>
		);	
	}
	
	renderButtonGroup(type, cta){
		
		switch(type){
			
			case 'add':
				return({
					label: i18n.t('library:action_add_image'),
					url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=image', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					split: [
						{
							label: i18n.t('library:action_add_video'),
							url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=video', 'library', window.location.pathname + '?type=' + this.state.type)}`
						},
						{
							label: i18n.t('library:action_add_article'),
							url: `${this.props.parentPath}/article?b=${generateBack(window.location.pathname + '?type=article', 'library', window.location.pathname + '?type=' + this.state.type)}`
						},
						{
							label: i18n.t('library:action_add_product'),
							url: `${this.props.parentPath}/product?b=${generateBack(window.location.pathname + '?type=product', 'library', window.location.pathname + '?type=' + this.state.type)}`
						}
					],
					split_reverse: cta ? cta : false,
					outline: cta ? cta : false
				});
				break;
				
			case 'select':
				return ({
					label: i18n.t('library:action_select_image'),
					onClick: () => { this.onChangeType('image') },
					split: [
						{
							label: i18n.t('library:action_select_video'),
							onClick: () => { this.onChangeType('video') }
						},
						{
							label: i18n.t('library:action_select_article'),
							onClick: () => { this.onChangeType('article') }
						},
						{
							label: i18n.t('library:action_select_product'),
							onClick: () => { this.onChangeType('product') }
						}
					],
					split_reverse: cta ? cta : false,
					outline: cta ? cta : false
				});
				break;
				
			default:
			
				break;
		}
	}
	
	render() {
	
		if(!this.props.assets.assets){
			return (
				<Loading />
			);
		}
		
		const counter = this.filterData('counter');
		let buttons = [];
		
		console.log(counter)
				
		let tabs = [
			{
				label: i18n.t('library:toolbar_selected'),
				counter: counter.selected,
				onClick: () => { this.onChangeType('selected') },
				selected: this.state.type === 'selected' ? true : false
			},
			{
				label: i18n.t('library:toolbar_collection'),
				counter: counter.collection,
				onClick: () => { this.onChangeType('collection') },
				selected: this.state.type === 'collection' ? true : false
			},
			{
				label: i18n.t('library:toolbar_image'),
				counter: counter.image,
				onClick: () => { this.onChangeType('image') },
				selected: this.state.type === 'image' ? true : false
			},
			{
				label: i18n.t('library:toolbar_video'),
				counter: counter.video,
				onClick: () => { this.onChangeType('video') },
				selected: this.state.type === 'video' ? true : false
			},
			{
				label: i18n.t('library:toolbar_article'),
				counter: counter.article,
				onClick: () => { this.onChangeType('article') },
				selected: this.state.type === 'article' ? true : false
			},
			{
				label: i18n.t('library:toolbar_document'),
				counter: counter.document,
				onClick: () => { this.onChangeType('document') },
				selected: this.state.type === 'document' ? true : false
			},
			{
				label: i18n.t('library:toolbar_product'),
				counter: counter.product,
				onClick: () => { this.onChangeType('product') },
				selected: this.state.type === 'product' ? true : false
			}
		];
		
		switch(this.state.parent){
			
			case 'campaign':
				
				let label = 'campaigns:library_action_submit';
				
				if(_.isEmpty(this.props.library.selected)){
					label = 'campaigns:library_action_submit_empty';
				}
				
				buttons.push({
					label: i18n.t(label, { count: this.props.library.selected.length }),
					onClick: () => { 
						this.props.startSubmission(formName);

						this.props.saveCampaignItems(formName, this.state.id, this.props.library.selected, () => {
							this.props.history.push(this.props.parentPath);
						});							
					}
				});
				
			default:
			
				break;
		}
		
		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{this.state.title}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={tabs}
					buttons={buttons}
					filter={{
						toggleFunc: (status) => {
							this.setState({
								search: status
							});
						},
						onChangeFunc: (event) => { 
							
							this.setState({
								keyword: event.target.value
							})
						},
						fields: [
							{
								placeholder: i18n.t('library:filter_keyword'),
								name: "keyword",
								type: 'text',
								value: this.state.keyword ? this.state.keyword : ''
							}
						],
						open: this.state.search
					}}
				/>
				
				<DropzoneArea
					type="multiple"
					processFiles={(posting, percentIndicator, callback, callbackError) => {
	
						posting.append('collection_id', false);
						posting.append('source', 'local');

						this.props.saveAsset(false, false, posting, (percent) => {
							percentIndicator(percent);
						}, (assets) => {
							callback();
							this.props.makeSelection('asset', assets)
						}, () => { callbackError() });
					}}
				>	
					{this.renderFiles()}						
				</DropzoneArea>
					
				{this.renderCta()}
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets, overlay, library, campaigns, articles, products }, ownProps){
	return {
		assets,
		overlay,
		library,
		campaigns,
		articles,
		products
	};
}

export default connect(mapStateToProps, { 
	fetchAssetsList,
	fetchArticlesList,
	makeSelection,
	clearSelection,
	saveAsset,
	saveCampaignItems,
	startSubmission,
	fetchCampaignsList,
	fetchProductList,
	fetchCollectionsList
})(Library);