// REWORK i18n.t params
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';

import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';
import OverlaySettings from '../../components/chrome/overlay_settings';

import { checkPermission } from '../../helpers/permissions';
import { fetchSubscribersGroups, saveSubscribersGroup } from '../../actions/subscribers';
import SubscribersToolbar from './toolbar';

var formName = 'formSubscribersGroups';

class SubscribersGroup extends Component {

	constructor(props){
		super(props);
		
		if(!checkPermission(this.props.account.permissions, 'SUBSCRIBERS')){
			this.props.history.push('/');
		}
		
		const { id } = this.props.match.params;

		if(id){
				       				       	
			this.props.fetchSubscribersGroups(id, () => {
		       	
		       	if(this.props.subscribers.groups.local && !this.props.subscribers.groups.local[id]){
					this.props.history.push(this.props.parentPath);
				}else{
					this.updateDomElements();
				}
	       	});			
		}
		
		this.state = {
            id: id
        };	
        
        this.updateDomElements();	
	}
	
	updateDomElements(){
		
		if(this.props.subscribers.groups.local && this.state.id && this.props.subscribers.groups.local[this.state.id]){
		
			let group = this.props.subscribers.groups.local[this.state.id];			
			this.props.initialize(group);	
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		this.updateDomElements();	
	}
	
	onSubmit(values) {
			  				  	
	  	this.props.startSubmission(formName);
	  		  		  	
	  	this.props.saveSubscribersGroup(formName, this.state.id, values, (id) => {
			this.props.history.push(`${this.props.parentPath}/audience/${id}`);
			
			this.setState({ id: id });
			this.updateDomElements();
		});
	}
	
	renderPage(){
		
		let { subscribers } = this.props;
		
		if(!subscribers.groups.local || (this.state.id && !subscribers.groups.local[this.state.id])){
			return (
				<Loading />
			);
		}
		
		return (
			<div className="c-card">
													
				<Field
					label={i18n.t('subscribers:group_label_name')} 
					name="name"
					type="text"
					component={InputField}
				/>
			</div>
		);		
	}
	
	render() {
		let { subscribers } = this.props;
		let title = i18n.t('subscribers:title_add_group');
		let counter = 0;
		
		if(this.state.id && subscribers.groups && subscribers.groups.local && subscribers.groups.local[this.state.id]){
			title = subscribers.groups.local[this.state.id].name;
			counter = subscribers.groups.local[this.state.id].subscribers ? subscribers.groups.local[this.state.id].subscribers.length : 0;
		}
		
		const { handleSubmit } = this.props;
		
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<SubscribersToolbar
					formName={formName}
					section="settings"
					id={this.state.id}
					counter={counter}
					{...this.props}
				/>
				
				{this.renderPage()}	
				
			</form>
		);
	}
}


const validate = (values) => {	
	
	const errors = {};

	if (!values.name) {
		errors.name = 'Please enter a name!';
	}
	
	return errors;
}

function mapStateToProps({ account, subscribers }, ownProps){
	return {
		account,
		subscribers
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		fetchSubscribersGroups,
		saveSubscribersGroup
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(SubscribersGroup)
);