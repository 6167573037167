import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import EmptySection from '../../components/chrome/empty';
import { clearSelection } from '../../actions/library';
import Loading from '../../components/chrome/loading';
import CampaignsToolbar from './toolbar';
import { fetchCampaignsList, saveCampaignItems, toggleCampaignItemHeadline } from '../../actions/campaigns';
import { saveAsset, fetchAssetsList } from '../../actions/assets';
import { fetchArticlesList } from '../../actions/articles';
import { fetchProductList } from '../../actions/products';
import CountUp from 'react-countup';

import { setSectionOptions } from '../../actions/chrome';
import _ from 'lodash';
import AssetGridItem from '../../components/assets/grid_item';
import DropzoneArea from '../../components/forms/dropzone';
//import { DragDropContext } from 'react-beautiful-dnd';

import { checkPermission } from '../../helpers/permissions';

class CampaignsAssets extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT')){
			this.props.history.push('/');
		}
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/campaigns');
		}		
				
		this.state = {
			id: id,
			readonly: false,
			promo: {}
		}
		
		this.props.fetchCampaignsList(id, () => {
		       	
	       	if(!this.props.campaigns.campaigns[id]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements();
			}
       	});
    }
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
		this.updateDomElements();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	updateDomElements(){
				
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id]){
			
			const campaign = this.props.campaigns.campaigns[this.state.id];	
			
			this.props.setSectionOptions('campaigns', campaign.internal_name, `/campaigns/${this.state.id}`);
			let promo = {};
			
			if(campaign.items_restrictions && campaign.items_restrictions.headline && campaign.items_restrictions.headline.promo){
				promo = campaign.items_restrictions.headline.promo;
			}

			this.setState({
				readonly: campaign.status === 'draft' ? false : true,
				promo: promo
			});
			
			// LOAD IN THE ASSIGNED ITEMS
			if(campaign.items.assets && !_.isEmpty(campaign.items.assets)){
				this.props.fetchAssetsList(campaign.items.assets);
			}
			
			if(campaign.items.articles && !_.isEmpty(campaign.items.articles)){
				this.props.fetchArticlesList(campaign.items.articles);			}
			
			if(campaign.items.products && !_.isEmpty(campaign.items.products)){
				this.props.fetchProductList(campaign.items.products);
			}
							
		}else{
			this.props.setSectionOptions('campaigns', i18n.t('campaigns:assets_title'), `/campaigns/${this.state.id}`);
		}
	}
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 6;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
		
	openLibrary(type){
		
		let url = `${this.props.location.pathname}/library/campaign/${this.state.id}`;
		
		if(type){
			url += `?type=${type}`;
		}

		this.props.clearSelection(() => {
			this.props.history.push(url);
		});		
	}
	
	uploadFiles(posting, percentIndicator, headline, callback, callbackError){
					
		posting.append('collection_id', false);
		posting.append('source', 'local');
		posting.append('headline', headline);
		
		this.props.saveAsset(false, false, posting, (percent) => {
			percentIndicator(percent);
		}, (assets) => {
			
			let items = [];
			
			if(this.props.campaigns.campaigns[this.state.id].items.assets){
				
				_.forEach(this.props.campaigns.campaigns[this.state.id].items.assets, (asset, key) => {	
					items.push({ type: 'asset', id: asset });
				});
			}
			
			_.forEach(assets, (asset, key) => {	
				items.push({ type: 'asset', id: asset.id });

			});

			this.props.saveCampaignItems(false, this.state.id, items);
			callback();
		}, () => { callbackError() });
	}
	
	renderAssets(){
		
		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		let items_headline = [];
		let items_other = {
			secondary: [],
			videos: [],
			products: [],
			articles: [],
			documents: []
		};
		
		if(campaign.items){
									
			if(campaign.items.assets && !_.isEmpty(campaign.items.assets)){
				
				let item = false;
				
				_.forEach(campaign.items.assets, (id, key) => {	
					
					if(this.props.assets.assets && this.props.assets.assets[id]){
					
						item = _.clone(this.props.assets.assets[id]);
						item.group = 'asset';
		
						switch(item.type){
							
							case 'video':
							case 'youtube':
							case 'vimeo':
								items_other.videos.push(item);
								break;
								
							case 'pdf':
							case 'document':
							case 'presentation':
							case 'spreadsheet':
								items_other.documents.push(item);
								break;
								
							default:
								if(campaign.items.headline.includes(item.id)){
									items_headline.push(item);
								}else{
									items_other.secondary.push(item);
								}
								break;
						} 
					}
					
				}, this);
			}
			
			if(campaign.items.articles && !_.isEmpty(campaign.items.articles)){
				
				let item = false;
				
				_.forEach(campaign.items.articles, (id, key) => {	
					
					if(this.props.articles.articles && this.props.articles.articles[id]){
						item = _.clone(this.props.articles.articles[id]);
						item.type = 'article';
						items_other.articles.push(item);	
					}				
				}, this);
			}
			
			if(campaign.items.products && !_.isEmpty(campaign.items.products)){
				
				let item = false;
				
				_.forEach(campaign.items.products, (id, key) => {	
					
					if(this.props.products.products && this.props.products.products[id]){
						item = _.clone(this.props.products.products[id]);
						item.type = 'product';
						items_other.products.push(item);	
					}				
				}, this);
			}
		}
		
		if(items_headline.length > 0 || items_other.secondary.length > 0 || items_other.articles.length > 0 || items_other.products.length > 0){
									
			return (
				<div id="campaign_assets" className="row">
					<div className="col-xl-6 col-md-12">
						<div className={`hero c-card ${items_headline.length > 0 ? 'u-pb-zero' : ''}`}>
							<DropzoneArea
								type="multiple"
								processFiles={(posting, percentIndicator, callback, callbackError) => {
									this.uploadFiles(posting, percentIndicator, true, callback, callbackError);
								}}
								disabled={this.state.readonly ? true : false}
							>					
								<h4 className="u-mb-small">
									{i18n.t(`campaigns:assets_headline_title`)}
									<span className="c-badge c-badge--small badge-light u-ml-xsmall">
										<CountUp 
											delay={0} 
											duration={1} 
											end={items_headline.length}
											separator="," 
											preserveValue={true}
										/>
									</span>
									{!this.state.readonly && 
										<div className="options">
											<button
												className="c-btn c-btn--outline" 
												type="button"
												onClick={() => { this.openLibrary('image') }}
											>
												{i18n.t(`campaigns:settings_action_primary_select`)}
											</button>
										</div>
									}
								</h4>
								<div className={`row assets`}>
							
									{items_headline.length === 0 && 
										<div className="col col-sm-12">
											{i18n.t(`campaigns:assets_headline_empty`)}
										</div>
									}
										
									{items_headline.length > 0 && 
										
										<React.Fragment>
											{_.map(items_headline, (item, key) => {
												
												item.headline = 1; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
												
												if(this.state.readonly){
													item.headlineSelection = false;
												}else{
													item.headlineSelection = true;
												}
																								
												return (
													<AssetGridItem 
														key={key}
														asset={item}
														size="xlarge"
														actions={['edit']}
														headlineChange={() => {
															this.props.toggleCampaignItemHeadline(this.state.id, item.id, item.headline === 1 ? 0 : 1, () => {
																this.props.fetchCampaignsList(this.state.id);
															});
														}}
														animated={false}
														{...this.props}
													/>					
												);		
											})}
										</React.Fragment>
									}
								</div>
							</DropzoneArea>
						</div>
					</div>
					
					<div className="col-xl-6 col-md-12 other">
						
						{_.map(items_other, (items, key) => {
							
							let className = '';
							
							if(key === 'articles'){
								className = 'u-mb-zero';
							}
														
							return (
								<DropzoneArea
									key={key}
									type="multiple"
									processFiles={(posting, percentIndicator, callback, callbackError) => {
										this.uploadFiles(posting, percentIndicator, false, callback, callbackError);
									}}
									disabled={this.state.readonly ? true : false}
								>
									<div className={`c-card ${className} ${items.length > 0 ? 'u-pb-zero' : ''}`}>
										<h4 className="u-mb-small">
											{i18n.t(`campaigns:assets_${key}_title`)}
											<span className="c-badge c-badge--small badge-light u-ml-xsmall">
												<CountUp 
													delay={0} 
													duration={1} 
													end={items.length}
													separator="," 
													preserveValue={true}
												/>
											</span>
											{!this.state.readonly && 
												<div className="options">
													<button
														className="c-btn c-btn--outline" 
														type="button"
														onClick={() => { 
															
															let type = key;
															
															switch(key){
																case 'secondary':
																	type = 'image';
																	break;
																
																case 'videos':
																	type = 'video';
																	break;
																
																case 'articles':
																	type = 'article';
																	break;
																	
																case 'products':
																	type = 'product';
																	break;
																	
																case 'documents':
																	type = 'document';
																	break;
																
																default:
																	
																	break;
															}
															
															this.openLibrary(type) 
														
														}}
													>
														{i18n.t(`campaigns:settings_action_${key}_select`)}
													</button>
												</div>
											}
										</h4>
										<div className="row u-pb-zero assets">
																	
											{items.length === 0 && 
												<div className="col-xl-12">
													{i18n.t(`campaigns:assets_${key}_empty`)}
												</div>
											}
												
											{items.length > 0 && 
												
												<React.Fragment>
													{_.map(items, (item, key2) => {
														
														item.headline = 0; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
														
														if(this.state.readonly){
															item.headlineSelection = false;
														}else{
															item.headlineSelection = true;
														}
																																										
														return (
															<AssetGridItem 
																key={key2}
																asset={item}
																size="large"
																actions={['edit']}
																headlineChange={() => {
																	this.props.toggleCampaignItemHeadline(this.state.id, item.id, item.headline === 1 ? 0 : 1, () => {
																		this.props.fetchCampaignsList(this.state.id);
																	});
																}}
																animated={false}
																{...this.props}
															/>					
														);		
														
													})}
												</React.Fragment>
											}
										</div>
									</div>
								</DropzoneArea>
							)
						})}
						
						
						
					</div>
				</div>
			);
			
		}else{
			
			let cta = false;
			
			if(!this.state.readonly){
				cta = {
					label: i18n.t('campaigns:assets_action_select'),
					onClick: this.openLibrary.bind(this, false)
				}
			}
			
			return (
				<DropzoneArea
					type="multiple"
					processFiles={(posting, percentIndicator, callback, callbackError) => {
						this.uploadFiles(posting, percentIndicator, false, callback, callbackError);
					}}
					disabled={this.state.readonly ? true : false}
				>
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t('campaigns:assets_empty_title')}
						description={i18n.t('campaigns:assets_empty_description')}
						cta={cta}
					/>
				</DropzoneArea>
			);
		}
	}
	
	
	render() {
		
		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let campaign = this.props.campaigns.campaigns[this.state.id];
		
		return (

			<React.Fragment>
				<CampaignsToolbar
					id={this.state.id}
					section="assets"
					readonly={this.state.readonly}
					continue={true}
					onClick={{
						select: this.openLibrary.bind(this, false)
					}}
					campaign={campaign}
					{...this.props}
				/>
				
				<div className="container">
					{this.renderAssets()}
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ campaigns, assets, account, articles, products }, ownProps){
	return {
		campaigns,
		assets,
		account,
		articles,
		products
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchCampaignsList,
	clearSelection,
	fetchAssetsList,
	saveAsset,
	saveCampaignItems,
	fetchArticlesList,
	fetchProductList,
	toggleCampaignItemHeadline
})(CampaignsAssets);