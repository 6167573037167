import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Submit from '../../components/forms/submit';
import ReactTooltip from 'react-tooltip';

class Button extends React.Component {
	
	constructor(props) {
        super(props);
        
        this.state = {
	        toggleOver: false,
	        subOver: false,
            subOpen: false
        };
    }
    
    elmOver = (type, status) => {
        this.setState(state => ({ [type]: status }));
    };
    
    render() {
		
		let className = `c-btn u-mr-xsmall c-btn--info ${this.props.className ? this.props.className : ''}`;
									
		if(this.props.outline){
			className += ` c-btn--outline`;
		}
		
		if(this.props.submit){
			
			let params = {};
			
			if(this.props.onClick){
				params.onClick = this.props.onClick;
			}
			
			return (
				<Submit
					{...params}
					form={this.props.submit}
					className={className}
					label={this.props.label}
					proceed={this.props.proceed ? true : false}
				/>
			);
		}
		
		let button = '';
		let icon = '';
		
		if(this.props.icon){
			icon = (
				<i className={`fal ${this.props.icon} ${this.props.label ? 'icon-padding-right' : ''}`}></i>
			);
		}
		
		if(this.props.url){
			
			button = (
		
				<Link 
					className={className}
					to={this.props.url}
					data-tip={this.props.tooltip ? this.props.tooltip : null}
				>
					{icon} {this.props.label}
				</Link>
			);
	
		}else if(this.props.href){
			
			button = (
		
				<a 
					className={className}
					href={this.props.href}
					data-tip={this.props.tooltip ? this.props.tooltip : null}
					target="_blank"
				>
					{icon} {this.props.label}
				</a>
			);
	
		}else if(this.props.onClick){
			
			button = (
		
				<button 
					className={className}
					onClick={this.props.onClick}
					type="button"
					data-tip={this.props.tooltip ? this.props.tooltip : null}
				>
					{icon} {this.props.label}
				</button>
			);
		}
		
		if(this.props.tooltip){
			
			button = (
				<React.Fragment>
					{button}
					<ReactTooltip 
						effect="solid"
					/>
				</React.Fragment>
			);
		}
		
		if(this.props.split){
			
			return (
				<div className={`btn-split ${this.props.split_reverse ? 'btn-split-reverse' : ''} ${(this.state.toggleOver || this.state.subOver) ? 'open' : ''}`}>
					{button}
					
					<span 
						className="toggle" 
						onMouseEnter={() => { this.elmOver('toggleOver', true) }} 
						onMouseLeave={() => { this.elmOver('toggleOver', false) }} 
						onClick={() => { if(this.state.toggleOver){ this.elmOver('toggleOver', false); }else{ this.elmOver('toggleOver', true); } }} 
					/>

					<ul onMouseEnter={() => { this.elmOver('subOver', true) }} onMouseLeave={() => { this.elmOver('subOver', false) }}>
						{_.map(this.props.split, (option, key) => {
							
							let link = '';
							
							if(option.url){
			
								link = (
							
									<Link to={option.url}>
										{option.label}
									</Link>
								);
						
							}else if(option.onClick){
								
								link = (
							
									<button 
										onClick={option.onClick}
										type="button"
									>
										{option.label}
									</button>
								);
							}
							
							return (
								<li key={key}>
									{link}
								</li>	
							);
						})}
					</ul>
				</div>
			);
			
		}else {
			return button
		}
	}
}

export default Button;