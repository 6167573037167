import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 
import { generateBack } from '../../helpers/back';
import { downloadAsset } from '../../actions/assets';
import { forceDownload } from '../../helpers/download'; 

class CollectionsToolbar extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			downloading: false
		}
	}
	
	render() {

		let tabs = [];
		let buttons = [];
		let filter = false;

		if(this.props.id){
			
			if(!this.props.readonly){
				tabs.push({
					label: i18n.t('assets:collections_assets_toolbar_settings'),
					url: `${this.props.parentPath}/collection/${this.props.id}/edit`,
					selected: this.props.section === 'settings' ? true : false
				});
			}
				
			tabs.push({
				label: i18n.t('assets:collections_assets_toolbar_files'),
				url: `${this.props.parentPath}/collection/${this.props.id}`,
				selected: this.props.section === 'files' ? true : false
			});
			
		}else{
			
			tabs = [{
				label: i18n.t('assets:collections_assets_toolbar_settings'),
				url: `${this.props.parentPath}/collection/add`,
				selected: this.props.section === 'settings' ? true : false
			}];
		}

		switch(this.props.section){
				
			case 'settings':
				buttons.push({
					label: i18n.t('assets:collections_add_submit'),
					submit: this.props.formName
				});	
				break;
				
			case 'files':
				if(this.props.selected.length > 0){
					buttons.push({
						icon: 'fa-download',
						className: this.state.downloading ? 'processing' : false,
						onClick: () => {
							this.setState({ downloading: true });
							
							this.props.downloadAsset(this.props.selected.join(','), (url, filename) => {
								forceDownload(url, filename);
								this.setState({ downloading: false });
							});						
						},
						outline: true
					});
				}
				
				if(!this.props.readonly){
					buttons.push({
						label: i18n.t('assets:action_add_sub_collection'),
						url: `${this.props.parentPath}/collection/add?parent=${this.props.id}&b=${generateBack(window.location.pathname)}`
					});
					
					buttons.push({
						label: i18n.t('assets:action_add_submit'),
						url: `${this.props.parentPath}/asset?collection=${this.props.id}&b=${generateBack(window.location.pathname)}`
					});
				}
				
				/*filter={{
					toggleFunc: (status) => {
						this.setState({
							search: status
						});
					},
					onChangeFunc: (event) => { 
						
						this.setState({
							keyword: event.target.value
						})
					},
					fields: [
						{
							placeholder: i18n.t('keyword'),
							name: "keyword",
							type: 'text',
							value: this.state.keyword ? this.state.keyword : ''
						}
					],
					open: this.state.search
				}}*/
				break;
				
			default:
			
				break;			
		}

		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}

function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 
	downloadAsset
})(CollectionsToolbar);