import { billingConstants } from '../constants/billing';

export default function(state = {}, action) {
	
	switch (action.type) {
		      
		case billingConstants.INVOICES:
		
			return { 
				...state, 
				invoices: action.payload.data
			}
			
		case billingConstants.MONTHLY_USAGE:
		
			let monthly = state.monthly ? state.monthly : {};

			monthly[action.month] = action.payload.data;
		
			return { 
				...state, 
				monthly: monthly
			}							
							
		case billingConstants.BILLING_OVERVIEW:
		
			return { 
				...state, 
				overview: action.payload.data
			}					
									
		default:
			return state;
	}
}