import React from 'react';

const SoonSection = ({ title, icon, description }) => {

	return (
		<div className="soon-section">
            <i className={` u-mb-small fal ${icon}`}></i>
			<h3 className="u-mb-xsmall">{title}</h3>
			<p>{description}</p>
		</div>
	);
}

export default SoonSection;