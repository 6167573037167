import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import ReactTooltip from 'react-tooltip';
import { imageUrl } from '../../helpers/s3';
import { Link } from 'react-router-dom';
import Toolbar from '../../components/chrome/toolbar';

import { saveArticle, fetchArticlesList } from '../../actions/articles';
import moment from 'moment-timezone';
import 'moment/min/locales';
import { checkPermission } from '../../helpers/permissions';
import { makeSelection } from '../../actions/library';
import Editor from 'react-medium-editor';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

import * as Doka from '../../packages/react-doka/lib/doka.esm.min';
import '../../packages/react-doka/lib/doka.min.css';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit);

var slugify = require('slugify')

var formName = 'formArticlesEdit';

class ArticlesEdit extends Component {

	constructor(props){
		super(props);
		moment.locale(i18n.language);
		
		this.props.stopSubmission(formName);	
		
		const { id } = this.props.match.params;
		let publishDate = new Date();

		if(id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'ARTICLES_EDIT')){
				this.props.history.push('/');
			}
			
			this.state = {
	            id: id,
	            publishDate: publishDate,
	            url: false,
	            files: [],
	            files_changed: false,
	            status: 0,
	            content: '',
	            publish: 'campaign'
	        };
	       				       	
			this.props.fetchArticlesList(id, () => {
		       	
		       	if(this.props.articles.articles && !this.props.articles.articles[id]){
					this.props.history.push(this.props.parentPath);
				}else{
					this.updateDomElements();
				}
	       	});
			
			this.updateDomElements();
			
		}else{
					
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'ARTICLES_ADD')){
				this.props.history.push('/');
			}
			
			this.state = {
				id: false, 
				publishDate: publishDate,
				url: false,
				files: [],
	            files_changed: false,
	            content: '',
	            publish: 'campaign'
	        };
	        
	        this.props.change('publish', publishDate);	        
		}
	}
	
	onInputChangeStatus(selected) {
	    this.setState({ status: selected.value });
	}
	
	onInputChangePublish(selected) {
	    this.setState({ publish: selected.value });
	}
	
	updateDomElements(){
		
		if(this.props.articles.articles && this.state.id){
		
			let article = this.props.articles.articles[this.state.id];
			let files = [];
				
			if(article.image){
			
				files = [
					{
						source: imageUrl(article.image),
						options: {
			              	type: 'local'
			            }
					}
				];
			}	
									
			this.setState({
				files: files,
				status: article.status,
				title: article.title,
				content: article.content,
				publish: article.publish ? 'date' : 'campaign',
				publishDate: article.publish ? moment.utc(article.publish.date).tz(this.props.account.profile.timezone) : false
			});	
						
			this.props.initialize(article);
		}
	}

	onSubmit(values) {
				
	  	this.props.startSubmission(formName);
	  	
	  	let posting = _.clone(values);
	  	
	  	if(this.state.publish === 'date'){
		  	posting.publish = moment(this.state.publishDate).format('YYYY-MM-DD hh:mm');
	  	}else{
		  	posting.publish = 'null';
	  	}
	  	
		posting.status = posting.status.value;
		posting.content = this.state.content;
		
		if(posting.image){
			delete(posting.image);
		}

		if(this.state.files_changed){
			
			let files = _.map(this.pond.getFiles(), (file, key) => {
			  	return {
				  	filename: file.filename,
				  	encoded: file.getFileEncodeBase64String()
				}
			});
						
			if(files.length > 0){
				posting.image = files[0].encoded;
			}else {
				posting.image = 'none';
			}
		}
				  	
	  	this.props.saveArticle(formName, this.state.id, posting, (articles) => {
			
			if(this.props.overlay.back){
				  	
			  	switch(this.props.overlay.back.action){
				  	
			  		case 'library':				  			
			  			this.props.makeSelection('article', articles)
			  			this.props.history.push(this.props.overlay.back.url);
			  			break;
			  			
			  		default:
			  			this.props.history.push(this.props.overlay.back.url);	
			  			break;
		  		}
			  	
		  	}else{
			  	this.props.history.push(this.props.parentPath);	
		  	}
		});
	}
	
	onInputDateChange(date){

		this.setState({
			publishDate: date
		});
	}
	
	onTitleBlur(event){

		this.setState({
			title: event.target.value
		}, () => {
			if(!this.state.url){
				this.createUrl();
			}
		});		
	}

	onUrlChange(value){
		
		this.setState({
			url: value.target.value
		});
	}
	
	createUrl(){

		if(this.state.title !== ''){
			
			let url = slugify(this.state.title).toLowerCase();
			
			this.setState({
				url: url
			})
			
			this.props.change('url', url);
		}
	}

	render() {

		if(this.state.id && (!this.props.articles.articles || !this.props.articles.articles[this.state.id])){
			return (
				<Loading />
			);
		}
		
		const { handleSubmit } = this.props;
		let title = i18n.t('articles:article_add_title');
		let action = i18n.t('articles:article_add_submit');
		let article = false;
		let publishText = 'The publish date will automatically be calculated once this article is assigned to a campaign.';
		
		if(this.state.id){
			article = this.props.articles.articles[this.state.id];
			title = article.title;
			action = i18n.t('articles:article_edit_submit');
			
			if(article.publish_campaign){

				if(article.publish_campaign.status === 'draft'){
					publishText = (
						<React.Fragment>
							This article will be published when the draft campaign <Link to={`/campaigns/${article.publish_campaign.id}`}>'{article.publish_campaign.internal_name}'</Link> is launched, which is currently set as {moment(article.publish_campaign.date_start.date).format('LL - HH:mm')}.
						</React.Fragment>									
					);
				}else{
					
					if(moment(article.publish_campaign.date_start.date) < moment()){
						publishText = (
							<React.Fragment>
								This article was published with campaign <Link to={`/campaigns/${article.publish_campaign.id}`}>'{article.publish_campaign.internal_name}'</Link> on {moment(article.publish_campaign.date_start.date).format('LL - HH:mm')}
							</React.Fragment>									
						);
					}else{
						publishText = (
							<React.Fragment>
								This article will be published when campaign <Link to={`/campaigns/${article.publish_campaign.id}`}>'{article.publish_campaign.internal_name}'</Link> starts on {moment(article.publish_campaign.date_start.date).format('LL - HH:mm')}
							</React.Fragment>									
						);
					}
				} 
			}			
		}
		
		let labelIcon = '';
		
		if(this.state.title){
			labelIcon = (
				<React.Fragment>
					<i className="fal fa-redo" onClick={this.createUrl.bind(this)} data-tip={i18n.t('articles:article_label_url_regenerate')}></i>
					<ReactTooltip 
						effect="solid"
					/>
				</React.Fragment>				
			);
		}		
	
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>	
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('articles:article_toolbar_settings'),
						url: `${this.props.parentPath}/article${this.state.id ? '/' + this.state.id : ''}`,
						selected: true
					}]}
					buttons={[{
						label: action,
						submit: formName
					}]}
				/>
				
				{article && article.readonly === 1 && 
					<div className="c-card c-alert u-mb-medium c-alert--danger">
		                <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
							<i className="fal fa-inbox-in" style={{ color: '#FFF' }}></i>
						</span>
					
						<div className="c-alert__content">
							<h4 className="c-alert__title">Received article</h4>
							<p className="u-mb-small">This article was created for you by {this.props.brand.details.name}. You can edit this article but in doing so, all future updates from {this.props.brand.details.name} will not be reflected for this article.</p>
						</div>
					</div>
				}			

				<div className="row">
					<div className="col-xl-4 col-md-12">
						<div className="c-card">
						
							<h4 className="u-mb-medium">{i18n.t('articles:article_general_title')}</h4>
							
							<Field
								label={i18n.t('articles:article_label_status')} 
								name="status"
								type="suggest"
								options={[
									{
										value: 0,
										label: i18n.t('articles:article_label_status_draft')
									},
									{
										value: 2,
										label: i18n.t('articles:article_label_status_approval')
									},
									{
										value: 1,
										label: i18n.t('articles:article_label_status_approved')
									}
								]}
								selected={this.state.status}
								onChangeFunc={this.onInputChangeStatus.bind(this)}
								component={InputField}
							/>
							
							<Field
								label={i18n.t('articles:article_label_title')} 
								name="title"
								type="text"
								onBlurFunc={this.onTitleBlur.bind(this)}
								component={InputField}
							/>
							
							<Field
								label={i18n.t('articles:article_label_url')} 
								labelIcon={labelIcon}
								name="url"
								type="text"
								onChangeFunc={this.onUrlChange.bind(this)}
								component={InputField}
							/>
							
							<Field
								label={i18n.t('articles:article_label_publish')} 
								name="publish_type"
								type="suggest"
								options={[
									{
										value: 'campaign',
										label: i18n.t('articles:article_label_publish_campaign')
									},
									{
										value: 'date',
										label: i18n.t('articles:article_label_publish_date')
									}
								]}
								selected={this.state.publish}
								onChangeFunc={this.onInputChangePublish.bind(this)}
								component={InputField}
							/>
							
							
							{this.state.publish === 'campaign' && 
								
								<React.Fragment>
									<label className="c-field__label">{i18n.t('articles:article_label_publishdate')} </label>
									<div className="field u-mb-small">
										{publishText}
									</div>
								</React.Fragment>
							}
							
							{this.state.publish === 'date' && 
								<Field
									name="publish"
									label={i18n.t('articles:article_label_publishdate')} 
									type="datepicker"
									selected={this.state.publishDate}
									onChangeFunc={this.onInputDateChange.bind(this)}
									timepicker={true}
									component={InputField}
								/>
							}
							
							<Field
								label={i18n.t('articles:article_label_summary')} 
								name="summary"
								type="textarea-auto"
								component={InputField}
							/>	
							
							<label className="c-field__label">
								{i18n.t('articles:article_label_thumbnail')} 
							</label>
							<div className="filepond-container minimal">
								<FilePond 
									maxFiles="1"
									ref={ref => this.pond = ref}
									acceptedFileTypes="image/*"
									allowFileEncode={true}
									itemInsertLocation="after"
			                      	labelIdle={i18n.t('filepond_idle', { count: this.state.maxFiles })}
			                      	labelFileLoading={i18n.t('filepond_fileLoading')}
			                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
			                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
			                      	files={this.state.files}
			                      	server={{
										process: null,
										load: (source, load, error, progress, abort, headers) => {
											
											fetch(source)
												.then((res) => {
										            return res.blob();
										        }).then((res) => {
											      	load(res);
										        });
										}
									}}
									onupdatefiles={(files) => {

										if(files.length > 0){

											let fileItems = files.map(fileItem => fileItem.file);

											if(files[0].origin !== 3){ // NOT LOADED ON INIT
												this.setState({
													files: fileItems,
													files_changed: true
												});
											}
											
										}else{
											this.setState({
												files: [],
												files_changed: true
											});
										}
									}}
									imageEditEditor={Doka.create({
										onconfirm: (output) => {
											this.setState({
												files_changed: true
											});
										}
									})}
								/>
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-md-12">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('articles:article_content_title')}</h4>
							
							<link href="//cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/css/medium-editor.min.css" type="text/css" media="all" rel="stylesheet" />
							<link href="//cdnjs.cloudflare.com/ajax/libs/medium-editor/5.23.3/css/themes/default.min.css" type="text/css" media="all" rel="stylesheet" />
							<Editor 
								text={this.state.content} 
								className="outlined c-input"
								options={{ 
									placeholder: {
										text: ''
									},
									toolbar: { 
										buttons: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'h4'] 
									}
								}}
								onChange={( text, medium) => {
									
									this.setState({
										content: text
									})														
								}}
							/>
						</div>
					</div>
				</div>
			</form>				
		);
	}
}

const validate = (values) => {	
	
	const errors = {};
	
	if (!values.status) {
		errors.status = 'Enter the article status!';
	}
	
	if (!values.title) {
		errors.title = 'Enter the article title!';
	}
	
	if (!values.summary) {
		errors.summary = 'Enter the article summary!';
	}
	
	if (!values.url) {
		errors.url = 'Enter the article url!';
	}

	if (!values.publish) {
		errors.publish = 'Enter a publish date!';
	}
	
	if (!values.content) {
		errors.content = 'Enter the article content!';
	}
	
	return errors;
}

function mapStateToProps({ articles, account, overlay, brand }, ownProps){
	return {
		articles,
		account,
		overlay,
		brand
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		saveArticle,
		fetchArticlesList,
		makeSelection
	} )(ArticlesEdit)
);