import { trackingConstants } from '../constants/tracking';

export function trackOpen(url){
	
	return {
		type: trackingConstants.OPEN,
		payload: url
	};
}

export function trackPwa(){
	
	return {
		type: trackingConstants.PWA,
		payload: true
	};
}