import { formsConstants } from '../constants/forms';

const defaultState = {
	submitting: false
};

export default function(state = defaultState, action) {
		
	let submitting = {};
	
	switch (action.type) {
			
		case formsConstants.SUBMITSTART:

			return { 
				...state, 
				submitting: action.data
			}
			
		case formsConstants.SUBMITSTOP:
									
			if(state.submitting){
				
				if(state.submitting[action.data]){
					submitting = state.submitting;
					delete submitting[action.data];
				}
			}		
			
			return { 
				...state, 
				submitting: action.data
			}
			
		case formsConstants.SUBMITPROCEED:
		
			return { 
				...state, 
				proceed: action.data
			}	
					      
		default:
			return state;
	}
}


