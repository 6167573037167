import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import CopyCode from '../../chrome/copy_code';
import { showNotification } from '../../../actions/notifications';
import InputField from '../../forms/field.js';
import _ from 'lodash';

class IntegrationsAdvert extends Component {

	constructor(props) {
	    super(props);

		this.state = {
	       	size: 0,
	       	sizes: [
		       	{
			       	name: 'Square',
			       	width: 250,
			       	height: 250
		       	},
		       	{
			       	name: 'Small Square',
			       	width: 200,
			       	height: 200
		       	},
		       	{
			       	name: 'Banner',
			       	width: 468,
			       	height: 60
		       	},
		       	{
			       	name: 'Leaderboard',
			       	width: 728,
			       	height: 90
		       	},
		       	{
			       	name: 'Inline Rectangle',
			       	width: 300,
			       	height: 250
		       	},
		       	{
			       	name: 'Large Rectangle',
			       	width: 336,
			       	height: 280
		       	},
		       	{
			       	name: 'Skyscraper',
			       	width: 120,
			       	height: 600
		       	},
		       	{
			       	name: 'Wide Skyscraper',
			       	width: 160,
			       	height: 600
		       	},
		       	{
			       	name: 'Half-Page Ad',
			       	width: 300,
			       	height: 600
		       	},
		       	{
			       	name: 'Large Leaderboard',
			       	width: 970,
			       	height: 90
		       	},
		       	{
			       	name: 'Custom',
			       	width: false,
			       	height: false
		       	}
	       	],
	       	sizeCustom: {
		       	width: 250,
		       	height: 250
	       	}
       	}
    }
	
	onInputSizeChange(selected){

		let width = 250;
		let height = 250;
		
		if(this.state.sizes[selected.value].width){
			width = this.state.sizes[selected.value].width;
			height = this.state.sizes[selected.value].height;
		}else if(this.state.sizes[this.state.size].width){
			width = this.state.sizes[this.state.size].width;
			height = this.state.sizes[this.state.size].height;
		}
		
		this.setState({
			sizeCustom: {
				width: width,
				height: height,
			}
		});
		
		this.setState({
			size: selected.value,
		});
	}
	
	onInputCustomChange(type, event){
		
		let sizes = this.state.sizeCustom;
		
		sizes[type] = event.target.value;
		
		this.setState({
			sizeCustom: sizes
		});
	}
	
	render() {		
		
		let hub = this.props.retailer.domains.hub;
		
		if(this.props.retailer.domains.custom){
		
			if(this.props.retailer.domains.custom.connected){
				hub = this.props.retailer.domains.custom.domain;
			}			
		}	
		
		
		let width = false;
		let height = false;
		
		if(this.state.sizes[this.state.size].width){
			width = this.state.sizes[this.state.size].width;
			height = this.state.sizes[this.state.size].height;
		}else{
						
			if(this.state.sizeCustom.width){
				width = this.state.sizeCustom.width;
			}
			
			if(this.state.sizeCustom.height){
				height = this.state.sizeCustom.height;
			}
		}
		
		
		return (
			<ol className="numbererd">
				<li>
					Select the size of advert you would like to display:
					
					<InputField
						name="size"
						className="u-mt-small u-mb-small"
						type="suggest"
						selected={this.state.size}
						options={_.map(this.state.sizes, (size, key) => {
							
							let label = size.name;
							
							if(size.width && size.height){
								label = `${label} (${size.width} x ${size.height})`;
							}
							
							return ({
								label: label,
								value: key
							});
						})}
						onChangeFunc={this.onInputSizeChange.bind(this)}
					/>
					
					{!this.state.sizes[this.state.size].width &&
						
						<div className="row">
							<div className="col-sm-5">
								<InputField
									placeholder="Width"
									append="pixels"
									name="width"
									type="number"
									value={width}
									onChangeFunc={(e) => { this.onInputCustomChange('width', e) }}
								/>
							</div>
							<div className="col-sm-2" style={{ textAlign: 'center', lineHeight: '40px' }}>
								x
							</div>
							<div className="col-sm-5">
								<InputField
									placeholder="Height"
									append="pixels"
									name="height"
									type="number"
									value={width}
									onChangeFunc={(e) => { this.onInputCustomChange('height', e) }}
								/>
							</div>
						</div>
					}
				</li>
				<li>
					Copy your embed code below:
					
					<CopyCode
						className="u-mt-small u-mb-small"
						value={`<iframe src="https://${hub}/advert"${width ? ' width="' + width + '"' : ''}${height ? ' height="' + height + '"' : ''} style="border:none"></iframe>`}
						onCopy={() => {
							this.props.showNotification(
								'success',
								i18n.t('retailers:notification_integration_advert_url_copied_title'),
								i18n.t('retailers:notification_integration_advert_url_copied_description')
							);	
						}}
					/>
					
				</li>
				<li>Paste this through the website where you would like the advert to appear.</li>
			</ol>
		);
	}
}

export default connect(null, { 
	showNotification,
})(IntegrationsAdvert);