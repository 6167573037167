import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import i18n from '../../helpers/i18n'; 

export default class DropzoneArea extends Component {
	
	constructor(props){
		super(props);

		this.state = {
			files: [],
			uploadStatus: false,
			uploadIcon: false
		}
	}
		
	render(){
		
		if(this.props.disabled){
			
			return(
				<React.Fragment>
					{this.props.children}
				</React.Fragment>
			);
		}
		
		let label = '';
		
		if(this.state.uploadStatus !== 'dropzone_hovering'){
			
			if(this.state.uploadStatus === 'dropzone_uploading'){
				label = i18n.t('dropzone_starting');
			}else if(this.state.uploadStatus === '100%'){
				label = i18n.t('dropzone_processing');
			}else{
				label = i18n.t('dropzone_uploading', { percent: this.state.uploadStatus });
			}				
		}
		
		return (
			<Dropzone 
				onDropAccepted={files => {
					
					this.setState({
						uploadStatus: this.props.statusUploadText ? this.props.statusUploadText : 'dropzone_uploading',
						uploadIcon: this.props.statusUploadIcon ? this.props.statusUploadIcon : 'fa-spinner-third fa-spin'
					});
					
					let reader = [];
					
					const posting = new FormData();
					
					files.forEach((file, index) => {
						
						posting.append( 
							'files[]', 
							file,
							file.name
						);
					});
					
					this.props.processFiles(
			        	posting,
			        	(percent) => {
				        	this.setState({
								uploadStatus: `${percent}%`
							});
			        	},
						() => {
							this.setState({
								uploadStatus: false
							});
						},
						() => {
							this.setState({
								uploadStatus: false
							});
						}
			        );
				}}
				onDragEnter={() => {
					this.setState({
						uploadStatus: this.props.statusDragText ? this.props.statusDragText : 'dropzone_hovering',
						uploadIcon: this.props.statusDragIcon ? this.props.statusDragIcon : 'fa-plus'
					});
				}}
				onDragLeave={() => {
					this.setState({
						uploadStatus: false
					});
				}}
				onDropRejected={() => {
					this.setState({
						uploadStatus: false
					});
				}}
				noClick={true}
			>
				{({getRootProps, getInputProps}) => (
					<div {...getRootProps()} className={`dropzone ${this.state.uploadStatus}`}>
						
						<input {...getInputProps()} />
						
						{this.state.uploadStatus &&
							<div className={`status ${this.props.border ? 'border' : null}`}>
								<div>
									<i className={`fal ${this.state.uploadIcon}`}></i>
									{label}
								</div>								
							</div>
						}
							
						{this.props.children}				
					</div>
				)}
			</Dropzone>
		);
	}
}