import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import i18n from '../../helpers/i18n'; 
import { checkPermission } from '../../helpers/permissions';

class Nav extends Component {

	getNav() {
		
		let permissions = this.props.account.permissions;
		let options = ['dashboard'];
		
		if(checkPermission(permissions, 'CALENDAR')){
			options.push('calendar');
		}
		
		if(checkPermission(permissions, 'CAMPAIGNS')){
			options.push('campaigns');
		}
		
		if(checkPermission(permissions, 'ASSETS')){
			options.push('assets');
		}
		
		if(checkPermission(permissions, 'ARTICLES')){
			options.push('articles');
		}
		
		if(checkPermission(permissions, 'REGIONAL')){
			options.push('regional');
		}
		
		if(checkPermission(permissions, 'RETAILERS')){
			options.push('retailers');
		}
				
		if(checkPermission(permissions, 'PRODUCTS')){
			options.push('products');
		}
		
		if(checkPermission(permissions, 'SUBSCRIBERS')){
			options.push('subscribers');
		}
		
		if(checkPermission(permissions, 'ANALYTICS')){
			options.push('analytics');
		}
		
		return options;
	}
	
	createNavLink(key, title, url, icon, blank){

		let className = '';
		
		if(key === this.props.section){
			className = 'is-active';
		}
		
		if(blank){
			return (
				<li key={key}>
					<a href={url} className={`c-sidebar__link ${className}`} target="_blank" rel="noopener noreferrer" onClick={this.props.closeNav}>
						<i className={`c-sidebar__icon fal fa-${icon}`}></i>
						{title}
					</a>
				</li>
			)
		}else{
			return (
				<li key={key}>
					<Link to={url} className={`c-sidebar__link ${className}`} onClick={this.props.closeNav}>
						<i className={`c-sidebar__icon fal fa-${icon}`}></i>
						{title}
					</Link>
				</li>
			)
		}		
	}

	renderNav() {

		let options = this.getNav();

		if(options){

			let list = options.map(option => {

				switch(option){

					case 'dashboard':
						return this.createNavLink(option, i18n.t('nav_dashboard'), '/', 'home');
						
					case 'campaigns':
						return this.createNavLink(option, i18n.t('nav_campaigns'), '/campaigns', 'hashtag');

					case 'assets':
						return this.createNavLink(option, i18n.t('nav_assets'), '/assets', 'images');
						
					case 'calendar':
						return this.createNavLink(option, i18n.t('nav_calendar'), '/calendar', 'calendar');
						
					case 'articles':
						return this.createNavLink(option, i18n.t('nav_articles'), '/articles', 'file-alt');
						
					case 'subscribers':
						return this.createNavLink(option, i18n.t('nav_subscribers'), '/subscribers', 'at');
						
					case 'analytics':
						return this.createNavLink(option, i18n.t('nav_analytics'), '/analytics', 'analytics');
						
					case 'products':
						return this.createNavLink(option, i18n.t('nav_products'), '/products', 'bicycle');
						
					case 'regional':
						return this.createNavLink(option, i18n.t('nav_regional'), '/regional', 'users');
						
					case 'retailers':
						return this.createNavLink(option, i18n.t('nav_retailers'), '/retailers', 'store-alt');
						
					default: 
						return {};
				}

			});

			return list;
		}

		return;
	}

	render() {
		
		let permissions = this.props.account.permissions;
		
		return (
			<React.Fragment>
				<ul className="c-sidebar__list u-border-bottom">
					{this.renderNav()}
				</ul>
				
				<ul className="c-sidebar__list">
					
					{checkPermission(permissions, 'SETTINGS') && 
						this.createNavLink('settings', i18n.t('nav_settings'), '/settings', 'cog')
					}
					
					{this.createNavLink('documentation', i18n.t('nav_documentation'), this.props.account.docs, 'book', true)}
					{this.createNavLink('account', i18n.t('nav_account'), '/account', 'user')}
				</ul>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, chrome, brand }, ownProps){
	return { 
		account,
		section: chrome.section,
		brand
	};
}

export default withRouter(connect(mapStateToProps)(Nav));
