import _ from 'lodash';

export function tagsArray(posted) {

	let tags = {
		existing: [],
		new: []	
	};
	
	_.forEach(posted, (tag, key) => {					
			
		if(tag.__isNew__){
			tags.new.push(tag.value);
		}else{
			tags.existing.push(tag.value);
		}
	});
			
	return tags;
}
