import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import Table from '../../../components/chrome/table';
import _ from 'lodash';
import OverlaySettings from '../../../components/chrome/overlay_settings';
import InputField from '../../../components/forms/field.js';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';

import Loading from '../../../components/chrome/loading';
import EmptySection from '../../../components/chrome/empty';
import { imageResize } from '../../../helpers/s3';

import { setSectionOptions } from '../../../actions/chrome';
import RetailsDetailsToolbar from './toolbar';
import { checkPermission } from '../../../helpers/permissions';
import { fetchRetailersList } from '../../../actions/retailers';
import { fetchInvoices, fetchBillingMonth } from '../../../actions/billing';
import moment from 'moment';
import 'moment/min/locales';
import getSymbolFromCurrency from 'currency-symbol-map';

class RetailersBilling extends Component {

	constructor(props){
		super(props);
		
		const id = this.props.account.group.id;
		this.props.setSectionOptions('settings', i18n.t('users:settings_title'));
			
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'RETAILERS_BILLING')){
			this.props.history.push('/retailers');
		}
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(!this.props.retailers.retailers[id]){
				this.props.history.push('/settings');
			}
       	});
       	
       	const month = moment().format('YYYY-MM');
		
		this.state = {
			id: id,
			month: month
		}
		
		this.props.fetchInvoices();
		this.props.fetchBillingMonth(month);
		this.updateDomElements();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
		
	}
	
	onInputMonthChange(selected){
				
		this.setState({
			month: selected.value
		});
		
		this.props.fetchBillingMonth(selected.value);
	}
	
	renderMonth(){
		
		let { billing, retailers } = this.props;
		
		if(!billing.monthly){
			return null;
		}
		
		let renderMonth = (
			<Loading 
				className="inline"
			/>
		);
		
		const retailer = retailers.retailers[this.state.id];
		let months = [];
		let month = moment(retailer.created.date).format('YYYY-MM');
		
		while(month <= moment().format('YYYY-MM')){
			
			months.push({
				label: moment(month, 'YYYY-MM').format('MMM YYYY'),
				value: month
			});
			
			month = moment(month, 'YYYY-MM').add(1, 'MONTH').format('YYYY-MM');
		}
		
		months.reverse();
		
		if(billing.monthly[this.state.month]){
				
			let labels = [];
			let datasets = [{
				label: 'Sent',
				backgroundColor: '#2083fe',
				borderColor: '#2083fe',
				borderWidth: 2,
				data: [],
				fill: false,
				pointRadius: 0
			}];
					
			_.forEach(billing.monthly[this.state.month].daily, (value, day) => {
				labels.push(moment(this.state.month + '-' + day).format('D/M'));
				datasets[0].data.push(value);
			});

			const currency = getSymbolFromCurrency(billing.monthly[this.state.month].currency);
	
			renderMonth = (
				<React.Fragment>
					<div className="row u-mt-medium u-mb-none">
						<div className={`col-sm-6 col-md-4 col-xl-4`}>
							<div className="c-card equalize stats-box">
								<span className={`c-icon u-mb-small`}>
									<i className={`fal fa-tally`}></i>
								</span>
								<h3 className="c-text--subtitle">Free Allowance</h3>
								<h2 className="counter">
									<CountUp 
										delay={0} 
										duration={1} 
										end={billing.monthly[this.state.month].messages.free} 
										separator="," 
										preserveValue={true}
									/>
								</h2>
							</div>
						</div>
						<div className={`col-sm-6 col-md-4 col-xl-4`}>
							<div className="c-card equalize stats-box">
								<span className={`c-icon u-mb-small`}>
									<i className={`fal fa-mail-bulk`}></i>
								</span>
								<h3 className="c-text--subtitle">Messages Sent</h3>
								<h2 className="counter">
									<CountUp 
										delay={0} 
										duration={1} 
										end={billing.monthly[this.state.month].messages.sent} 
										separator="," 
										preserveValue={true}
									/>
								</h2>
							</div>
						</div>
						<Link to={billing.monthly[this.state.month].invoice ? `/settings/billing/invoice/${billing.monthly[this.state.month].invoice}` : '/settings/billing/plans'} className={`col-sm-6 col-md-4 col-xl-4`}>
							<div className="c-card equalize stats-box">
								<span className={`c-icon u-mb-small`}>
									<i className={`fal fa-money-bill`}></i>
								</span>
								<h3 className="c-text--subtitle">
									{this.state.month == moment().format('YYYY-MM') ? 'Projected Cost' : 'Cost'}
								</h3>
								<h2 className="counter">
									<CountUp 
										delay={0} 
										duration={1} 
										end={billing.monthly[this.state.month].cost} 
										separator="," 
										preserveValue={true}
										prefix="£"
									/>
								</h2>
							</div>
						</Link>
					</div>
					
					<div style={{ height: '350px', width: 'calc(100% + 20px)', marginLeft: '-10px'  }}>
						<Line
							data={{
								labels: labels,
								datasets: datasets
							}}
							options={{
								responsive: true,
								maintainAspectRatio: false,
								title: {
									display: false,
								},
								legend: {
									display: false,
								},
								layout: {
						            padding: {
						                left: 10,
						                right: 10,
						                top: 30,
						                bottom: 30
						            }
						        },
								tooltips: {
									mode: 'index',
									intersect: false,
									callbacks: {
						                title: (tooltipItem, data) => {
							                return moment(tooltipItem[0].xLabel, 'D/M').format('LL');
							            }
								    }
								},
								hover: {
									mode: 'nearest',
									intersect: true
								},
								scales: {
									xAxes: [{
										display: true,
										scaleLabel: {
											display: false,
											scaleLabel: {
												display: false
											}
										}
									}],
									yAxes: [{
										display: false,
										scaleLabel: {
											display: false
										},
										ticks: {
											min: 0
										}
									}]
								}
							}}
						/>
					</div>
				</React.Fragment>
			);
		}
		
		return (
			<div className="c-card equalize">
				<h4 className="u-mb-medium">
					Monthly Overview
					<div className="options" style={{ minWidth: '150px' }}>
		         		
		         		<InputField
							name="month"
							type="suggest"
							options={months}
							selected={this.state.month}
							alignRight={true}
							onChangeFunc={this.onInputMonthChange.bind(this)}
						/>
	         		</div>
				</h4>
				
				{renderMonth}
			</div>
		);
	}
	
	renderStripe(){
		
		return (
			<div className="c-card">
				<h4 className="u-mb-medium">Payment Method</h4>
				<p>Online payment coming soon!</p>
			</div>
		);
	}
	
	renderInvoices(){
		
		let { billing } = this.props;
		
		if(!billing.invoices){
			return null;
		}
		
		if(_.isEmpty(billing.invoices)){

			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title="No Invoices"
					description="You have no issues invoices"
				/>
			);
		}
		
		return (
			<div className="c-card">
				<h4 className="u-mb-medium">Invoices</h4>
				<Table 
					columns={[
						{
							key: "month",
							label: "Month",
							sortable: true
						},
						{
							key: "total",
							label: "Total",
							sortable: true,
							format: 'currency'
						},
						{
							key: "status",
							label: "Status",
							sortable: true,
							format: 'color'
						},
						{
							key: "actions",
							label: "Actions",
							className: "tight right"
						}
					]}
					data={_.map(billing.invoices, (invoice, key) => {
						
						let status = invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1);
						let color = false;
						
						switch(invoice.status){
	
							case 'failed':
							case 'overdue':
								color = '#FF3333';
								break;
								
							default:
							
								break;
						}
		
						return ({
							id: invoice.id,
							month: moment(invoice.month).format('MMM YYYY'),
							total: {
								value: invoice.total,
								currency: invoice.currency
							},
							status: {
								value: status,
								color: color
							},
							actions: invoice.status === '1' ? ['view'] : ['pay']								
						});
					})}
					actions={{
						view: {
							tooltip: 'View Invoice',
							icon: 'fal fa-eye',
							url: '/settings/billing/invoice/[ID]'
						},
						pay: {
							tooltip: 'View & Pay Invoice',
							icon: 'fal fa-money-bill',
							url: '/settings/billing/invoice/[ID]'
						}
					}}
					sort={{
						column: 'month',
						order: 'desc'
					}}
					rowClick={(row) => {
						this.props.history.push(`/settings/billing/invoice/${row.id}`);
					}}
				/>
			</div>
		);
	}
	
	renderPage(){
		
		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}		
				
		return (
			<div className="container">
				<div className="row">
					<div className="col-md-7">
						{this.renderMonth()}						
					</div>
					<div className="col-md-5">
						{this.renderStripe()}
						
						{this.renderInvoices()}
					</div>
				</div>		
			</div>
		);
	}
	
	render() {

		return (

			<React.Fragment>
				
				<RetailsDetailsToolbar
					section="billing"
					id={this.state.id}
					own={true}
					{...this.props}
				/>

				{this.renderPage()}
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, users, retailers, billing }, ownProps){
	return {
		users,
		retailers,
		account,
		billing
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchRetailersList,
	fetchInvoices, 
	fetchBillingMonth
})(RetailersBilling);