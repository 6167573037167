import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 
import { generateBack } from '../../helpers/back';

class SubscribersToolbar extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
			search: false
		}
	}
		
	render() {
		
		let buttons = [];
		let tabs = [{
			label: i18n.t('subscribers:toolbar_settings'),
			url: `${this.props.parentPath}/audience/${this.props.id}`,
			selected: this.props.section === 'settings' ? true : false
		}];
		
		let filter = false;
		
		switch(this.props.section){
				
			case 'settings':
				buttons.push({
					label: i18n.t('retailers:integration_domain_action_save'),
					submit: this.props.formName
				});	
				break;
				
			case 'users':
				buttons.push({
					icon: 'fa-upload',
					url: `${this.props.parentPath}/audience/${this.props.id}/import?b=${generateBack(window.location.pathname)}`,
					outline: true
				},
				/*{
					icon: 'fa-download',
					url: `${this.props.parentPath}/audience/${this.props.id}/users/export`,
				},*/
				{
					label: i18n.t('subscribers:action_user_add'),
					url: `${this.props.parentPath}/audience/${this.props.id}/users/add?b=${generateBack(window.location.pathname)}`
				});
				
				/*filter = {
					toggleFunc: (status) => {
						this.setState({
							search: status
						});
					},
					onChangeFunc: (event) => { 
						
						this.setState({
							keyword: event.target.value
						})
					},
					fields: [
						{
							placeholder: i18n.t('subscribers:user_filter_keyword'),
							name: "keyword",
							type: 'text'
						}
					],
					open: this.state.search
				}*/
				break;
				
			default:
			
				break;			
		}
		
		if(this.props.id){
			tabs.push({
				label: i18n.t('subscribers:toolbar_users'),
				url: `${this.props.parentPath}/audience/${this.props.id}/users`,
				selected: this.props.section === 'users' ? true : false,
				counter: String(this.props.counter)
			})
		}
		
		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}

function mapStateToProps({ users }, ownProps){
	return {
		users
	};
}

export default connect(mapStateToProps, { 

})(SubscribersToolbar);