import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"
import { proceedAfterSubmission } from '../../actions/forms';

class Submit extends Component {
	
	render() {
		
		let disabled = false;
		let className = '';
		
		if(this.props.className){
			className = this.props.className;
		}

		if(this.props.form && this.props.formSubmitting === this.props.form){
			disabled = true;
			className += ' processing ';
		}
		
		if(this.props.type){
			
			switch(this.props.type){
				
				case 'secondary':
					className += 'secondary ';
					break
					
				case 'warning':
					className += 'warning ';
					break
					
				default:
				
					break;
			}
		}
		
		return (
			<button 
				type="submit" 
				className={`submit c-btn c-btn--fullwidth c-btn--info ${className}`} 
				disabled={disabled}
				onClick={() => {
					
					if(this.props.onClick){
						this.props.onClick();
					}
					
					this.props.proceedAfterSubmission(this.props.proceed ? true : false);
				}}
			>
				{this.props.label ? this.props.label : 'Submit'}
			</button>
		)
	}
}

function mapStateToProps({ forms }, ownProps){
	return { 
		formSubmitting: forms.submitting,
		forms: forms
	};
}

export default withRouter(connect(mapStateToProps, {  
	proceedAfterSubmission
})(Submit));
