import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import Table from '../../components/chrome/table';
import _ from 'lodash';
import OverlaySettings from '../../components/chrome/overlay_settings';

import Loading from '../../components/chrome/loading';
import EmptySection from '../../components/chrome/empty';

import { checkPermission } from '../../helpers/permissions';
import { fetchSubscribersGroups, fetchSubscribersUsers } from '../../actions/subscribers';
import { generateBack } from '../../helpers/back';
import SubscribersToolbar from './toolbar';

class SubscribersUsers extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;
		
		if(!checkPermission(this.props.account.permissions, 'SUBSCRIBERS')){
			this.props.history.push('/');
		}

		// MUST HAVE CORRECT PERMISSIONS!
		if(!id){
			this.props.history.push('/');
		}
		
		this.props.fetchSubscribersGroups(id, () => {
	       	
	       	if(this.props.subscribers.groups.local){
		       
		       	if(!this.props.subscribers.groups.local[id]){
					this.props.history.push('/subscribers');	
				}
			}
       	});

		this.props.fetchSubscribersUsers(id);
					       	       			
		this.state = {
			id: id
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	renderPage(){
		
		let { subscribers } = this.props;
				
		if(!subscribers.users){
			return (
				<Loading />
			);
		}		

		let users = [];
		
		if(subscribers.groups.local[this.state.id].subscribers && this.props.subscribers.users){
			
			_.forEach(subscribers.groups.local[this.state.id].subscribers, (user, key) => {	
				
				if(this.props.subscribers.users[user]){
					users.push(this.props.subscribers.users[user]);
				}
			}, this)			
		}
								
		if(_.isEmpty(users)){

			return (
				<React.Fragment>
				
					{!this.state.own && 
						<React.Fragment>
							<OverlaySettings closeURL={this.props.parentPath} />
							
							<header className="c-navbar u-mb-medium">
								<h2 className="c-navbar__title">
									{subscribers.groups.local[this.state.id].name}
								</h2>
							</header>
						</React.Fragment>
					}
					
					<SubscribersToolbar
						section="users"
						id={this.state.id}
						counter="0"
						{...this.props}
					/>	
									
					<EmptySection
						icon="fa-exclamation-circle"
						title={i18n.t('subscribers:empty_users_title', { type: this.state.type })}
						description={i18n.t('subscribers:empty_users_description', { type: this.state.type })}
						cta={{
							label: i18n.t('subscribers:action_user_add'),
						url: `${this.props.parentPath}/audience/${this.state.id}/users/add?b=${generateBack(window.location.pathname)}`
						}}
					/>
				</React.Fragment>
			);
		}

		return (
			<div className="c-card">
					
				<Table 
					columns={[
						{
							key: "name",
							label: "Name",
							sortable: true
						},
						{
							key: "email",
							label: "Email",
							sortable: true
						},
						{
							key: "created",
							label: "Subscribed",
							sortable: true,
							format: 'datetime'
						},
						{
							key: "last",
							label: "Last Email",
							sortable: true,
							format: 'datetime'
						},
						{
							key: "actions",
							label: "Actions",
							className: "tight right"
						}
					]}
					data={_.map(users, (user, key) => {
									
						let name = `${user.forename} ${user.surname}`;			
												
						return ({
							id: user.id,
							name: name,
							email: user.email,
							created: user.created.date,
							last: user.last_message ? user.last_message.date : false,
							actions: ['edit']								
						});
					})}
					actions={{
						edit: {
							tooltip: i18n.t('subscribers:users_action_edit'),
							icon: 'fal fa-edit',
							url: `${this.props.parentPath}/audience/${this.state.id}/users/[ID]?b=${generateBack(window.location.pathname)}`
						}
					}}
					sort={{
						column: 'name',
						order: 'asc'
					}}
					rowClick={(row) => {
						this.props.history.push(`${this.props.parentPath}/audience/${this.state.id}/users/${row.id}?b=${generateBack(window.location.pathname)}`);
					}}
				/>
				
				
				
				
			</div>
		);
	}
		
	render() {
		
		let { subscribers } = this.props;
				
		if(!subscribers.groups.local || !subscribers.groups.local[this.state.id]){
			return (
				<Loading />
			);
		}		
				
		return (

			<React.Fragment>
				
				{!this.state.own && 
					<React.Fragment>
						<OverlaySettings closeURL={this.props.parentPath} />
						
						<header className="c-navbar u-mb-medium">
							<h2 className="c-navbar__title">
								{subscribers.groups.local[this.state.id].name}
							</h2>
						</header>
					</React.Fragment>
				}
				
				<SubscribersToolbar
					section="users"
					id={this.state.id}
					counter={subscribers.groups.local[this.state.id].subscribers.length}
					{...this.props}
				/>	

				{this.renderPage()}
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, users, subscribers }, ownProps){
	return {
		users,
		subscribers,
		account
	};
}

export default connect(mapStateToProps, { 
	fetchSubscribersGroups,
	fetchSubscribersUsers
})(SubscribersUsers);