import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 
import { forceDownload } from '../../helpers/download'; 
import { downloadAsset } from '../../actions/assets';

class AssetToolbar extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			downloading: false
		}
	}
	
	render() {
		
		let buttons = [];

		switch(this.props.section){
				
			case 'view':
				buttons.push({
					icon: 'fa-external-link',
					onClick: () => {
						window.open(this.props.filename);
					},
					outline: true
				});
				
				buttons.push({
					icon: 'fa-download',
					className: this.state.downloading ? 'processing' : false,
					onClick: () => {
						this.setState({ downloading: true });
						
						this.props.downloadAsset(this.props.id, (url, filename) => {
							forceDownload(url, filename);
							this.setState({ downloading: false });
						});
					},
					outline: true
				});
				break;

			case 'edit':
				buttons.push({
					label: this.props.cta,
					submit: this.props.formName
				});
				break;
				
			default:
			
				break;			
		}
				
		let tabs = [];
		
		if(this.props.id){
			tabs.push({
				label: i18n.t('assets:toolbar_view'),
				url: `${this.props.parentPath}/asset/${this.props.id}`,
				selected: this.props.section === 'view' ? true : false
			});
			
			if(this.props.type == 'image'){
				tabs.push({
					label: i18n.t('assets:toolbar_crop'),
					url: `${this.props.parentPath}/asset/${this.props.id}/crop`,
					selected: this.props.section === 'crop' ? true : false,
					counter: this.props.crops
				});
			}
		}
		
		if(!this.props.readonly){
			tabs.push({
				label: i18n.t('assets:toolbar_settings'),
				url: `${this.props.parentPath}/asset/${this.props.id ? this.props.id + '/edit' : ''}`,
				selected: this.props.section === 'edit' ? true : false
			});
		}

		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
			/>			
		);
	}
}

function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 
	downloadAsset
})(AssetToolbar);