import { subscribersConstants } from '../constants/subscribers';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchSubscribersGroups(id, callback) {

    return async (dispatch) => {
		
		let url = '/subscriber/groups';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: subscribersConstants.SUBSCRIBERS_GROUPS,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}


export function fetchSubscribersUsers(id, callback) {

    return async (dispatch) => {
		
		let url = '/subscriber/users';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: subscribersConstants.SUBSCRIBERS_USERS,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveSubscribersGroup(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/subscriber/group/${id}`, values);
			}else{
				request = await axiosCreate().post('/subscriber/group', values);
			}	
								
			dispatch({
				type: subscribersConstants.SUBSCRIBERS_GROUPS_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('subscribers:notification_group_updated_title'),
					i18n.t('subscribers:notification_group_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('subscribers:notification_group_added_title'),
					i18n.t('subscribers:notification_group_added_description')
				));
			}

			if(callback)
				callback(request.data.groups[0].id);				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function saveSubscriber(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/subscriber/user/${id}`, values);
			}else{
				request = await axiosCreate().post('/subscriber/user', values);
			}	
								
			dispatch({
				type: subscribersConstants.SUBSCRIBERS_USER_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('subscribers:notification_user_updated_title'),
					i18n.t('subscribers:notification_user_updated_description')
				));
			}else{
				
				if(request.data.status === 'success_updated'){
					dispatch(showNotification(
						'success',
						i18n.t('subscribers:notification_user_added_title_dupe'),
						i18n.t('subscribers:notification_user_added_description_dupe')
					));

				}else{
					dispatch(showNotification(
						'success',
						i18n.t('subscribers:notification_user_added_title'),
						i18n.t('subscribers:notification_user_added_description')
					));
				}				
			}

			if(callback)
				callback();				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function unsubscribeSubscriber(id, callback) {

	return async (dispatch) => {
				
		try {
						
			const request = await axiosCreate().post(`/subscriber/unsubscribe/${id}`);
								
			dispatch({
				type: subscribersConstants.SUBSCRIBERS_UNSUBSCRIBE,
				payload: request
			});

			if(callback)
				callback();				

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchSubscribersStats(from, to) {

    return async (dispatch) => {
		
		let url = '/subscriber/overview';
		
		if(from){
			url += `?from=${from}`;
		}
		
		if(to){
			url += `&to=${to}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: subscribersConstants.SUBSCRIBERS_STATS,
				payload: request
			});
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function importSubscribers(formName, group, values, callback) {

	return async (dispatch) => {
				
		try {
			
			values.group = group;
						
			const request = await axiosCreate().post('/subscriber/import', values);	
								
			dispatch({
				type: subscribersConstants.SUBSCRIBERS_USERS_IMPORT,
				payload: request
			});
			
			if(values.map){
				dispatch(showNotification(
					'success',
					i18n.t('subscribers:notification_upload_imported_title'),
					i18n.t('subscribers:notification_upload_imported_desdription')
				));
			}

			if(callback)
				callback(request.data);				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function fetchSubscribersHistory(user) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get(`/subscriber/history/${user}`);
										
			dispatch({ 
				type: subscribersConstants.SUBSCRIBERS_HISTORY,
				payload: request,
				user: user
			});
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchSubscribersQueued(user) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get(`/subscriber/queued/${user}`);
										
			dispatch({ 
				type: subscribersConstants.SUBSCRIBERS_QUEUED,
				payload: request,
				user: user
			});
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}
