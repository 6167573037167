// REWORK i18n.t params
import React, { Component } from 'react';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 

class AccountToolbar extends Component {

	render() {
		
		let buttons = [];
		
		switch(this.props.section){
				
			case 'settings':
				buttons.push({
					label: i18n.t('users:toolbar_settings_submit'),
					submit: this.props.formName
				});
				break;
				
			case 'preferences':
				buttons.push({
					label: i18n.t('users:toolbar_preferences_submit'),
					submit: this.props.formName
				});
				break;
				
				
			default:
			
				break;			
		}

		return (
			<Toolbar
				tabs={[{
					label: i18n.t('users:account_toolbar_settings'),
					url: `/account`,
					selected: this.props.section === 'settings' ? true : false
				},
				{
					label: i18n.t('users:account_toolbar_preferences'),
					url: `/account/preferences`,
					selected: this.props.section === 'preferences' ? true : false
				}]}
				buttons={buttons}
			/>			
		);
	}
}

export default AccountToolbar;