import { userConstants } from '../constants/users';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function userLogin(formName, { email, password }, callback) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().post('/user/authenticate', { email, password })

			dispatch({
				type: userConstants.AUTHENTICATED,
				payload: request
			});

			dispatch(stopSubmission(formName));
			
			if(callback){
				callback(request.data);
			}

		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});

			dispatch(stopSubmission(formName));
		}
	};
}

export function userLogout(history){

	return async (dispatch) => {
		try {
			const request = await axiosCreate().delete('/user/key', { })

			dispatch({
				type: userConstants.LOGOUT,
				payload: request
			});
			
			var cookies = document.cookie.split(";");

		    for (var i = 0; i < cookies.length; i++) {
		        var cookie = cookies[i];
		        var eqPos = cookie.indexOf("=");
		        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		    }

			localStorage.clear();
			//history.push('/login');
			window.location = '/login';

		} catch(error) {
			localStorage.clear();
			//history.push('/login');
			window.location = '/login';
		}
	};
}

export function userForgotten(formName, email, callback) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().post('/user/forgotten', { email })

			dispatch({
				type: userConstants.FORGOTTEN,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('users:notification_forgotten_title'),
				i18n.t('users:notification_forgotten_description')
			));

			dispatch(stopSubmission(formName));
			
			if(callback){
				callback();
			}

		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});

			dispatch(stopSubmission(formName));
		}
	};
}

export function userReset(formName, token, password, callback) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().patch('/user/reset', { token: token, password: password })

			dispatch({
				type: userConstants.RESET,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('users:notification_reset_title'),
				i18n.t('users:notification_reset_description')
			));

			dispatch(stopSubmission(formName));
			
			if(callback){
				callback();
			}

		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});

			dispatch(stopSubmission(formName));
		}
	};
}

export function userWelcome(formName, values, callback) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().patch('/user/approve', values)

			dispatch({
				type: userConstants.WELCOME,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('users:notification_welcome_title'),
				i18n.t('users:notification_welcome_description')
			));

			dispatch(stopSubmission(formName));
			
			if(callback){
				callback();
			}

		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});

			dispatch(stopSubmission(formName));
		}
	};
}

export function userValidateKey(type, token, callback) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().get(`/user/key?type=${type}&token=${token}`)

			dispatch({
				type: userConstants.KEY_CHECK,
				payload: request,
				token: token
			});
						
			if(callback){
				callback();
			}

		} catch(error) {

			dispatch({
				type: userConstants.KEY_CHECK,
				token: token
			});
						
			if(callback){
				callback();
			}
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		}
	};
}

export function userSave(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
				
		try {
			
			if(id){
				request = await axiosCreate().patch(`/user/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/user/save', values);
			}			
			
			if(id === localStorage.getItem('user_id')){
				
				dispatch(showNotification(
					'success',
					i18n.t('users:notification_account_updated_title'),
					i18n.t('users:notification_account_updated_description'),
				));

			}else{
				
				if(id){
					dispatch(showNotification(
						'success',
						i18n.t('retailers:notification_user_updated_title'),
						i18n.t('retailers:notification_user_updated_description'),
						request.data.users[0].image
					));
				}else{
					dispatch(showNotification(
						'success',
						i18n.t('retailers:notification_user_added_title'),
						i18n.t('retailers:notification_user_added_description'),
						request.data.users[0].image
					));
				}
			}
			
			dispatch({
				type: userConstants.USER_SAVE,
				payload: request
			});
			
			dispatch(stopSubmission(formName));
			
			if(callback){
				callback(request.data.users[0]);
			}

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function userSavePreferences(formName, id, values, callback) {

	return async (dispatch) => {
						
		try {
			
			let request = await axiosCreate().patch(`/user/save/${id}`, values);		
			
			dispatch(showNotification(
				'success',
				i18n.t('users:notification_preferences_updated_title'),
				i18n.t('users:notification_preferences_updated_description'),
				request.data.users[0].image
			));
			
			dispatch({
				type: userConstants.USER_SAVE_PREFERENCES,
				payload: request
			});
			
			dispatch(stopSubmission(formName));
			
			if(callback){
				callback(request.data.users[0]);
			}

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function usersFetch(id, group, callback) {

    return async (dispatch) => {
		
		let url = '/user/list';
		let param = '?';
		
		if(id){
			url += `${param}id=${id}`;
			param = '&';
		}
		
		if(group){
			url += `${param}group=${group}`;
		}
				
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: userConstants.USER_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function userReinvite(id) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().post('/user/reinvite', { id })

			dispatch({
				type: userConstants.REINVITE,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('retailers:notification_reinvited_title'),
				i18n.t('retailers:notification_reinvited_description')
			));

		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		}
	};
}

export function userDelegateLogin(id) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().post('/user/delegate', { id })

			dispatch({
				type: userConstants.DELEGATE_AUTHENTICATED,
				payload: request
			});
						
			window.location = '/';

		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		}
	};
}

export function userDelegateLogout(){

	return async (dispatch) => {
		
		dispatch({
			type: userConstants.DELEGATE_LOGOUT
		});
		
		window.location = '/';
	}
}

export function predictCountry(callback){
	
	return async (dispatch) => {
		try {
			const request = await axiosCreate().get('/user/country')

			dispatch({
				type: userConstants.PREDICT_COUNTRY,
				payload: request
			});
			
			if(callback){
				callback();
			}
						
		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		}
	};
}