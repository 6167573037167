import { connectionsConstants } from '../constants/connections';

export default function(state = {}, action) {
	
	switch (action.type) {
		      
		case connectionsConstants.CONNECITONS_SETTINGS:

			return { 
				...state, 
				settings: { ...state.settings, ...action.payload.data},
			}
			
		case connectionsConstants.CONNECTIONS_WEBSITE:
			
			return { 
				...state, 
				website: action.payload.data.status
			}
			
		case connectionsConstants.CONNECTIONS_SES:
			
			return { 
				...state, 
				ses: action.payload.data
			}
			
		case connectionsConstants.CONNECTIONS_MAILCHIMP:
			
			return { 
				...state, 
				mailchimp: action.payload.data
			}
					
		default:
			return state;
	}
}