import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 

class RetailersToolbar extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
			search: false
		}
		
	}
	
	render() {
		
		let buttons = [];
		let filter = false;
		
		switch(this.props.section){
				
			case 'list':
				buttons.push({
					label: i18n.t('retailers:action_add'),
					url: '/retailers/retailer/add'
				});
				
				/*filter = {
					toggleFunc: (status) => {
						this.setState({
							search: status
						});
					},
					onChangeFunc: (event) => { 
						
						this.setState({
							keyword: event.target.value
						})
					},
					fields: [
						{
							placeholder: i18n.t('retailers:filter_sub'),
							name: "group",
							type: 'suggest',
							options: [
								{
									value: 1,
									label: 'Item 1'
								},
								{
									value: 2,
									label: 'Item 2'
								}
							]
						},
						{
							placeholder: i18n.t('retailers:filter_keyword'),
							name: "keyword",
							type: 'text'
						}
					],
					open: this.state.search
				}*/
				break;
				
			case 'groups':
				buttons.push({
					label: i18n.t('retailers:action_add_sub'),
					url: '/retailers/groups/group/add'
				});
				
				/*filter = {
					toggleFunc: (status) => {
						this.setState({
							search: status
						});
					},
					onChangeFunc: (event) => { 
						
						this.setState({
							keyword: event.target.value
						})
					},
					fields: [
						{
							placeholder: i18n.t('retailers:sub_filter_keyword'),
							name: "keyword",
							type: 'text'
						}
					],
					open: this.state.search
				}*/
				break;
				
			default:
			
				break;			
		}

		return (
			<Toolbar
				tabs={[
					{
						label: i18n.t('retailers:toolbar_list'),
						url: '/retailers',
						selected: this.props.section === 'list' ? true : false
					},
					{
						label: i18n.t('retailers:toolbar_sub'),
						url: '/retailers/groups',
						selected: this.props.section === 'groups' ? true : false
					}
				]}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}

function mapStateToProps({ users }, ownProps){
	return {
		users
	};
}

export default connect(mapStateToProps, { 

})(RetailersToolbar);