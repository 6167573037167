// REWORK i18n.t params
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../../components/chrome/toolbar';
import i18n from '../../../helpers/i18n'; 

class RetailersGroupsToolbar extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
			search: false
		}
	}
		
	render() {
		
		let buttons = [];
		
		let tabs = [{
			label: i18n.t('retailers:groups_toolbar_settings'),
			url: `${this.props.parentPath}/group/${this.props.id}`,
			selected: this.props.section === 'settings' ? true : false
		}];
		
		let filter = false;
		
		switch(this.props.section){
				
			case 'settings':
				buttons.push({
					label: i18n.t('retailers:groups_settings_save'),
					submit: this.props.formName
				});
				break;
				
			case 'assigned':
				if(this.props.retailer){
					buttons.push({
						label: i18n.t('retailers:groups_action_add', { count: this.props.retailer.length }),
						submit: this.props.formName
					});
				}
				break;
				
			default:
			
				break;			
		}
		
		if(this.props.id){
			tabs.push({
				label: i18n.t('retailers:groups_toolbar_assigned'),
				url: `${this.props.parentPath}/group/${this.props.id}/assigned`,
				selected: this.props.section === 'assigned' ? true : false
			})
		}
		
		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}

function mapStateToProps({ users }, ownProps){
	return {
		users
	};
}

export default connect(mapStateToProps, { 

})(RetailersGroupsToolbar);