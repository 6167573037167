import { Base64 } from 'js-base64';

export function generateBack(url, action, url_cancel) {

	if(!action){
		action = false;
	}
	
	let hash = {
		url: url, //window.location.pathname,
		action: action,
		cancel: url_cancel ? url_cancel : url
	};
		
	return Base64.encode(JSON.stringify(hash));
}

export function parseBack(json) {

	let hash = JSON.parse(Base64.decode(json));
	
	return hash;
}

