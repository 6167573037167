export const assetsConstants = {
    ASSET_LIST: 'ASSET_LIST',
    ASSET_SEARCH: 'ASSET_SEARCH',
    ASSET_SAVE: 'ASSET_SAVE',
    COLLECTION_LIST: 'COLLECTION_LIST',
    COLLECTION_SAVE: 'COLLECTION_SAVE',
    ASSET_DELETE: 'ASSET_DELETE',
    ASSET_DOWNLOAD: 'ASSET_DOWNLOAD',
    ASSET_HEADLINE: 'ASSET_HEADLINE',
    REMOTE_VIDEO_DETAILS: 'REMOTE_VIDEO_DETAILS',
    ASSET_CROP: 'ASSET_CROP'
};