import { chromeConstants } from '../constants/chrome';

export function setSectionOptions(section, title, back){
		
	return {
		type: chromeConstants.SECTIONTITLE,
		payload: {
			section: section,
			title: title,
			back: back ? back : false
		}
	};
}