import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmptySection from '../../components/chrome/empty';
import i18n from '../../helpers/i18n'; 
import { setSectionOptions } from '../../actions/chrome';

class Error404 extends Component {
	
	constructor(props){
		super(props);

		this.props.setSectionOptions('articles', i18n.t('translation:404_title'));
	}
	
	render(){
		
		return (
			<EmptySection
				icon="fa-frown"
				title={i18n.t('translation:404_empty_title')}
				description={i18n.t('translation:404_empty_description')}
				cta={{
					label: i18n.t('translation:404_empty_cta'),
					url: '/'
				}}
			/>
		);
	}
}

function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions
})(Error404);