import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import CharacterCounter from 'react-character-counter';
import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';
import { fetchAssetsList } from '../../actions/assets';
import _ from 'lodash';
import clip from "text-clipper"; 
import CampaignsToolbar from './toolbar';
import { fetchCampaignsList, saveCampaignContent } from '../../actions/campaigns';
import ReactTooltip from 'react-tooltip';
import AssetGridItem from '../../components/assets/grid_item';
import CountUp from 'react-countup';

import { setSectionOptions } from '../../actions/chrome';

import { checkPermission } from '../../helpers/permissions';

var formName = 'formCampaignsContent';

class CampaignsContent extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT')){
			this.props.history.push('/');
		}
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/campaigns');
		}		
				
		this.state = {
			id: id,
			readonly: false,
			description: {
				0: {}
			},
			description_counter: 0,
			permissions: {
				customise: checkPermission(this.props.account.permissions, 'CAMPAIGNS_EDIT', 'customise')
			},
			preview: 'email',
			images: [],
			customise: true
		}
		
		this.props.fetchCampaignsList(id, () => {
		       	
	       	if(!this.props.campaigns.campaigns[id]){
				this.props.history.push('/campaigns');
			}else{
				this.updateDomElements();
			}
       	});
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
		this.updateDomElements();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}	
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 6;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
	
	updateDomElements(){
				
		if(this.props.campaigns.campaigns && this.props.campaigns.campaigns[this.state.id]){
			
			const campaign = this.props.campaigns.campaigns[this.state.id];	
			
			this.props.setSectionOptions('campaigns', campaign.internal_name, `/campaigns/${this.state.id}`);
			
			let restrictions = {};
						
			if(!_.isEmpty(campaign.items_restrictions.headline.promo)){
								
				_.forEach(campaign.items_restrictions.headline.promo, (value, key) => {
					
					_.forEach(value, (block, key2) => {
					
						if(!restrictions[block]){
							restrictions[block] = [];
						}
						
						restrictions[block].push(parseFloat(key));
					});
				});
			}

			let description = {};
			let init = {};
				
			_.forEach(campaign.content, (item, key) => {
				
				if(!description[key]){
					description[key] = {
						id: item.id
					};
				}
								
				_.forEach(JSON.parse(item.content), (content, type) => {
					description[key][type] = content;
					init = {...init, ...{ [`description_${key}_${type}`]: content }};
				});		
				
				if(restrictions[item.id]){
					description[key].images = restrictions[item.id];
				}		
			});
						
			this.props.initialize(init);
			
			let images = [];
						
			if(campaign.items){
										
				if(campaign.items.assets && !_.isEmpty(campaign.items.assets)){
										
					_.forEach(campaign.items.assets, (id, key) => {	
						
						if(campaign.items.headline.includes(id)){
							images.push(id);
						}
						
					}, this);
					
					this.props.fetchAssetsList(campaign.items.assets);
				}
			}
			
			let customise = true;
			
			if(campaign.status === 'draft' && !this.state.permissions.customise && campaign.customise === 0){
				customise = false;
			}

			this.setState({
				readonly: campaign.status === 'draft' ? false : true,
				description_counter: Object.keys(description).length,
				images: images,
				customise: customise
			});	
			
			if(Object.keys(description).length > 0){
				
				this.setState({
					description: description
				});
			}
					
		}else{
			this.props.setSectionOptions('campaigns', i18n.t('campaigns:assets_title'), `/campaigns/${this.state.id}/content`);
		}
	}
		
	onSubmit(values) {
		
		if(this.state.readonly){
			return false;
		}
				  				  	
		this.props.startSubmission(formName);
	  	
	  	let posting = this.state.description;
	  	
	  	_.forEach(posting, (value, key) => {
		  	
		  	if(value.images && value.images.length === 0){
			  	delete(posting[key].images);
		  	}
	  	});

	  	this.props.saveCampaignContent(formName, this.state.id, posting, (ret) => {
			
			if(this.props.proceed){
				this.props.history.push(`/campaigns/${this.state.id}/schedule`);	
			}
		});
	}
	
	descriptionAddRemove(type, key){
		
		let description = this.state.description;
		let description_counter = this.state.description_counter;
				
		if(type === 'add'){
			++description_counter;
			description[description_counter] = {};
		}else{
			
			delete(description[key]);
			
			if(Object.keys(description).length === 0){
				++description_counter;
				description[description_counter] = {};
			}
		}
										
		this.setState({
			description: description,
			description_counter: description_counter
		});	
		
		_.forEach(description, (item, key) => {					
			_.forEach(item, (value, type) => {					
				this.props.change(`description_${key}_${type}`, value);
			});
		});
		
		setTimeout(() => {
			ReactTooltip.rebuild();
		}, 500);
	}
	
	onInputChangeContent(key, type, max, value){

		let description = this.state.description;

		description[key][type] = max ? this.truncateString(value.target.value, max) : value.target.value;
		
		this.setState({
			description: description
		});
		
		this.props.change(`description_${key}_${type}`, description[key][type]);
	}	
	
	truncateString(str, limit, indicator, breakWords){
	    return clip(str, limit, { indicator: indicator ? indicator : '', breakWords: true }); 
	};
	
	countString(str, limit){
		
		if(!limit || (parseFloat(limit)-str.length) > 1000){
			return;
		}
		
		return (
			<div className={`counter ${str.length >= limit ? 'maxed' : ''}`}>
				<CharacterCounter 
					value={str} 
					maxLength={parseFloat(limit)} 
					overrideStyle={true}
				/>
			</div>
		)
	}
	
	renderChannel(key, type){
				
		const campaign = this.props.campaigns.campaigns[this.state.id];
		let social = _.find(campaign.distribution, {type: 'social'});
		let facebook = false;
		let instagram = false;
		let twitter = false;
		
		if(social){
			
			if(social.settings && social.settings.channels){
				
				_.forEach(social.settings.channels, (channels, type) => {
					
					switch(type){
						
						case 'facebook':
							facebook = true;
							break;
							
						case 'instagram':
							instagram = true;
							break;
							
						case 'twitter':
							twitter = true;
							break;
							
						default:
						
							break;
					}			
				});
				
			}else{
				facebook = true;
				instagram = true;
				twitter = true;
			}
		}
		
		
		const item = this.state.description[key];
		
		let limit = false; //https://sproutsocial.com/insights/social-media-character-counter/
		let label = '';
		
		switch(type){
			
			case 'email':
			
				if(!_.find(campaign.distribution, {type: 'email'})){
					return;
				}
				
				label = 'Email';
				break;
				
			case 'facebook':
				if(!facebook){
					return;
				}
				
				label = 'Facebook';
				limit = 63206;
				break;
				
			case 'instagram':
				if(!instagram){
					return;
				}
				
				label = 'Instagram';
				limit = 2200;
				break;
				
			case 'twitter':
				if(!twitter){
					return;
				}
				
				label = 'Twitter';
				limit = 280;
				break;
							
			default:
			
				break;
		}
				
		return (
			<Field
				label={label}
				labelSub={!this.state.readonly ? this.countString(item[type] ? item[type] : '', limit) : false}
				name={`description_${key}_${type}`}
				type={(!this.state.readonly && this.state.customise) ? 'textarea-auto' : 'echo'}
				value={item[type] ? item[type] : ''}
				echo={item[type] ? item[type] : ''}
				component={InputField}
				onChangeFunc={this.onInputChangeContent.bind(this, key, type, limit)}
			/>					
		)
	}
	
	renderImages(key){
		
		if(!this.props.assets.assets){
			return (
				<Loading />
			);
		}
		
		const item = this.state.description[key];
		
		return (
			<div className={`grid-scroller`}>
				<div className={`row u-pb-zero assets ${item.images_expanded ? 'expanded' : ''}`}>
					{_.map(this.state.images, (id, key2) => {
						
						if(!this.props.assets.assets[id]){
							return;
						}
						
						let image = _.clone(this.props.assets.assets[id])						
						
						image.headline = 1; // PASS THIS BASED ON CAMPAIGN, NOT ASSET
						image.readonly = 1;
						
						let selected = false;
						
						if(item.images && item.images.includes(id)){
							selected = true;
						}					
						
						let icon = selected ? 'fa-minus' : 'fa-plus';
	
						if(this.state.readonly){
							icon = 'fa-eye';
						}	
										
						return (
							<AssetGridItem 
								key={key2}
								asset={image}
								size="small"
								actions={[]}
								onSelectItem={() => { 
									
									if(this.state.readonly){
										this.props.history.push(`${this.props.location.pathname}/asset/${id}`);
									}else{
										
										let description = this.state.description;
										let selected = description[key].images ? description[key].images : [];
			
										if(!selected.includes(id)){
											selected.push(id);
										}else{
											
											let index = selected.indexOf(id);
								 
										    if(index > -1) {
										    	selected.splice(index, 1);
										    }
										}
	
										description[key].images = selected;
										
										this.setState({
											description: description
										});
									}
								}}
								selected={selected}
								icon={icon}
								animated={false}
								{...this.props}
							/>					
						);		
					})}
				</div>
			</div>
		);	
	}

	render() {

		if(!this.props.campaigns.campaigns || !this.props.campaigns.campaigns[this.state.id]){
			return (
				<Loading />
			);
		}
		
		const campaign = this.props.campaigns.campaigns[this.state.id];
		
		const { handleSubmit } = this.props;
		let i = 0;
			
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<CampaignsToolbar
					formName={formName}
					id={this.state.id}
					section="content"
					readonly={this.state.readonly}
					continue={true}
					campaign={campaign}
					{...this.props}
				/>
				
				<div className="container">
					<div className="c-card">					
						{_.map(this.state.description, (item, key) => {
					
							++i;
											
							return (
								<div className="c-card" key={key}>
									<h4 className={'u-mb-medium'}>
										{i18n.t('campaigns:content_title_varient', { count: i })}
										
										{(!this.state.readonly && this.state.customise) && 
											<div className="options">
												<i className="fal fa-trash" onClick={() => { this.descriptionAddRemove('remove', key); }} data-tip={i18n.t('campaigns:content_action_varient_delete')}></i>
											</div>
										}
									</h4>
									
									<div className="row">
																				
										<div className={`col-xl-12 col-md-12`}>
											{this.renderChannel(key, 'email')}
											{this.renderChannel(key, 'facebook')}
											{this.renderChannel(key, 'instagram')}
											{this.renderChannel(key, 'twitter')}
										</div>										
									</div>	
									
									{this.state.images.length > 0 && 
										<React.Fragment>
											
											<h4 className={'u-mt-medium u-mb-medium'}>
								         		{i18n.t(`campaigns:content_title_images`, { type: key })}
								         		<span className="c-badge c-badge--small badge-light u-ml-xsmall">
								         			<CountUp 
														delay={0} 
														duration={1} 
														end={item.images ? item.images.length : this.state.images.length}
														preserveValue={true}
													/>
												</span>
								         		
								         		{item.images && this.state.images.length > this.state.columns && 
								         			<div className="options">
														<i className={`fal fa-arrows-h ${item.images_expanded ? '' : 'selected'}`} onClick={() => { 
															
															let description = this.state.description;
													
															description[key].images_expanded = false;
															
															this.setState({
																description: description
															});
													 		
														}}></i>
														<i className={`fal fa-th ${item.images_expanded ? 'selected' : ''}`} onClick={() => { 
															
															let description = this.state.description;
													
															description[key].images_expanded = true;
															
															this.setState({
																description: description
															});
													 		
														}}></i>
													</div>
								         		}
								         	</h4>
								         	
								         	
											<Field
												name={`description_${key}_images`}
												type="switch"
												labelSwitch={`Choose specific headline image(s) to use with this varient${item.images ? ' - please choose:' : ''}`}
												selected={item.images ? true : false}
												disabled={this.state.readonly ? true : false}
												onChangeFunc={(event) => {
													
													let description = this.state.description;
													
													if(event.target.checked){
														description[key].images = [];
													}else{
														description[key].images = false;
													}
													
													this.setState({
														description: description
													});							
												}}
												component={InputField}
											/>
											
											{item.images && 
												this.renderImages(key)
											}
											
										</React.Fragment>
									}
															
								</div>
							);
						})}
						
						{!this.state.readonly && this.state.customise && 
							<button
								className="c-btn c-btn--outline" 
								type="button"
								onClick={() => { this.descriptionAddRemove('add'); }}
							>
								{i18n.t('campaigns:content_action_varient_add')}
							</button>
						}
						<ReactTooltip 
							effect="solid"
						/>
					</div>
				</div>
			</form>
		);
	}
}

const validate = (values, props) => {	
		
	const errors = {};
			
	let i = 0;
	
	while (i < 100) {
		if (!values[`description_${i}_email`]) {
			errors[`description_${i}_email`] = 'Please enter email content!';
		}
		
		if (!values[`description_${i}_facebook`]) {
			errors[`description_${i}_facebook`] = 'Please enter Facebook content!';
		}
		
		if (!values[`description_${i}_instagram`]) {
			errors[`description_${i}_instagram`] = 'Please enter Instagram content!';
		}
		
		if (!values[`description_${i}_twitter`]) {
			errors[`description_${i}_twitter`] = 'Please enter Twitter content!';
		}
		
		i++;
	}
	
	return errors;
}

function mapStateToProps({ campaigns, forms, account, assets }, ownProps){
	return {
		campaigns,
		proceed: forms.proceed,
		account,
		assets
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		saveCampaignContent,
		setSectionOptions,
		fetchCampaignsList,
		fetchAssetsList
	})(
	reduxForm({
		validate: validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(CampaignsContent)
);