import React, { Component } from 'react';
import { connect } from 'react-redux';
import Frame from 'react-frame-component'
import ReactDevicePreview from 'react-device-preview'
import { showNotification } from '../../actions/notifications';
//import i18n from '../../helpers/i18n'; 
import Loading from '../../components/chrome/loading';

import Template from './template';

let view = false;

class TemplatePreview extends Component {
	
	constructor(props){
		super(props);		
		this.timedEventsTimer = false;
		
		this.timedEvents = this.timedEvents.bind(this);
		this.disableLinks = this.disableLinks.bind(this);
		
		this.state = {
			display: true
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		this.timedEvents();
		
		if(prevProps.view !== this.props.view){
			this.timedEventsForce();
		}
		
		// Following fizes issue with templates merging in iframe during switch
		if(this.props.template !== prevProps.template){
						
			this.setState({
				display: false
			});
			
			setTimeout(() => {				
				
				this.setState({
					display: true
				});
								
				this.timedEventsForce();
				
			}, 100);
		}
	}
	
	componentDidMount(){
		
		// RUN TO CLEAN UP
		this.timedEventsTimer = setInterval(() => {
			this.timedEvents();
		}, 5000);
		
		window.addEventListener('resize', this.timedEvents.bind(this));	
		this.timedEventsForce();	
	}
	
	componentWillUnmount(){
		clearInterval(this.timedEventsTimer);
		window.removeEventListener('resize', this.timedEvents.bind(this));
	}
	
	timedEvents(){
			
		const iFrame = document.getElementById("previewIframe");
		
		if(iFrame){
					
			// ADJUST THE HEIGHT
			let newHeight = iFrame.contentWindow.document.body.offsetHeight;
			//newHeight = newHeight+50;
			newHeight = newHeight+1;
			
			iFrame.style.height = newHeight + 'px';
			
			// DISABLE ALL LINKS
			iFrame.contentDocument.body.removeEventListener('click', this.disableLinks);
			iFrame.contentDocument.body.addEventListener('click', this.disableLinks);
			
			// RESIZE SLIDERS IF NEEDED
			let sliders = iFrame.contentDocument.getElementsByClassName("slider-resizer");

			for (var x = 0, y = sliders.length; x < y; x++) {

				let height = sliders[x].offsetHeight;
				let slides = sliders[x].getElementsByClassName("image");
								
				for (var z = 0, a = slides.length; z < a; z++) {					
					slides[z].style.height = height + 'px';
				}	
			}
		}
	}
	
	timedEventsForce(){
		
		// THIS HELPS FORCE RE-RENDER ON VIEW CHANGE AND INITIAL LOAD
		this.timedEvents();
		
		let x = 0;
	    let intervalID = window.setInterval(() => {
	
	       this.timedEvents();
	
	       if (++x === 10) {
	           window.clearInterval(intervalID);
	       }
	    }, 100);
	}
	
	disableLinks(e){
		
		let target = e.target;
								
		if(e.target.classList.contains('click-allowed')){
			return true;
		}else if(e.target.classList.contains('react-player__preview') || e.target.classList.contains('play-button')){
			return false;
		}
		
		while (target !== undefined && target.localName && target.localName !== 'a') {
			target = target.parentNode;
		}		
		
		if (target !== undefined) {
			e.preventDefault();
						
			if(this.props.view){
				window.open(target.href);
			}else{
					
				/*this.props.showNotification(
					'error',
					i18n.t('campaigns:event_preview_link_notification_title'),
					i18n.t('campaigns:event_preview_link_notification_description', { url: target.href }),
					false,
					[
						{
							name: i18n.t('campaigns:event_preview_link_notification_visit'),
							onClick: () => {
								window.open(target.href);
							}
						}
					]
				);*/	
			} 
			
			return false; 
			
		} else {
			return true;  // handle other clicks
		}
	}

	render() {
				
		if(!this.state.display){
			
			return (
				<div style={{ padding: '100px 0 100px 0', position: 'relative', marginTop: '-20px' }}>
					<Loading />
				</div>
			)
		}
		
		let options = {
			css: ['/css/preview_editor.css']
		};
		
		if(this.props.items){
			options.items = this.props.items;
		}
			
		let className = '';
		
		switch(this.props.view){
			
			case 3:
				view = 'macbookpro';
				className = 'desktop';
				break;
				
			case 2:
				view = 'ipadmini';
				className = 'tablet';
				break;
				
			case 1:
				view = 'iphone8';
				className = 'mobile';
				break;
				
			default: 
				view = false;
				break;
				
		}
		
		if(this.props.onEdit){
			options.edit = {
				onChange: this.props.onEdit,
				errors: this.props.errors,
				deviceChange: this.props.onDeviceChange,
				enabled: view ? false : true
			}
		}
						
		if(!view){
									
			if(this.props.onEdit){
				className += ' edit';
			}
			
			let padding = 0;
			
			switch(this.props.template.type){
				
				case 'facebook':
				case 'twitter':
				case 'instragram':
					padding = '20px';
					break;
					
				default:
				
					break;
			}

			return (
				<div ref={(divElement) => { this.divElement = divElement }}>
					<Frame
						id="previewIframe"
						className={`previewIframe ${className}`}
		                frameBorder="0"
		                initialContent={`<!DOCTYPE html><html class="preview-frame-element ${className}"><head></head><body class="preview-frame-element" style="padding: ${padding}"><div></div></body></html>`}
					>
						<React.Fragment>
							<Template 
								currency={this.props.account.group.currency}
								template={this.props.template}
								branding={this.props.branding}
								data={this.props.data}
								view={view}
								{...options}
							/>
						</React.Fragment>
					</Frame>
				</div>
			);
		}else{
		
			let height = 720;
			let width = 1188;
			let max = false;
			let className = 'center';
				
			switch(this.props.view){
				
				case 3:
					width = 1188;
					height = 720;
					className ='';
					break;
					
				case 2:
					width = 626;
					height = 948;
					max = 626;
					break;
					
				case 1:
					width = 423;
					height = 877;
					max = 423;
					break;
					
				default: 
				
					break;
					
			}
			
			let ratio = 1/width;
			let scale = 1;
			
			if(this.divElement){
				scale = this.divElement.offsetWidth * ratio;
			}
			
			if(max && scale > 1){
				scale = 1;
				height = 'auto';
			}else{
				height = (height * scale) + 'px';
			}
									
			return (
				<div 
					className={`previewDevice ${className}`}
					ref={ (divElement) => { this.divElement = divElement } }
					style={{ height: height }}
				>
					{scale && 
						
						<ReactDevicePreview
							device={view}
							scale={scale}
						>
							<Frame
								id="previewIframe"
								className="previewIframe"
				                frameBorder="0"
								initialContent={`<!DOCTYPE html><html xmlns="http://www.w3.org/1999/xhtml"class="preview-frame-element"><head><meta http-equiv="content-type" content="text/html; charset=utf-8"><meta name="viewport" content="width=device-width, initial-scale=1.0;"><title>Preview</title></head><body><div data-element="head"></div><div data-element="body"></div></body class="preview-frame-element"><div></div></html>`}
							>
								<React.Fragment>
									<Template 
										currency={this.props.account.group.currency}
										template={this.props.template}
										branding={this.props.branding}
										data={this.props.data}
										view={view}
										{...options}
									/>
								</React.Fragment>
							</Frame>
						</ReactDevicePreview>
					}
				</div>
			);
		}
	}
}

function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 
	showNotification
})(TemplatePreview);