import React, { Component } from 'react';
import NotificationsSystem from 'reapop';
import theme from './theme_static';

export default class Notifications extends Component {
	
	render() {
		return (
			<NotificationsSystem theme={theme} />
		)
	}
}