// REWORK i18n.t params
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import _ from 'lodash';

import Loading from '../../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../../actions/forms';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import RetailersGroupsToolbar from './toolbar';
import { checkPermission } from '../../../helpers/permissions';

import { fetchRetailersGroups, saveRetailersGroup } from '../../../actions/retailers';


var formName = 'formRetailersGroupsGroup';

class RetailersGroupsGroup extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;

		if(id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS_EDIT')){
				this.props.history.push('/');
			}
			
			this.props.fetchRetailersGroups(id, () => {
	       	
		       	if(this.props.retailers.groups && !this.props.retailers.groups[id]){
					this.props.history.push('/retailers/groups');
				}else{
					this.updateDomElements();
				}
	       	});
			
		}else{
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS_ADD')){
				this.props.history.push('/');
			}
		}
		
		this.state = {
			id: id
		}
		
		this.updateDomElements();
	}
	
	updateDomElements(){
		
		if(this.props.retailers.groups && this.state.id){
		
			let group = this.props.retailers.groups[this.state.id];			
			this.props.initialize(group);	
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onSubmit(values) {
			  				  	
	  	this.props.startSubmission(formName);
	  	
	  	var posting = _.clone(values);
	  		  	
	  	this.props.saveRetailersGroup(formName, this.state.id, posting, (id) => {
			this.props.history.push(`${this.props.parentPath}/group/${id}`);
			
			this.setState({ id: id });
			this.updateDomElements();
		});
	}
	
	render() {
				
		let { retailers } = this.props;
		
		if(!retailers.groups || (this.state.id && !retailers.groups[this.state.id])){
			return (
				<Loading />
			);
		}
		
		let title = i18n.t('retailers:title_add_group');
		
		if(this.state.id && retailers.groups[this.state.id]){
			title = retailers.groups[this.state.id].name;
		}
		
		const { handleSubmit } = this.props;
		
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<RetailersGroupsToolbar
					formName={formName}
					section="settings"
					id={this.state.id}
					{...this.props}
				/>
				<div className="c-card">
													
					<Field
						label={i18n.t('retailers:groups_settings_label_name')} 
						name="name"
						type="text"
						component={InputField}
					/>
				</div>
			</form>
		);
	}
}


const validate = (values) => {	
	
	const errors = {};

	
	return errors;
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		fetchRetailersGroups, 
		saveRetailersGroup
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersGroupsGroup)
);