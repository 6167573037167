import React, { Component } from 'react';
import GAPage from './ga_page';

export default function GAModal(WrappedComponent, type, options = {}) {
	
	const Wrapped = class extends Component {
		
		render() {			
			return (
				<React.Fragment>
					<GAPage type="modal" {...this.props} />
					<WrappedComponent {...this.props} />
				</React.Fragment>
			);
		}
	};
	
	return Wrapped;
}