// REWORK i18n.t params
import { errorsConstants } from '../constants/errors';
import i18n from '../helpers/i18n'; 

const defaultState = {
	denied: false,
	message: false
};

export default function(state = defaultState, action) {
		
	switch(action.type){		
		
		case errorsConstants.ERRORS_ERROR:		

			// This user needs to be logged out!
			if(action.payload.logout){
				window.location = '/logout';
			}else if(action.payload.maintenance){
				
				if(window.location.pathname !== '/maintenance'){
					window.location = '/maintenance';
				}
			} 
			
			let reason = 'Something went wrong';
			
			if(action.payload.status){
				reason = action.payload.status;				
			}
			
			switch(action.payload.status){
				
				case 'failed_validation_local':
					
					// Scroll - we add a small delay to allow dom and classes to be applied
					setTimeout(() => {
						const items = document.getElementsByClassName('c-input--danger');
					
						if(items.length > 0) {
							items[0].scrollIntoView({ 
								behavior: 'smooth',
								block: 'center',
								inline: 'center'
	            			})
						}
						
					}, 250);
					break;
					
				default:
				
					break;
			}					

			return {
				...state,
				message: i18n.t(reason)
			}
		
		case errorsConstants.ERRORS_CLEAN:		
			return {
				...state,
				message: false
			}
			
		default:
			return state;
	}
}	
