import React from 'react';

class CopyCode extends React.Component {
	
    render() {
		
		return (
			
			<div 
				className={`c-input copy ${this.props.className ? this.props.className : ''}`}
				onClick={() => {
					
					const el = document.createElement('textarea');
					el.value = this.props.value;
					document.body.appendChild(el);
					el.select();
					document.execCommand('copy');
					document.body.removeChild(el);
  
					this.props.onCopy();					
				}}
			>
				{this.props.value}					
			</div>
		);
	}
}

export default CopyCode;