import { productsConstants } from '../constants/products';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchProductList(id, callback) {

    return async (dispatch) => {
		
		let url = '/product/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: productsConstants.PRODUCT_LIST,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveProduct(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/product/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/product/save', values);
			}	
								
			dispatch({
				type: productsConstants.PRODUCT_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('products:notification_updated_title'),
					i18n.t('products:notification_updated_description'),
					request.data.products[0].image
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('products:notification_saved_title'),
					i18n.t('products:notification_saved_description'),
					request.data.products[0].image
				));
			}
					
			if(callback)
				callback(request.data.products);				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function importProducts(formName, values, callback) {

	return async (dispatch) => {
				
		try {
						
			const request = await axiosCreate().post('/product/import', { sku: values });	
								
			dispatch({
				type: productsConstants.PRODUCT_IMPORT,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('products:notification_imported_title', { count: request.data.imported  }),
				i18n.t('products:notification_imported_description', { count: request.data.imported  }),
				request.data.products[0].image
			));
					
			if(callback)
				callback();				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function searchProducts(query, type, callback) {

	return async (dispatch) => {
				
		try {
						
			const request = await axiosCreate().post('/product/search', { query: query, type: type });
			
			dispatch({
				type: productsConstants.PRODUCT_SEARCH,
				payload: request
			});
			
			if(callback){	
				callback(request.data.products);				
			}
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function getProductImage(url, callback) {

	return async (dispatch) => {
				
		try {
						
			const request = await axiosCreate().post('/product/image', { url: url });
					
			callback(request.data.url);				

		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}
