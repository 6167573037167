import { campaignsConstants } from '../constants/campaigns';
import _ from 'lodash';

export default function(state = {}, action) {
	
	let campaigns = false;

	switch (action.type) {
		      
		case campaignsConstants.CAMPAIGN_LIST:

			return { 
				...state, 
				campaigns: _.mapKeys(action.payload.data, 'id'),
			}
			
		case campaignsConstants.CAMPAIGN_IMPORT_LIST:

			return { 
				...state, 
				import: _.mapKeys(action.payload.data, 'id'),
			}	
			
		case campaignsConstants.CAMPAIGN_IMPORT:

			return { 
				...state, 
				campaigns: { ...state.campaigns, ..._.mapKeys(action.payload.data.campaigns, 'id')},
				import: _.mapKeys(action.payload.data.import, 'id'),
			}	
			
		case campaignsConstants.CAMPAIGN_SAVE_GENERAL:
		case campaignsConstants.CAMPAIGN_SAVE_ASSETS:
		case campaignsConstants.CAMPAIGN_DEPLOY:
		case campaignsConstants.CAMPAIGN_LAUNCH:

			return { 
				...state, 
				campaigns: { ...state.campaigns, ..._.mapKeys(action.payload.data.campaigns, 'id') }
			}

			
		case campaignsConstants.CAMPAIGN_SAVE_EVENT:
								
			campaigns = state.campaigns;
			
			if(campaigns[action.campaign_id]){
				
				let existing = false;
				
				if(!campaigns[action.campaign_id].events){
					campaigns[action.campaign_id].events = [];
				}else{
					existing = _.findKey(campaigns[action.campaign_id].events, {id: action.payload.data.event.id})
				}
								
				if(existing){
					campaigns[action.campaign_id].events[parseFloat(existing)] = action.payload.data.event;
				}else{
					campaigns[action.campaign_id].events.push(action.payload.data.event)
				}
			}

			return { 
				...state, 
				campaigns: campaigns
			}
			
		case campaignsConstants.CAMPAIGN_REGENERATE_EVENTS:		
						
			campaigns = state.campaigns;
			
			if(campaigns[action.campaign_id]){
				campaigns[action.campaign_id].events = action.payload.data.events;
			}

			return { 
				...state, 
				campaigns: campaigns
			}	
			
		case campaignsConstants.CAMPAIGN_DELETE:
								
			campaigns = state.campaigns;
			
			if(action.payload.data.deleted){
			
				if(campaigns[action.payload.data.deleted]){
					delete(campaigns[action.payload.data.deleted]);
				}
				
			}else{
				campaigns = { ...state.campaigns, ..._.mapKeys(action.payload.data.campaigns, 'id') };
			}

			return { 
				...state, 
				campaigns: campaigns
			}	
			
		case campaignsConstants.CAMPAIGN_SAVE_WARNINGS:

			return { 
				...state, 
				warnings: {
					id: action.id,
					posted: action.posted,
					warnings: action.payload.data.warnings
				}
			}	
									
		default:
			return state;
	}
}