import { billingConstants } from '../constants/billing';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function fetchInvoices() {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get(`/billing/invoices`);
										
			dispatch({ 
				type: billingConstants.INVOICES,
				payload: request
			});
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchBillingMonth(month) {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get(`/billing/monthly?month=${month}`);
										
			dispatch({ 
				type: billingConstants.MONTHLY_USAGE,
				payload: request,
				month: month
			});
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchBillingOverview() {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get(`/billing/overview`);
										
			dispatch({ 
				type: billingConstants.BILLING_OVERVIEW,
				payload: request
			});
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}