import { productsConstants } from '../constants/products';
import _ from 'lodash';

export default function(state = { }, action) {

	switch (action.type) {
			
		case productsConstants.PRODUCT_LIST:

			return { 
				...state, 
				products: { ...state.products, ..._.mapKeys(action.payload.data, 'id') }
			}
			
		case productsConstants.PRODUCT_SAVE:
		case productsConstants.PRODUCT_IMPORT:
		
			let products = { ...state.products, ..._.mapKeys(action.payload.data.products, 'id') }
			
			if(action.payload.data.duplicated){
				
				if(products[action.payload.data.duplicated]){
					delete(products[action.payload.data.duplicated]);
				}
			}
			
			return { 
				...state, 
				products: products
			}
			
		case productsConstants.PRODUCT_SEARCH:

			let search = {};
			
			if(state.search){
				search = state.search
			}
			
			return { 
				...state, 
				search: { ...search, ..._.mapKeys(action.payload.data.products, 'sku') }
			}	
			
		default:
			return state;
	}
}