export function forceDownload(url, filename) {
		
	//var FileSaver = require('file-saver');
	//FileSaver.saveAs(url, filename);

	//window.location(url);
	window.location = url;
}

export { forceDownload as default };
