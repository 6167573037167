import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import LogRocket from 'logrocket';
import Nav from '../../components/chrome/nav';
import i18n from '../../helpers/i18n'; 
import Languages from '../../components/chrome/languages';
import { fetchNotifications } from '../../actions/messages';
import { userDelegateLogout } from '../../actions/users';
import { imageResize } from '../../helpers/s3';
import ErrorAlert from '../../components/chrome/error';
import { checkPermission } from '../../helpers/permissions';
import Swal from 'sweetalert2';

class Layout extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			toggleNav: false,
			enableNotifications: false
		}
	}
	
	componentDidMount() {
		
		if(this.props.users.id){
			
			LogRocket.identify(this.props.users.id, {
			  	Key: localStorage.getItem('user_token'),
			  	Type: this.props.account.type,
			  	ProfileId: this.props.account.profile.id
			});
		}
		
		// CHECK NOTIFICATIONS - MOVE TO PUSH LATER
		if(this.state.enableNotifications){
			
			let _react = this;
		
			setInterval(function(){
				_react.props.fetchNotifications();
			}, (60000));
			
			this.props.fetchNotifications();
		}
	}
	
	toggleNav(force){
		
		let status = false;
		
		if(force){
			status = force;
		}else if(this.state.toggleNav){
			status = false;
		}else{
			status = 'is-sidebar-open'
		}
		
		this.setState({
			toggleNav: status
		});
	}
	
	toggleIcon(type, e){
			
		e.stopPropagation();
		
		if(!type || this.state.toggleIcon === type){
			this.setState({
				toggleIcon: false
			});
		}else{
			this.setState({
				toggleIcon: type
			});
		}
	}
	
	logout(){
		
		let logoutUrl = '/logout';
		
		if(this.props.users.delegate_id && this.props.users.delegate_id !== 'null'){
			
			let user = this.props.account.profile;
			
			Swal.fire({
				title: i18n.t('nav_logout_delegate_dialog_title'),
				text: i18n.t('nav_logout_delegate_dialog_description', { name: `${user.forename} ${user.surname}` }),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: i18n.t('nav_logout_delegate_all'),
				cancelButtonText: i18n.t('nav_logout_delegate_only', { name: `${user.forename} ${user.surname}` }),
				focusConfirm: false
			}).then((result) => {
	
				if(result.value) {
					window.location = logoutUrl;
				}else{
					this.props.userDelegateLogout();
				}
			});
			
			
		}else{
			window.location = logoutUrl;
		}		
	}
	
	render() {

		let user = this.props.account.profile;
		let group = this.props.account.group;
		let permissions = this.props.account.permissions;
							
		return (
			<div className={`o-page ${this.state.toggleNav ? this.state.toggleNav : ''}`} onClick={(e) => { this.toggleIcon(false, e) }} ref={node => this.node = node }>
							
				<div className="o-page__sidebar js-page-sidebar">
					<aside className="c-sidebar">
						<div className="c-sidebar__brand">
							<a href="/">
								{this.props.brand.details &&
									<img src={imageResize(`brands/${this.props.brand.details.directory}/general/logo.png`, 500)} alt={this.props.brand.details.name} />
								}
							</a>
						</div>
				
						<div className="c-sidebar__body">
							<Nav 
								closeNav={() => { this.toggleNav(false); }}
							/>
						</div>				
						<button className="c-sidebar__footer" onClick={() => { this.logout() }}>
							{i18n.t('nav_logout')} <i className="c-sidebar__footer-icon far fa-power-off"></i>
						</button>
					</aside>
				</div>
				
				<main className="o-page__content">
					{this.props.users.delegate_id && this.props.users.delegate_id !== 'null' && 
						<div id="delegate-bar" className="c-navbar">
							<i className="far fa-exclamation-circle"></i>
							
							You are logged in as {`${user.forename} ${user.surname}`}. 
							
							<button onClick={() => {
								this.props.userDelegateLogout();
							}}>
								Back to my account
							</button>
						</div>
					}
					
					<header className="c-navbar u-mb-medium">
						<button className="c-sidebar-toggle js-sidebar-toggle" onClick={(e) => { this.toggleNav() }}>
							<i className="fal fa-bars"></i>
						</button>
				
						<h2 className="c-navbar__title">
						
							{this.props.back && 
								<Link to={this.props.back}>
									<i className="fal fa-chevron-left"></i>
								</Link>
							}
							{this.props.title}
						</h2>
						
						{this.state.enableNotifications && 
							<div className={`c-dropdown dropdown u-mr-medium ${this.state.toggleIcon === 'notifications' ? 'show' : ''}`}>
								<div className={`c-notification dropdown-toggle ${this.props.messages.unread ? 'has-indicator ' : ''}`} onClick={(e) => { this.toggleIcon('notifications', e) }}>
									<i className={`c-notification__icon fal ${this.props.messages.unread ? 'fa-bell-on' : 'fa-bell'}`}></i>
								</div>
						
								<div className="c-dropdown__menu c-dropdown__menu--large has-arrow dropdown-menu dropdown-menu-right" >
						
									<span className="c-dropdown__menu-header">
										{i18n.t('nav_notifications')}
									</span>
									<Link to="/notifications/X" className="c-dropdown__item dropdown-item">
										<div className="o-media">
											<div className="o-media__img u-mr-xsmall">
												<span className="c-icon c-icon--info c-icon--xsmall">
													<i className="fal fa-globe"></i>
												</span>
											</div>
										
											<div className="o-media__body">
												<p>Notification copy here</p>
											</div>
										</div>
									</Link>
									<Link to="/notifications" className="c-dropdown__menu-footer">
										All Notifications
									</Link>
								</div>
							</div>
						}
						
						<div className="header-details">
							<h6>{user.forename} {user.surname}</h6>
							<p>{group.name}</p>
						</div>
						
						{user.image && 
							<div className="c-avatar c-avatar--xsmall header-avatar-user dropdown-toggle" onClick={(e) => { this.toggleIcon('account', e) }}>
								 <UserAvatar 
								 	className="c-avatar__img"
								 	size="36" 
								 	name={`${user.forename} ${user.surname}`}
								 	src={user.image ? imageResize(user.image, 100) : false}
								 	color="#FFF"
								 />
							</div>
						}
						
						<div className={`c-dropdown dropdown ${this.state.toggleIcon === 'account' ? 'show' : ''}`}>
							
							<div className="c-avatar c-avatar--xsmall header-avatar-company dropdown-toggle" onClick={(e) => { this.toggleIcon('account', e) }}>
								 <UserAvatar 
								 	className="c-avatar__img"
								 	size="36" 
								 	name={group.name}
								 	src={group.image ? imageResize(group.image, 100) : imageResize(`brands/${this.props.brand.details.directory}/general/icon.png`, 500)}
								 	color="#FFF"
								 />
							</div>
							<div className="c-dropdown__menu -dropdown__menu--large header-account-dropdown has-arrow dropdown-menu dropdown-menu-right">
								<Link to="/account" className="c-dropdown__item dropdown-item">{i18n.t('nav_account')}</Link>
								
								{checkPermission(permissions, 'SETTINGS') && 
									<Link to="/settings" className="c-dropdown__item dropdown-item">{i18n.t('nav_settings')}</Link>
								}
								
								<a href="/logout" onClick={(e) => { 
									e.preventDefault();
									this.logout(); 
									return false;
								}} className="c-dropdown__item dropdown-item">{i18n.t('nav_logout')}</a>
							</div>
						</div>
						
						{this.props.brand.details && this.props.brand.details.languages.length > 1 &&
							<div className={`c-dropdown dropdown languages-dropdown ${this.state.toggleIcon === 'language' ? 'show' : ''}`}>
								<div className="c-avatar c-avatar--xsmall dropdown-toggle" onClick={(e) => { this.toggleIcon('language', e) }}>
									<img className="c-avatar__img" src={`/images/flags/${i18n.language}.svg`} alt={i18n.language} />
								</div>
					
								<div className="c-dropdown__menu -dropdown__menu--large has-arrow dropdown-menu dropdown-menu-right">
									<Languages 
										ignore={true}
										forceUpate={() => { this.forceUpdate.bind(this); }}
									/>
								</div>
							</div>
						}
					</header>
					{this.props.children}
				</main>
				<ErrorAlert />
			</div>
		);
	}
}


function mapStateToProps({ users, account, tracking, chrome, brand, messages }, ownProps){
	return {
		users, 
		account, 
		tracking,
		title: chrome.title,
		back: chrome.back,
		brand,
		messages
	};
}

export default connect(mapStateToProps, { 
	fetchNotifications,
	userDelegateLogout
})(Layout);




//export default withRouter(connect(mapStateToProps)(Layout));
