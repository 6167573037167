export function checkPermission(permissions, role, sub) {

	role = role.toUpperCase();
	
	if(role in permissions){
		
		if(sub){
			
			if(permissions[role] !== ''){
				
				let roles = JSON.parse(permissions[role]);

				if(roles[sub] === 0){
					return false;					
				}
			}
		}
		
		return true;
		
	}else{
		return false;
	}
}