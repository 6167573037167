import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { userWelcome, userValidateKey } from '../../actions/users.js';
import { userAccount } from '../../actions/account.js';
import ErrorAlert from '../../components/chrome/error';
import InputField from '../../components/forms/field.js';
import Submit from '../../components/forms/submit';
import { startSubmission, stopSubmission } from '../../actions/forms';
import Loading from '../../components/chrome/loading';
import { imageResize } from '../../helpers/s3';
import { showNotification } from '../../actions/notifications';
import Timezone from '../../components/users/timezone';
import moment from 'moment-timezone';
import 'moment/min/locales';
import i18n from '../../helpers/i18n'; 

var formName = 'formUserWelcome';

class UserWelcome extends Component {

	constructor(props) {
	    super(props);
	    this.props.stopSubmission(formName);
	    const { token } = this.props.match.params;
	    
	    moment.locale(i18n.language);
	    	    
	    if(!token){
		    this.props.history.push('/login');		    
	    }
	    
	    this.state = {
		    token: token,
		    timezone: moment.tz.guess()
	    }
	    
	    // VALIDATE KEY
	    this.props.userValidateKey('invite', token, () => {
		    
		    if(!this.props.users.key || this.props.users.key.token !== this.state.token){
			    this.props.history.push('/login');
		    }
	    });
	}
	
	componentDidMount(){
		
		if(this.state.timezone){
			this.props.initialize({
				timezone: this.state.timezone
			});
		}
	}

  	onSubmit(values) {
	  		  	
	  	this.props.startSubmission(formName);
	  	
	  	let posting = {
		  	token: this.state.token, 
		  	password: values.password,
		  	timezone: this.state.timezone
	  	}
	 
		this.props.userWelcome(formName, posting, () => {			
			
			this.props.userAccount((account) => {
				
				if(account.language && account.language !== i18n.language){
					i18n.changeLanguage(account.language, () => {
						this.forceUpdate();
						
						this.props.showNotification(
							'success',
							i18n.t('users:notification_language_changed_title'),
							i18n.t('users:notification_language_changed_description')
						);
				    });
				}
				
				this.props.history.push('/');
			});
		});
	}
	
	onInputTimezoneChange(selected){
				
		this.setState({
			timezone: selected.value
		});
	}
  	
	render(){
		
		const { handleSubmit } = this.props;
		
		if(!this.props.users.key || this.props.users.key.token !== this.state.token){
			return (
				<Loading />
			);
		}

		return (
			<div className="o-page o-page--center">
        		{this.props.brand.details &&
					<img className="u-mb-medium logo" src={imageResize(`brands/${this.props.brand.details.directory}/general/logo.png`, 500)} alt={this.props.brand.details.name} />
				}
				
				<div className="o-page__card">
					<div className="c-card c-card--center">
						
						<div className="top">
							<h3>{i18n.t('users:invited_title', { name: this.props.users.key.forename, app_name: this.props.brand.details.name })}</h3>
							<p>{i18n.t('users:invited_intro')}</p>	
						</div>
							
						<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
							
							<Field
								label={i18n.t('users:invited_label_password')} 
								name="password"
								type="password"
								component={InputField}
							/>
							
							<Field
								label={i18n.t('users:invited_label_password2')} 
								name="password2"
								type="password"
								component={InputField}
							/>
							
							<Timezone
								label={i18n.t('users:account_label_timezone')}
								selected={this.state.timezone}
								onChangeFunc={this.onInputTimezoneChange.bind(this)}
								component={Field}
							/>
															
							<ErrorAlert />
							<Submit 
								label={i18n.t('users:invited_action_submit')} 
								form={formName} 
							/>
							
						</form>
					</div>
				</div>
        	</div>
		);
	}
}

function validate(values) {

	const errors = {};

	if (!values.password) {
		errors.password = 'Enter your new password!';
	}
	
	if (!values.password2) {
		errors.password2 = 'Enter your new password again!';
	}
	
	if (values.password !== values.password2) {
		errors.password2 = 'New passwords do not match!';
	}
	
	if (!values.timezone) {
		errors.timezone = 'Choose your timezone!';
	}
	
	return errors;
}


function mapStateToProps({ brand, users }, ownProps) {
	return { 
		brand,
		users
	};
}
export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		userWelcome,
		startSubmission, 
		stopSubmission,
		userValidateKey,
		userAccount,
		showNotification
	} )(UserWelcome)
);