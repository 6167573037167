import { statsConstants } from '../constants/stats';

export default function(state = {}, action) {
	
	switch (action.type) {
		      
		case statsConstants.STATS_OVERVIEW:
		
			return { 
				...state, 
				overview: action.payload.data
			}
			
		case statsConstants.STATS_CAMPAIGNS:
		
			return { 
				...state, 
				campaigns: action.payload.data
			}		
			
		case statsConstants.STATS_TYPE:
		
			let types = {};
			
			if(state.type){
				types = state.type;
			}
			
			types[action.event_type] = action.payload.data;
		
			return { 
				...state, 
				types: types
			}		
								
		case statsConstants.STATS_ANALYTICS:
		
			return { 
				...state, 
				analytics: action.payload.data
			}		
			
		case statsConstants.STATS_TOTALS:
		
			return { 
				...state, 
				totals: action.payload.data
			}
			
		case statsConstants.STATS_ANALYTICS_CLEAR:
		
			return { 
				...state, 
				analytics: false,
				types: {}
			}									
									
		default:
			return state;
	}
}