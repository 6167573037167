import React, { Component } from 'react';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import Loading from '../../../components/chrome/loading';

import IntegrationsCode from '../../../components/retailers/integrations/code';

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';
import EmptySection from '../../../components/chrome/empty';

import { fetchRetailersList } from '../../../actions/retailers';
import { checkWebsite } from '../../../actions/connections';

import SendDeveloper from '../../../components/retailers/send_developer';

class RetailersIntegrationsCode extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}
       	});
       	
       	let checkWebsite = false; // DISABLED CHECKING DUE TO UNRELIABLE AS PORT BLOCKS 	

       	if(checkWebsite && typeof this.props.connections.website === 'undefined'){
	       	this.props.checkWebsite();
       	}      
       	       	
       	this.state = {
	       	id: id,
	       	checkWebsite: checkWebsite
       	}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	render() {
			
		if(!this.props.retailers.retailers || !this.props.retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}

		let retailer = this.props.retailers.retailers[this.state.id];
		
		let connected = (
			<span className="status c-badge c-badge--success u-mt-medium">{i18n.t(`retailers:integration_setup_code_connected_status`)}</span>
		);
		
		if(this.props.connections.website && this.props.connections.website === 'checking'){
			connected = (
				<span className="status c-badge c-badge--warning u-mt-medium">{i18n.t(`retailers:integration_setup_code_checking_status`)}</span>
			);
		}else if(!this.props.connections.website && this.props.connections.website !== 'checking'){
			
			connected = (
				<div className="c-card c-alertc-alert--danger u-mb-zero u-mt-medium">
	                <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
						<i className="fal fa-unlink" style={{ color: '#FFF' }}></i>
					</span>
				
					<div className="c-alert__content">
						<h4 className="c-alert__title">{i18n.t('retailers:integration_code_settings_disconnected_title')}</h4>
						<p className="u-mb-small">{i18n.t('retailers:integration_code_settings_disconnected_description')}</p>
						
						<button type="button" className="c-btn c-btn--info" onClick={this.props.checkWebsite.bind(this)}>
							{i18n.t('retailers:integration_code_settings_label_check')}
						</button>
					</div>
				</div>
			);
		}
			
		return (

			<React.Fragment>
	
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_code_title')}
					</h2>
				</header>
								
				<div className="row">
					
					<div className="col-xl-6">
						<div className="c-card">
							<h4>{i18n.t('retailers:integration_code_settings_title')}</h4>
							<p>{i18n.t('retailers:integration_code_settings_intro', { website: retailer.details.url_website ? retailer.details.url_website : 'none currently set' })}</p>
							
							{this.state.checkWebsite && retailer.details.url_website && connected}
						</div>
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							{retailer.details.url_website && 
								<React.Fragment>	
									<h4 className="u-mb-medium">{i18n.t('retailers:integration_code_instructions_title')}</h4>
							
									<IntegrationsCode 
										retailer={retailer}
									/>
									
									<br/>
									<SendDeveloper />
								</React.Fragment>
							}
							
							{!retailer.details.url_website && 
								<EmptySection
									icon="fa-exclamation-circle"
									title={i18n.t('retailers:notification_integration_code_nowebsite_title')}
									description={i18n.t('retailers:notification_integration_code_nowebsite_description')}
									cta={{
										label: i18n.t('retailers:action_add_website'),
										url: '/settings/branding'
									}}
								/>								
							}
						</div>
						
					</div>					
						
				</div>
				
				
			</React.Fragment>
		);
		
	}
}

function mapStateToProps({ account, retailers, connections }, ownProps){
	return {
		account,
		retailers,
		connections
	};
}

export default connect(mapStateToProps, { 
	fetchRetailersList,
	checkWebsite
})(RetailersIntegrationsCode);