import React, { Component } from 'react';
import _ from 'lodash';
import InputField from '../../components/forms/field.js';

class Filters extends Component {

	componentDidMount(){
		
		/*
			
		// Below should be used to prefocus input
			
		let focus = this.props.fields[0].name;
		
		if(this.props.focus){
			focus = this.props.focus;
		}*/
				
		//ReactDOM.findDOMNode(focus).focus();
	}
	
	render() {

		return (
			<div className={`c-toolbar c-filters c-toolbar-responsive ${this.props.display ? 'active' : ''}`}>
				{_.map(this.props.fields, (field, key) => {
					
				  	return (
					  	<InputField
					  		key={key}
					  		className={`filter_${field.name}`}
					  		onChangeFunc={(event) => { this.props.onChangeFunc(event, field.name) }}
							{...field}
						/>
				  	)
				})}		
			</div>
		);
	}
}

export default Filters;