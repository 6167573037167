import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import Loading from '../../../components/chrome/loading';
import IntegrationsEmail from '../../../components/retailers/integrations/email';
import IntegrationsSes from '../../../components/retailers/integrations/ses';

import { startSubmission, stopSubmission } from '../../../actions/forms';
import _ from 'lodash';
import Toolbar from '../../../components/chrome/toolbar';
import { connectionSettings, checkSes } from '../../../actions/connections';

//import "highlight.js/styles/darkula.css"

import { checkPermission } from '../../../helpers/permissions';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { saveRetailerIntegration, fetchRetailersList } from '../../../actions/retailers';
import { fetchSubscribersGroups } from '../../../actions/subscribers';
import { showNotification } from '../../../actions/notifications';
import SendDeveloper from '../../../components/retailers/send_developer';

var formName = 'formRetailersIntegrationsEmail';
var validator = require("email-validator");

class RetailersIntegrationsEmail extends Component {

	constructor(props){
		super(props);

		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
			this.props.history.push('/');
		}
		
		let id = this.props.account.group.id;
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
				this.props.history.push(this.props.parentPath);
			}else{
				this.updateDomElements();
			}
       	});
       	
       	this.props.fetchSubscribersGroups(id, () => {
	       	this.updateDomElements();
       	});
       	
       	this.props.connectionSettings(['ses']);
       	
       	this.state = {
	       	id: id,
	       	empty: false,
	       	double_optin: false,
	       	name: false,
	       	dob: false,
	       	gender: false,
	       	group: [],
	       	email: '',
	       	valid: false,
	       	validating: false
       	}	
	}
	
	updateDomElements(){		
		
		if(this.props.retailers.retailers && this.props.subscribers.groups.local && this.state.id){
		
			let retailer = this.props.retailers.retailers[this.state.id];

			this.setState({
				double_optin: (retailer.details.subscribe_double_optin && retailer.details.subscribe_double_optin === '1') ? true : false,
		       	name: (retailer.details.subscribe_field_name && retailer.details.subscribe_field_name === '1') ? true : false,
		       	dob: (retailer.details.subscribe_field_dob && retailer.details.subscribe_field_dob === '1') ? true : false,
		       	gender: (retailer.details.subscribe_field_gender && retailer.details.subscribe_field_gender === '1') ? true : false,
		       	group: retailer.details.subscribe_group ? JSON.parse(retailer.details.subscribe_group) : [],
		       	email: retailer.details.email_local_email ? retailer.details.email_local_email : ''
			});
			
			let init = { 
				group: retailer.details.subscribe_group ? JSON.parse(retailer.details.subscribe_group) : [] 
			}
			
			if(retailer.details.email_local_email){
				init.email = retailer.details.email_local_email;
					
				this.props.checkSes(retailer.details.email_local_email, false, (connected) => {
										
					this.setState({
						valid: connected,
						validating: false
					})
				});
				
				this.setState({
					valid: retailer.details.email_local_email_connected ? retailer.details.email_local_email_connected : false,
					validating: false
				})
			}
						
			this.props.initialize(init);			
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onInputGroupChange(selected){
				
		this.setState({
			group: selected
		});
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
	  	
	  	let groups = _.map(this.state.group, (group, key) => {
			
			if(group.value){
				return group.value
			}else{
				return group;
			}	
		})
			  	
	  	let posting = {
		  	group: groups,
			double_optin: this.state.double_optin,
			field_name: this.state.name,
			field_dob: this.state.dob,
			field_gender: this.state.gender,
			email: values.email
	  	}
		  	
		this.props.saveRetailerIntegration(formName, 'email_local', posting, (retailer) => {
			
			if(posting.email == '' || !posting.email){
			  				  	
			  	this.props.showNotification(
					'success',
					i18n.t('retailers:notification_integration_email_local_updated_title'),
					i18n.t('retailers:notification_integration_email_local_updated_description', { email: this.props.connections.settings.ses.default })
				);
					
				this.props.history.push(this.props.parentPath);
				
		  	}else{
		  		
				this.setState({
					valid: retailer.details.email_local_email_connected ? retailer.details.email_local_email_connected : false,
					validating: false
				});
				
				if(retailer.details.email_local_email_connected){
					
					this.props.showNotification(
						'success',
						i18n.t('retailers:notification_integration_email_local_updated_title'),
						i18n.t('retailers:notification_integration_email_local_updated_description', { email: posting.email })
					);
						
					this.props.history.push(this.props.parentPath);
					
				}else{
					
					this.setState({
						validating: true
					})
					
					this.props.checkSes(retailer.details.email_local_email, false, (connected) => {
										
						this.setState({
							valid: connected,
							validating: false
						})
					});
					
					this.props.showNotification(
						'success',
						i18n.t('retailers:notification_integration_email_local_updated_dns_title'),
						i18n.t('retailers:notification_integration_email_local_updated_dns_description', { email: posting.email })
					);
				}
			}
	  	});
	}
	
	onInputChange(event, type){
				
		this.setState({
			[type]: event.target.checked
		});
	}

	render() {
			
		let { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.retailers[this.state.id] || !this.props.connections.settings || !this.props.connections.settings.ses){
			return (
				<Loading />
			);
		}
				
		const { handleSubmit } = this.props;
		
		let retailer = retailers.retailers[this.state.id];
		
		let connected = '';
			
		if(retailer.details.email_local_email == this.state.email){
						
			if(this.state.valid){
				
				connected = (
					<span className="status c-badge c-badge--success">{i18n.t(`retailers:integration_setup_domain_connected_status`)}</span>
				);
			
			}else if(this.state.validating){
				
				connected = (
					<span className="status c-badge c-badge--warning">{i18n.t(`retailers:integration_setup_domain_checking_status`)}</span>
				);
				
			}else if(!this.state.valid && this.props.connections.ses){

				connected = (
					<div className="c-card c-alertc-alert--danger u-mb-none">
		                <span className="c-alert__icon u-mb-small u-mr-small c-alert--danger">
							<i className="fal fa-unlink" style={{ color: '#FFF' }}></i>
						</span>
					
						<div className="c-alert__content">
						
							<IntegrationsSes
								retailer={retailer}
								email={validator.validate(this.state.email) ? this.state.email : false}
							/>
							
							<button type="button" className="u-mt-medium c-btn c-btn--info" onClick={() => {
								
								this.setState({
									validating: true
								});
								
								this.props.checkSes(this.state.email, false, (connected) => {
									
									this.setState({
										valid: connected,
										validating: false
									})
								});
							}}>
								{i18n.t('retailers:integration_email_local_settings_label_domain_check')}
							</button>
							
							{!this.props.connections.ses.email.verified && 
								
								<button type="button" className="u-mt-medium u-ml-small c-btn c-btn--info" onClick={() => {
														
									this.props.checkSes(this.state.email, true, () => {
										
										this.props.showNotification(
											'success',
											i18n.t('retailers:notification_integration_email_local_resent_title'),
											i18n.t('retailers:notification_integration_email_local_resent_description', { email: this.state.email })
										);
									});
								}}>
									{i18n.t('retailers:integration_email_local_settings_label_email_resend')}
								</button>
								
							}
						</div>
						
						<SendDeveloper />
					</div>
				);
			}
		}

		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{i18n.t('retailers:integration_email_local_title')}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={[{
						label: i18n.t('retailers:integration_email_local_toolbar_settings'),
						url: `${this.props.parentPath}/integrations/email`,
						selected: true
					}]}
					buttons={[{
						label: i18n.t('retailers:integration_email_local_action_save'),
						submit: formName
					}]}
				/>
				
				<div className="row">
					
					<div className="col-xl-6">
												
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_email_local_sending_title')}</h4>
							
							<Field
								label={i18n.t('retailers:integration_email_local_settings_label_email')}
								labelSub={i18n.t('retailers:integration_email_local_settings_label_email_info', { email: this.props.connections.settings.ses.default })}
								name="email"
								type="text"
								onChange={(event) => {
									this.setState({
									   	email: event.target.value
								   	})
								}}
								component={InputField}
							/>
							
							{connected}
						</div>
						
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_email_local_subscription_title')}</h4>
							
							<Field
								label={i18n.t('retailers:integration_email_local_label_group', { count: this.state.group ? this.state.group.length : 1 })} 
								name="group"
								type="suggest"
								multiple={true}
								options={_.map(this.props.subscribers.groups.local, (group, key) => {
																					
									return ({
										label: group.name,
										value: group.id							
									});
								})}
								selected={this.state.group}
								onChangeFunc={this.onInputGroupChange.bind(this)}
								component={InputField}
								className="u-mb-medium"
							/>
															
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_double_optin')} 
								name="double_optin"
								type="switch"
								selected={this.state.double_optin}
								onChangeFunc={(e) => { this.onInputChange(e, 'double_optin') }}
								component={InputField}
							/>
							
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_name')} 
								name="field_name"
								type="switch"
								selected={this.state.name}
								onChangeFunc={(e) => { this.onInputChange(e, 'name') }}
								component={InputField}
							/>
							
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_dob')} 
								name="field_dob"
								type="switch"
								selected={this.state.dob}
								onChangeFunc={(e) => { this.onInputChange(e, 'dob') }}
								component={InputField}
							/>
							
							<Field
								labelSwitch={i18n.t('retailers:integration_email_local_label_gender')} 
								name="field_gender"
								type="switch"
								selected={this.state.gender}
								onChangeFunc={(e) => { this.onInputChange(e, 'gender') }}
								component={InputField}
							/>
							
						</div>
					</div>
					
					<div className="col-xl-6">
						<div className="c-card preview">
							<h4 className="u-mb-medium">{i18n.t('retailers:integration_email_local_instructions_title')}</h4>
						
							<IntegrationsEmail 
								retailer={retailer}
							/>
																
							<br/>
							<SendDeveloper />
						</div>
					</div>					
				</div>
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};
	
	if (!values.group) {
		errors.group = 'Enter at least one group for where users should be saved!';
	}

	return errors;
}

function mapStateToProps({ account, retailers, subscribers, connections }, ownProps){
	return {
		account,
		retailers,
		subscribers,
		connections
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		showNotification,
		fetchRetailersList,
		saveRetailerIntegration,
		fetchSubscribersGroups,
		connectionSettings,
		checkSes
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersIntegrationsEmail)
);
