import { statsConstants } from '../constants/stats';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function fetchStatsOverview(startDate, endDate) {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get(`/stats/overview?from=${startDate}&to=${endDate}`);
										
			dispatch({ 
				type: statsConstants.STATS_OVERVIEW,
				payload: request
			});
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchStatsCampaigns(callback) {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get('/stats/campaign');
										
			dispatch({ 
				type: statsConstants.STATS_CAMPAIGNS,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	};
}

export function fetchAccountTotals() {

    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get('/stats/totals');
										
			dispatch({ 
				type: statsConstants.STATS_TOTALS,
				payload: request
			});
			 			
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	};
}

export function fetchAnalytics(from, to, campaign, event, retailers) {

	let url = `/stats/analytics?from=${from}&to=${to}`;
	
	if(campaign){
		url += `&campaign=${campaign}`;
	}
	
	if(event){
		url += `&event=${event}`;
	}
	
	if(retailers){
		url += `&retailers=${retailers.join()}`;
	}
	
    return async (dispatch) => {
	    
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: statsConstants.STATS_ANALYTICS,
				payload: request
			});
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchStatsType(from, to, type, retailers, callback) {

    return async (dispatch) => {
	    
	    let url = `/stats/type?type=${type}&from=${from}&to=${to}`;
	    
	    if(retailers){
			url += `&retailers=${retailers.join()}`;
		}
	    
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: statsConstants.STATS_TYPE,
				payload: request,
				event_type: type
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function clearAnalytics() {

	return async (dispatch) => {
		
		dispatch({ 
			type: statsConstants.STATS_ANALYTICS_CLEAR
		});
	}
}