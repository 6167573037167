import { formsConstants } from '../constants/forms';

export function startSubmission(form){
		
	return {
		type: formsConstants.SUBMITSTART,
		data: form
	};
}

export function stopSubmission(form){
		
	return {
		type: formsConstants.SUBMITSTOP,
		data: false
	};
}

export function proceedAfterSubmission(data){
		
	return {
		type: formsConstants.SUBMITPROCEED,
		data: data
	};
}