import { calendarConstants } from '../constants/calendar';
import _ from 'lodash';

export default function(state = {}, action) {
	
	let schedule;
	
	switch (action.type) {
		      
		case calendarConstants.SCHEDULE:
		
			schedule = (state.schedule && !action.clear) ? state.schedule : {}
			
			_.forEach(action.payload.data, (values, type) => {
				
				if(!schedule[type]){
					schedule[type] = {}
				}
				
				schedule[type] = { ...schedule[type], ..._.mapKeys(values, 'id') }
				
			});			

			return { 
				...state, 
				schedule: schedule
			}		
		
		case calendarConstants.EVENT_UPDATE:

			schedule = state.schedule ? state.schedule : {}
			
			if(schedule.events){
				schedule.events[action.payload.data.event.id] = action.payload.data.event;
			}		

			return { 
				...state, 
				schedule: schedule
			}	
			
		case calendarConstants.EVENT_DELETE:

			schedule = state.schedule ? state.schedule : {}
			
			if(schedule.events && schedule.events[action.id]){
				delete(schedule.events[action.id]);
			}		

			return { 
				...state, 
				schedule: schedule
			}				
					
						
		default:
			return state;
	}
}