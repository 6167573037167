export function imageUrl(path) {
	
	if(checkUrl(path)){
		return path;
	}
	
	return `https://campaign-tool.s3.eu-west-2.amazonaws.com/${path}`;
}

export function imageResize(path, width, height, fit, overlay, crop) {
	
	if(checkUrl(path)){
		return path;
	}
		
	//  OPTIONS: https://sharp.pixelplumbing.com/
	
	
	
	if(!width){
		width = null;
	}
	
	if(!height){
		height = null;
	}
	
	if(!fit){
		fit = 'cover';
	}
	
	let edits = {};
	
	if(crop){
		
		edits.extract = {
			left: parseFloat(crop.x),
			top: parseFloat(crop.y),
			width: parseFloat(crop.width),
			height: parseFloat(crop.height)
		}
	}
	
	//edits.grayscale = true;
	
	edits.resize = {
		width: width,
	  	height: height,
	  	withoutEnlargement: false,
	  	fit: fit,
	  	position: 'attention',
	  	background: '#FFF'
	}
		
	if(overlay){
			
		switch(overlay){
			
			case 'play':
				edits.overlayWith = {
	                 bucket: 'campaign-tool',
	                 key: 'general/play.png',
	                 wRatio: 15
		        };			        
				break;
				
			default:
			
				break;
		}
	}	

	const imageRequest = {
	    bucket: 'campaign-tool',
	    key: path,
	    edits: edits
	};
			
	return `https://d24fvwhp25tn1r.cloudfront.net/${btoa(JSON.stringify(imageRequest))}`;
}

export function checkUrl(path) {
	let regex = new RegExp("^(https?)(:\/\/|(\%3A%2F%2F))", "i");
	return regex.test(path);
};

export { imageUrl as default };
