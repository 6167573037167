import { messagesConstants } from '../constants/messages';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function fetchNotifications(status) {

    return async (dispatch) => {

		try {
			const request = await axiosCreate().get('/notifications/list');
										
			dispatch({ 
				type: messagesConstants.FETCH_NOTIFICATIONS,
				payload: request
			});
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchSuggestions() {

    return async (dispatch) => {

		try {
			const request = await axiosCreate().get('/notifications/suggestions');

			dispatch({ 
				type: messagesConstants.FETCH_SUGGESTIONS,
				payload: request
			});
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}
