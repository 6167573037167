import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 
import { generateBack } from '../../helpers/back';

import { checkPermission } from '../../helpers/permissions';

class RegionalDetailsToolbar extends Component {
	
	constructor(props){
		super(props);
		
		this.state = {
			search: false
		}
	}
	
	render() {
		
		let buttons = [];
		let filter = false;
		let tabs = [];
		
		switch(this.props.section){
				
			case 'settings':
				buttons.push({
					label: i18n.t('regional:settings_submit'),
					submit: this.props.formName
				});
				break;
				
			case 'users':
				buttons.push({
					label: i18n.t('regional:action_add_user'),
					url: `${this.props.parentPath}/user/regional?id=${this.props.id}&b=${generateBack(window.location.pathname)}`
				});
				
				/*filter = {
					toggleFunc: (status) => {
						this.setState({
							search: status
						});
					},
					onChangeFunc: (event) => { 
						
						this.setState({
							keyword: event.target.value
						})
					},
					fields: [
						{
							placeholder: i18n.t('regional:filter_users_status'),
							name: "status",
							type: 'suggest',
							options: [
								{
									value: '',
									label: i18n.t('regional:filter_users_status_label_all')
								},
								{
									value: 1,
									label: i18n.t('regional:filter_users_status_label_active')
								},
								{
									value: 0,
									label: i18n.t('regional:filter_users_status_label_invited')
								}
							]
						},
						{
							placeholder: i18n.t('regional:filter_users_keyword'),
							name: "keyword",
							type: 'text'
						}
					],
					open: this.state.search
				}*/
				break;
				
				
			default:
			
				break;			
		}
		
		let url = `${this.props.parentPath}/region/${this.props.id}`;
		
		tabs.push({
			label: i18n.t('regional:toolbar_setting'),
			url: url,
			selected: this.props.section === 'settings' ? true : false
		});
		
		if(this.props.id){

			if(checkPermission(this.props.account.permissions, 'REGIONAL_USERS')){
				tabs.push({
					label: i18n.t('regional:toolbar_users'),
					url: `${url}/users`,
					selected: this.props.section === 'users' ? true : false
				});
			}
		}

		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}

function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 

})(RegionalDetailsToolbar);