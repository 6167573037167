import { connectionsConstants } from '../constants/connections';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function connectionSettings(types, callback) {

    return async (dispatch) => {
		
		let url = '/connections/settings';
		
		if(types){
			url += `?types=${types.join(',')}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: connectionsConstants.CONNECITONS_SETTINGS,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function connectionDisconnect(type, callback) {

	return async (dispatch) => {
				
		try {
						
			const request = await axiosCreate().delete('/connections/disconnect/' + type);
								
			dispatch({
				type: connectionsConstants.CONNECITONS_DISCONNECT,
				payload: request
			});
			
			if(callback)
				callback();				

		} catch (error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function connectionGetUrl(type, values, callback) {

	return async (dispatch) => {
				
		try {
			
			switch(type){
				
				case 'twitter':
					values.redirect = window.location.origin + `/connections/${type}`;
					break;
					
				default:
				
					break;
			}
						
			const request = await axiosCreate().post('/connections/connect/' + type, values);
								
			dispatch({
				type: connectionsConstants.CONNECTIONS_CONNECT,
				payload: request
			});
			
			if(callback)
				callback(request.data.response);				

		} catch (error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function connectionConfirm(type, values, callback) {

	return async (dispatch) => {
				
		try {

			const request = await axiosCreate().post('/connections/confirm/' + type, values);
								
			dispatch({
				type: connectionsConstants.CONNECTIONS_CONFIRM,
				payload: request
			});
			
			if(callback)
				callback(request.data.response);				

		} catch (error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function checkDns(domain, callback) {

	return async (dispatch) => {

		try {

			const request = await axiosCreate().post('/connections/dns', { domain: domain });

			dispatch({
				type: connectionsConstants.CONNECTIONS_DNS,
				payload: request
			});
			
			callback(request.data.connected);				

		} catch (error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function checkSes(email, resend, callback) {

	return async (dispatch) => {

		try {

			const request = await axiosCreate().post('/connections/ses', { email: email, resend: resend });

			dispatch({
				type: connectionsConstants.CONNECTIONS_SES,
				payload: request
			});
			
			if(callback)
				callback(request.data.connected);				

		} catch (error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function checkWebsite() {

	return async (dispatch) => {
				
		dispatch({
			type: connectionsConstants.CONNECTIONS_WEBSITE,
			payload: {
				data: {
					status: 'checking'
				}
			}
		});
		
		try {

			const request = await axiosCreate().get('/connections/website');

			dispatch({
				type: connectionsConstants.CONNECTIONS_WEBSITE,
				payload: request
			});
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function getMailchimp(key, callback) {

	return async (dispatch) => {

		try {

			const request = await axiosCreate().post('/connections/mailchimp', { key: key });

			dispatch({
				type: connectionsConstants.CONNECTIONS_MAILCHIMP,
				payload: request
			});
			
			if(callback)
				callback(request.data.emails);				

		} catch (error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}