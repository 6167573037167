import { Component } from 'react';
import { connect } from 'react-redux';
import { userLogout } from '../../actions/users.js';

class UserLogout extends Component {
	
  	constructor(props) {
	    super(props);
	    this.state = {};
	    
		this.props.userLogout(this.props.history);
	}
	
	/*componentWillMount() {
		this.props.userLogout(this.props.history);
	}*/
	
	render(){
		return null;
	}
}

function mapStateToProps(state) {
	//return { post: posts[ownProps.match.params.id] };
	return { };
}

export default connect(mapStateToProps, { userLogout })(UserLogout);

