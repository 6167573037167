import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import i18n from '../../helpers/i18n'; 
import Swal from 'sweetalert2';
import { deleteAsset, downloadAsset } from '../../actions/assets';
import { forceDownload } from '../../helpers/download'; 

class AssetActions extends Component {

	onClickDelete(id){
		
		Swal.fire({
			title: i18n.t('assets:delete_dialog_title'),
			text: i18n.t('assets:delete_dialog_description'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: i18n.t('dialog_confirm'),
			cancelButtonText: i18n.t('dialog_cancel'),
			focusConfirm: false
		}).then((result) => {

			if (result.value) {
				
				this.props.deleteAsset(id);
				
				if(this.props.overlay.back){
					this.props.history.push(this.props.overlay.back.url);
				}else if(this.props.parentPath){
					this.props.history.push(this.props.parentPath);
				}
			}
		});
	}
	
	render(){
		
		let { asset } = this.props;
		let type = 'asset';
		
		if(this.props.type){
			type = this.props.type;
		}
		
		/*{asset.readonly === 0 && (!this.props.actions || this.props.actions.includes('delete')) &&
			<Link 
				to="#"
				onClick={() => { this.onClickDelete(asset.id) }}
			>
				<i className="fal fa-trash"></i>
			</Link>
		}*/
						
		return (
			<React.Fragment>

				{asset.readonly === 0 && (!this.props.actions || this.props.actions.includes('edit')) &&
					<Link to={`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/${type}/${asset.id}/edit${this.props.back ? '?b=' + this.props.back : ''}`}>
						<i className="fal fa-edit"></i>
					</Link>
				}
				
				{this.props.asset.type !== 'youtube' && this.props.asset.type !== 'vimeo' && (!this.props.actions || this.props.actions.includes('download')) &&
					<button onClick={() => { 
						this.props.downloadAsset(asset.id, (url, filename) => {
							forceDownload(url, filename);
						});
					}}>
						<i className="fal fa-download"></i>
					</button>
				}
				
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ overlay }, ownProps){
	return {
		overlay
	};
}

export default withRouter(connect(mapStateToProps, { 
	deleteAsset,
	downloadAsset
})(AssetActions));
